import './App.css'

//import './assets/css/responsive.css';


import RouterConfig from './navigation/RouterConfig';

function App() {
  return (
    <>
      <RouterConfig></RouterConfig>
    </>
  );
}

export default App;
