import React, { useEffect, useState } from "react";
import DashBoard from "../DashBoard/DashBoard";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import "../../assets/css/bootstrap.min.css"
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from "../../authConfig";
import Login from "../../AzureLogin/Login";
import { callMsGraph } from "../../AzureLogin/GraphQL";
import ValidateGraphData from "../../AzureLogin/ValidateGraphAPI";
import GetAccessToken from "../../AzureLogin/GetAccessToken";
import Footer from "../Footer/Footer";

const MyTour = () => {
	const [mapData, setMapData] = useState([])
	const [EmailAddress, setEmailAddress] = useState("");
	const [WaiverStatus, setWaiverStatus] = useState("0");
	const [paginationArr, setPaginationArr] = useState([]);
	const [selectedPageNo, setSelectedPageNo] = useState(1);
	const [pageNoEnd, setPageNoEnd] = useState();
	const [pageNoStart, setPageNoStart] = useState(0);
	const [totalGuest, setTotalGuest] = useState(0);
	const [isPrintClicked, setIsPrintClicked] = useState(false);
	const [confirmationDetail, setConfirmationDetail] = useState({});
	const [confirmationList, setConfirmationList] = useState([]);
	const { instance, accounts } = useMsal();
	const [graphData, setGraphData] = useState(null);
	const [valgraphData, setvalGraphData] = useState(false)
	const [Tpcsconfig, setTpcsconfig] = useState("");
	let arr = [];
	useEffect(() => {
		getGuestDetail();
	}, [selectedPageNo])

	const obj = {
		"ConfirmationID": "",
		"GroupName": "",
	}

	// useEffect(() => {
	// 	if ((confirmationList && confirmationList.length > 0) && (confirmationDetail.ConfirmationID !== confirmationList[0].ConfirmationID)) {
	// 		setConfirmationList([])
	// 	}
	// })
	const getGuestDetail = () => {
		const config = {
			method: 'GET',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/getmytoursdata`,
			headers: {
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
			},
			params: {
				"ConfirmationID": EmailAddress,
				"WaiverStatus": WaiverStatus,
				"PageNumber": selectedPageNo

			}
		};
		axios(config)
			.then(function (response) {
				if (response.data.Guests != null) {
					setMapData(response.data.Guests);
					var itemNo = 1;
					var itemArr = [];
					itemNo = Math.ceil(response.data.Pagination.TotalRecords / response.data.Pagination.PageSize)
					for (let i = 1; i <= itemNo; i++) {
						itemArr.push(i)
					}
					setPaginationArr(itemArr);
					setTotalGuest(response.data.Pagination.TotalRecords)
				}

			})
	}
	const onchangeEmailAddress = (val) => {
		val = val.trim()
		setEmailAddress(val)
	}
	const onchangeWaiverStatus = (val) => {
		val = val.trim()
		setWaiverStatus(val)
	}
	const handleSubmit = () => {
		const config = {
			method: 'GET',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/getmytoursdata`,
			headers: {
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
			},
			params: {
				"ConfirmationID": EmailAddress,
				"WaiverStatus": WaiverStatus,
				"PageNumber": 1

			}
		};
		axios(config)
			.then(function (response) {
				if (response.data.Guests != null) {
					setMapData(response.data.Guests)
					var itemNo = 1;
					var itemArr = [];
					itemNo = Math.ceil(response.data.Pagination.TotalRecords / response.data.Pagination.PageSize)
					for (let i = 1; i <= itemNo; i++) {
						itemArr.push(i)
					}
					setSelectedPageNo(1);
					setPageNoStart(0);
					setPageNoEnd(3)
					setPaginationArr(itemArr);
					setTotalGuest(response.data.Pagination.TotalRecords)

				}
				else {
					setMapData([]);
					setPaginationArr([]);

				}
			})
	}

	const handleGuestData = (item) => {
		setIsPrintClicked(true);
		setConfirmationList([]);
		setConfirmationDetail([]);
		let arr = [];
		arr.push(obj);
		arr[0] = { ...arr[0], ConfirmationID: item.ConfirmationID, GroupName: item.GroupName }
		const config = {
			method: 'GET',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/getguestdetailsbywaiverstatus`,
			headers: {
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
			},
			params: {
				"ConfirmationID": item.ConfirmationID,
				"WaiverStatus": -1,
				"PageNumber": 1,
				"EventDate": '',
				"SearchType": 1,
				"pagesize": 20
			}
		};
		axios(config)
			.then(function (response) {
				if (response.data && response.data.Guests && response.data.Guests.length > 0) {
					setConfirmationList(response.data.Guests);
					setConfirmationDetail(arr);
				}
				else {
					setConfirmationList([]);

				}
			})
	}
	// useEffect(() => {
	// 	debugger;
	// 	const config = {
	// 		method: 'GET',
	// 		url: `${process.env.REACT_APP_WEBSITE_NAMES}/getguestdetailsbywaiverstatus`,
	// 		headers: {
	// 			'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
	// 		},
	// 		params: {
	// 			"ConfirmationID": confirmationDetail.ConfirmationID,
	// 			"WaiverStatus": -1,
	// 			"PageNumber": selectedPageNo,
	// 			"EventDate": '',
	// 			"SearchType": 1,
	// 			"pagesize": 20
	// 		}
	// 	};
	// 	axios(config)
	// 		.then(function (response) {
	// 			if (response.data.Guests != null) {
	// 				setConfirmationList(response.data.Guests);
	// 			}
	// 			else {
	// 				setConfirmationList([]);

	// 			}
	// 		})

	// }, [confirmationDetail])


	return (
		<>
			<AuthenticatedTemplate>
				<>
					{graphData ? (
						<>
							{valgraphData ? (
								<>

									<>
										<Modal centered
											show={isPrintClicked}
											onHide={() => setIsPrintClicked(false)}
											class="modal fade" id="mytoursModal" tabindex="-1" aria-hidden="true"
											size="lg"
											backdrop={true}
										>
											<Modal.Header className="modal-header" closeButton>
												<Modal.Title className="modal-title fs-5">Group Details</Modal.Title>
											</Modal.Header>
											<Modal.Body className="modal-body">

												<div className="leftsubRow">
													<div className="blockFormLabel">
														<div className="form-mb">
															<label className="form-label">Confirmation ID </label>
															<h6>{confirmationDetail && confirmationDetail.length > 0 ? confirmationDetail[0].ConfirmationID : ''}</h6>
														</div>
														<div className="form-mb">
															<label className="form-label">Group Name</label>
															<h6>{confirmationDetail && confirmationDetail.length > 0 ? confirmationDetail[0].GroupName : ''}</h6>
														</div>
													</div>
												</div>
												<div className="table-responsive">
													<table className="table align-middle">
														<thead>
															<tr>
																<th>Name</th>
																<th>Email Address</th>
																<th>Waiver Status</th>
																<th>Minor</th>
																<th>Guardian</th>
															</tr>
														</thead>
														<tbody>

															{confirmationList && confirmationList.map((item) => {
																return (
																	<>
																		<tr>
																			<td>{item.Name}</td>
																			<td>{item.EmailAddress}</td>
																			<td>{(item.PaperWaiver == 2) ? "Paper" : (item.PaperWaiver == 1 ? "Completed" : "Pending")}</td>
																			<td>{item.Minor ? "Yes" : "No"}</td>
																			<td>-</td>
																		</tr>
																	</>
																)
															})
															}

														</tbody>
													</table>
												</div>
											</Modal.Body>

											{/* </ModalDialog> */}
										</Modal>
										<DashBoard></DashBoard>
										<div className="mainWrap mainTopMargin">
											<div className="container">
												<div className="mainHeadeing">
													<h1>My Tours</h1>
													<div className="rightsubRow">
														<div className="leftsubRow">
															<div className="blockFormLabel">
																<div className="form-mb">
																	<label className="form-label">Confirmation ID / Email Address / Group Name</label>
																	<input type="text" className="form-control" placeholder="Enter Confirmation ID Or Email Address Or Group Name" onChange={(e) => { onchangeEmailAddress(e.target.value) }} />
																</div>

																<div className="form-mb width-130">
																	<label className="form-label">Waiver Status</label>
																	<select className="form-select" onChange={(e) => { onchangeWaiverStatus(e.target.value) }}>
																		<option value="-1">All</option>
																		<option value="1">Completed</option>
																		<option value="0" selected>Pending</option>

																	</select>
																</div>

																<button className="btn btn-info" onClick={() => { handleSubmit() }}>Search</button>
															</div>
														</div>
													</div>
												</div>

												<div className="table-responsive">
													<table className="table  align-middle">
														<thead>
															<tr>
																<th>Confirmation ID </th>
																<th>Group Name</th>
																<th>Name</th>
																<th>Email Address</th>
																<th>Event Date</th>
																<th>Slot Time</th>
																<th>No of Tickets </th>
																<th>Waiver Status</th>
															</tr>
														</thead>
														<tbody>
															{mapData && mapData.length > 0 ?
																<>
																	{mapData.map((item) => {
																		return (
																			<tr>
																				<td onClick={() => {
																					handleGuestData(item)
																				}}><a className="confirmation" style={{ cursor: 'pointer' }}>{item.ConfirmationID}</a></td>
																				<td>{item.GroupName}</td>
																				<td>{item.Name}</td>
																				<td>{item.EmailAddress}</td>
																				<td>{item.EventDate}</td>
																				<td>{item.Slot}</td>
																				<td>{item.NoofTickets}</td>
																				<td>{item.WaiverStatus}</td>
																			</tr>
																		)
																	})}
																</>
																:
																<>
																	<td></td>
																	<td></td>
																	<td></td>
																	<h2><center>No Record Found!</center></h2></>
															}
														</tbody>
													</table>
												</div>

												<nav aria-label="Page navigation example" className="pull-right">
													<ul className="pagination" onClick={(e) => {
														setSelectedPageNo(Number(e.target.value == 0 ? selectedPageNo : e.target.value));
													}}>

														<li className="page-item active" class="page-link">
															<button
																onClick={() => {
																	if (pageNoStart !== 0) {

																		setPageNoStart(pageNoStart - 1)
																		setPageNoEnd(pageNoEnd - 1)
																	}
																}}
																value={selectedPageNo - 1} disabled={selectedPageNo <= 1} style={{ border: "none" }}>Previous</button>
														</li>
														{paginationArr.slice(pageNoStart, pageNoEnd).map((item, index) => {
															return (
																<>
																	{/* <a> */}
																	<li value={item}
																		className="page-item active" class={selectedPageNo == item ? "page-link pagination-color" : "page-link"}>
																		{item}
																	</li>
																	{/* </a> */}
																</>
															)
														})}
														{paginationArr.length > pageNoEnd && <li className="page-item active" class="page-link">...</li>}

														<li className="page-item active" class="page-link">
															<button value={selectedPageNo + 1}
																onClick={() => {
																	if (paginationArr.length > pageNoEnd) {
																		setPageNoStart(pageNoStart + 1)
																		setPageNoEnd(pageNoEnd + 1)
																	}
																}}
																disabled={selectedPageNo >= paginationArr.length} style={{ border: "none" }}>Next</button>
														</li>
													</ul>
												</nav>
											</div>
										</div>
										<Footer></Footer>
									</>



								</>
							) : (<ValidateGraphData setvalGraphData={setvalGraphData} graphData={graphData} />)}
						</>
					) : (<GetAccessToken setGraphData={setGraphData} graphData={graphData} account={accounts} instance={instance} />)}
				</>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</>
	)
}
export default MyTour;