import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "../components/Admin/Admin";
import ManageTickets from "../components/Admin/ManageTickets";
import MyTour from "../components/Admin/MyTour";
import BuyerIndividual from "../components/BuyerIndividual/BuyerIndividual";
import GroupRegistration from "../components/GroupRegistration/GroupRegistration";
import SuccessfullMessage from "../components/SuccessfullMessage/SuccessfullMessage";
import GroupRegistrationForm from "../components/GroupRegistration/GroupRegistrationForm";
import GroupRegistrationTop from "../components/GroupRegistration/GroupRegistrationTop";
import OnsiteRegistration from "../components/OnsiteRegistration/OnsiteRegistration";
import GroupSuccessfull from "../components/SuccessfullMessage/GroupSuccessfull";
import ManageTicketsAdd from "../components/Admin/ManageTicketsAdd";
import ManageTicketsEdit from "../components/Admin/ManageTicketsEdit";
import DashBoard from "../components/DashBoard/DashBoard";
import Register from "../components/Admin/TodayCompleted";
import Header from "../components/Header/Header";
import Individual from "../components/Individual/Individual";
import LoginPage from "../components/OnsiteRegistration/OnsiteLogin";
import WaiverView from "../components/Admin/WaiverView";
export const RouterConfig = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/WaiverView/:GuestID' element={<WaiverView />} />
                <Route path='/' element={<LoginPage />} />
                <Route path='/DashBoard' element={<DashBoard />} />
                <Route path='/Admin' element={<Admin />} />
                <Route path='/RegisteredDetails' element={<Register />} />
                <Route path='/ManageTickets' element={<ManageTickets />} />
                <Route path='/ManageTicketsAdd' element={<ManageTicketsAdd />} />
                <Route path='/ManageTicketsEdit' element={<ManageTicketsEdit />} />
                <Route path='/MyTour' element={<MyTour />} />
                <Route path='/BuyerIndividual' element={<BuyerIndividual />} />
                <Route path='/GroupRegistration' element={<GroupRegistration />} />
                <Route path='/GroupRegistrationForm' element={<GroupRegistrationForm />} />
                <Route path='/GroupRegistrationTop' element={<GroupRegistrationTop />} />
                <Route path='/Individual' element={<Individual />} />
                <Route path='/OnsiteRegistration' element={<OnsiteRegistration />} />
                <Route path='/SuccessfullMessage' element={<SuccessfullMessage />} />
                <Route path='/GroupSuccessfull' element={<GroupSuccessfull />} />
                <Route path="/Header" element={<Header />} />
                <Route path="/OnsiteLogin" element={<LoginPage />} />
            </Routes>
        </BrowserRouter>
    )
}
export default RouterConfig;