import React,{ useEffect } from "react";
import logo from '../../assets/images/successfully-icon.svg'
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useLocation } from "react-router";

const GroupSuccessfull = () => {
    const location=useLocation()
    useEffect(()=>{
        window.history.pushState(null,null,location.href)
        window.onpopstate = function(event) {
            window.history.go(1);
          };
    })
    return (
        <>
            <Header></Header>
            <div class="mainWrap mainTopMargin">
                <div class="container">
                    <div class="successfullyWrapp">
                        <img src={logo} alt="" />
                        <h1>Your group tickets updated was successfully sent.</h1>
                        <p>A brief blurb of the registration and waiver process goes here. The guest gets informed that waivers will be sent to the email addresses.
                            Waivers must be completed upon arrival. etc.</p>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}
export default GroupSuccessfull;