import React, { useEffect, useState, useRef } from "react";
import { Button } from 'react-bootstrap';
import axios from "axios";
import GroupRegistrationTop from "./GroupRegistrationTop";
import GroupRegistrationForm from "./GroupRegistrationForm";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router";
import '../../assets/fonts/fontawesome/css/font-awesome.css'
import { isValidEmail, isValidName, isValidTime } from "../utilities";
import { webhooks } from "../WebHooksApi/webhooks";

const GroupRegistration = () => {

  const navigate = useNavigate();
  const [response, setResponse] = useState({});
  const [ticketInfo, setTicketInfo] = useState([]);
  const [groupName, setGroupName] = useState({ groupName: "", errGroupName: "" })
  const [blurbMessage, setBlurbMessage] = useState(process.env.REACT_APP_GROUP_BLURB);
  const [formMsg, setFormMsg] = useState("<br/><br/><h1>Loading...</h1>");
  const [noofTkts, setnoofTkts] = useState(0);
  const [errormsg, seterrormsg] = useState("");
  const firstRef = useRef([]);
  const lastRef = useRef([]);
  const middleRef = useRef([]);
  const emailRef = useRef([]);
  const groupRef = useRef([]);
  const slotRef = useRef([]);
  const obj = {
    "FirstName": "",
    "MiddleName": "",
    "LastName": "",
    "EmailAddress": "",
    "IsMinor": false,
    "PaperWaiver": 0,
    "Active": true,
    "Slot": "",
    "ISGroup": ""
  }
  const [tktSlot, setTktSlot] = useState('');
  useEffect(() => {
    let path = window.location.search.replace("?", "")

    //Get details for No Of Tickets
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?queryparams=${path}`,
      headers: {
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
      }
    };

    axios(config)
      .then(function (response) {
        if (response.data.length && response.data.length > 0) {
          setResponse(response.data[0])
          setnoofTkts(response.data[0].NoOfTickets)
          let arr = [];
          for (let i = 0; i < response.data[0].NoOfTickets; i++) {

            arr.push(obj)
            arr[i] = { ...arr[i], Slot: response.data[0].Slot }

          }
          setTktSlot(response.data[0].Slot)



          arr[0] = {
            ...arr[0], FirstName: response.data[0].FirstName, MiddleName: response.data[0].MiddleName,
            LastName: response.data[0].LastName, EmailAddress: response.data[0].EmailAddress,
          };

          setTicketInfo(arr)
          for (let i = 0; i < response.data[0].Messages.length; i++) {
            if (response.data[0].Messages[i].ID == 1000) {
              setBlurbMessage(response.data[0].Messages[i].Message)
              break;
            }
          }
        }
        else if (response.data.StatusCode && response.data.StatusCode == "1004") {
          navigate('/SuccessfullMessage')
        }
        else if (response.data.StatusCode && eval(response.data.StatusCode) >= 1005) {
          setFormMsg(response.data.Message)
        }
      })
      .catch(function (error) {
        console.log(error)
      })

  }, [])



  //On Submit form
  const notValidate = () => {
    let doNotValidate = false
    let arr = [...ticketInfo]
    for (let i = 0; i < ticketInfo.length; i++) {
      arr[i] = { ...arr[i], errFirstName: '' }
      arr[i] = { ...arr[i], errMiddleName: '' }
      arr[i] = { ...arr[i], errLastName: '' }
      arr[i] = { ...arr[i], errEmailAddress: '' }
      arr[i] = { ...arr[i], errGroupName: '' }
      arr[i] = { ...arr[i], errSlot: '' }

      if ((!ticketInfo[i].FirstName || (ticketInfo[i].FirstName).trim() == "") && (!ticketInfo[i].IsMinor)) {
        arr[i] = { ...arr[i], errFirstName: ' Required' }

        doNotValidate = true
      }
      else if ((isValidName(ticketInfo[i].FirstName) && (!ticketInfo[i].IsMinor))) {
        arr[i] = { ...arr[i], errFirstName: ' Invalid Name' }
        doNotValidate = true

      }
      if ((!ticketInfo[i].MiddleName || (ticketInfo[i].MiddleName).trim() == "") && (!ticketInfo[i].IsMinor)) {
        arr[i] = { ...arr[i], errMiddleName: '' }

      }
      else if (isValidName(ticketInfo[i].MiddleName) && (!ticketInfo[i].IsMinor)) {
        arr[i] = { ...arr[i], errMiddleName: ' Invalid Name' }
        doNotValidate = true
      }
      if ((!ticketInfo[i].LastName || (ticketInfo[i].LastName).trim() == "") && (!ticketInfo[i].IsMinor)) {
        arr[i] = { ...arr[i], errLastName: ' Required' }

        doNotValidate = true
      }
      else if ((isValidName(ticketInfo[i].LastName) && (!ticketInfo[i].IsMinor))) {
        arr[i] = { ...arr[i], errLastName: ' Invalid Name' }
        doNotValidate = true

      }
      if (!ticketInfo[i].EmailAddress || ((ticketInfo[i].EmailAddress).trim() == "")) {

        arr[i] = { ...arr[i], errEmailAddress: ' Required' }

        doNotValidate = true
      } else if (!isValidEmail((ticketInfo[i].EmailAddress) || ticketInfo[i].EmailAddress.trim())) {
        arr[i] = { ...arr[i], errEmailAddress: ' Invalid Email' }
        doNotValidate = true
      }

      if (ticketInfo[i].Slot === "") {
        arr[i] = { ...arr[i], errSlot: ' Required' }
        doNotValidate = true
      }
      else if (!isValidTime(ticketInfo[i].Slot)) {
        arr[i] = { ...arr[i], errSlot: " Invalid Slot Time" }
        doNotValidate = true
      }
      else {
        arr[i] = { ...arr[i], errSlot: "" }
      }
    }
    setTicketInfo(arr)
    let count = 1;
    if (groupName.groupName == "") {
      setGroupName((old) => { return ({ ...old, errGroupName: " Required" }) })
      groupRef.current[0].focus();
      count = 0;
      doNotValidate = true
    }
    else {
      setGroupName((old) => { return ({ ...old, errGroupName: "" }) })

    }

    arr.map((items, index) => {
      if (arr.length > 0 && count == 1) {

        if (items.errFirstName) {
          firstRef.current[index].focus();
          count = 0;
        }
        else if (items.errMiddleName) {
          middleRef.current[index].focus();
          count = 0;
        }
        else if (items.errLastName) {
          lastRef.current[index].focus();
          count = 0;
        }
        else if (items.errEmailAddress) {
          emailRef.current[index].focus();
          count = 0;
        }
        else if (items.errSlot) {
          slotRef.current[index].focus();
          count = 0;
        }

      }
    })

    return doNotValidate

  }
  const handleSubmit = () => {
    if (notValidate()) return
    let finalArray = []

    finalArray[0] =
    {
      "TicketID": response.TicketID,
      "ConfirmationNumber": response.ConfirmationNumber,
      "EmailAddress": ticketInfo[0].EmailAddress,
      "GroupName": groupName.groupName,
      "ISGroup": response.ISGroup,
      "TicketDetails": [...ticketInfo]
    }
    const reqData = JSON.stringify(finalArray)

    const config = {
      method: 'POST',
      url: `${process.env.REACT_APP_WEBSITE_NAMES}/insertguestdetails`,
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
      },
      data: reqData
    };
    axios(config)
      .then(function (res) {
        if (res.data == 1) {
          const config = {
            method: 'GET',
            url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?queryparams=""&ConfirmationID=${response.ConfirmationNumber ? response.ConfirmationNumber : ""}`,
            headers: {
              'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
            }
          };
          axios(config)
            .then(function (response) {
              debugger;
              let value = `${process.env.REACT_APP_REDIRECT_REG_ON_NEWGUEST}`
              if (response.data && response.data.TicketDetails.length > 0) {
                response.data.TicketDetails.map((guest) => {
                  if (guest.PaperWaiver == 2 && guest.GuestID != "") {
                    if (guest.TicketTypeID == 2 || guest.TicketTypeID == 3) {
                      let value = `${process.env.REACT_APP_WEBHOOKS_ISACTIVE}`
                      if (value.toLowerCase() == "true" && (guest.TicketType == "VIP" || guest.TicketType == "Group")) {
                        webhooks(guest.GuestID, guest.TicketType, guest.FirstName, guest.LastName, guest.EmailAddress)
                      }
                    }
                  }
                })
              }
            })
          navigate('/SuccessfullMessage')
        }

      })
      .catch(function (error) {
        console.log(error)
      })

  }

  const parenttochild = (data) => {
    let arr = [];
    let count = 0;
    if (data.length <= noofTkts) {
      for (let i = 0; i < data.length; i++) {

        if ((data[i].FirstName || data[i].EmailAddress || data[i].LastName) && i < noofTkts) {
          let minorinputValue = data[i].IsMinor ? data[i].IsMinor.toString().toLowerCase() : undefined;
          let paperinputValue = data[i].PaperWaiver ? data[i].PaperWaiver.toString().toLowerCase() : undefined;
          const vIsMinor = (minorinputValue ? ((minorinputValue == 'yes' || minorinputValue == 'y' || minorinputValue == 't' || minorinputValue == '1' || minorinputValue == 'true') ? true : false) : false);
          const vIsPaper = (paperinputValue ? ((paperinputValue == 'yes' || paperinputValue == 'y' || paperinputValue == 't' || paperinputValue == '1' || paperinputValue == 'true') ? 2 : 0) : 0);
          arr[i] = { ...data[i], FirstName: data[i].FirstName, MiddleName: data[i].MiddleName, LastName: data[i].LastName, EmailAddress: data[i].EmailAddress, IsMinor: vIsMinor, PaperWaiver: vIsPaper }
           if (vIsMinor) {
            arr[i] = { ...data[i], FirstName: data[i].FirstName, MiddleName: data[i].MiddleName, LastName: data[i].LastName, EmailAddress: data[i].EmailAddress, IsMinor: vIsMinor, PaperWaiver: vIsPaper }
            count = count + 1;
           }
           else {
           arr[i] = { ...data[i], FirstName: data[i].FirstName, MiddleName: data[i].MiddleName, LastName: data[i].LastName, EmailAddress: data[i].EmailAddress, IsMinor: vIsMinor, PaperWaiver: vIsPaper }
          }
        }
      }
      if (arr.length > 0) {
        arr = arr.sort((a, b) => {
          if (a.FirstName && !b.FirstName) { return 1; }
          if (b.FirstName && !a.FirstName) { return -1; }
          let x = a.FirstName ? a.FirstName.toLowerCase() : '';
          let y = b.FirstName ? b.FirstName.toLowerCase() : '';
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0;
        });
        arr.sort((a, b) => {
          if (a.IsMinor && !b.IsMinor) { return 1; }
          if (b.IsMinor && !a.IsMinor) { return -1; }
          return 0;
        });
        for (let i = arr.length; i < noofTkts; i++) {
          arr.push(obj);
        }
        if (count != data.length) {
          setTicketInfo(arr);
          seterrormsg("");
        }
        else {
          seterrormsg("Parent/Guardian is missing");
        }

      }
    }
    else {
      seterrormsg("Upload Count is more than tickets")
    }
  }
  const childdataform = (data) => {
    setTicketInfo(data)
  }
  return (

    <React.Fragment>

      <Header></Header>
      <div className="mainWrap mainTopMargin groupWrapp">
        <div className="container">
          {ticketInfo.length > 0 ?
            <>
              <section>
                <GroupRegistrationTop
                  setGroupName={setGroupName}
                  groupName={groupName}
                  ticketInfo={ticketInfo}
                  noOfTickets={response?.NoOfTickets}
                  slot={tktSlot}
                  parenttochild={parenttochild}
                  errormessage={errormsg}
                  seterrormessage={seterrormsg}
                  blurbMessage={blurbMessage}
                  formMsg={formMsg}
                  groupRef={groupRef}
                ></GroupRegistrationTop>
              </section>
              <div className="ticketInformationBlock">
                <div className="leftsubRow">
                  <h2 className="ticketInformationLabel">Your Ticket Information:</h2>
                  <h6><i className="fa fa-calendar-check-o" aria-hidden="true"></i> {response.EventDate} </h6>
                  <h6><i className="fa fa-clock-o" aria-hidden="true"></i> {response.Slot}</h6>
                  <h6><i className="fa fa-user" aria-hidden="true"></i> {response.TicketType}</h6>
                  <h6><i className="fa fa-ticket" aria-hidden="true"></i>{response.NoOfTickets}</h6>
                </div>
              </div>{
                ticketInfo.map((items, index) => {
                  return (
                    <GroupRegistrationForm
                      items={items}
                      index={index}
                      ticketInfo={ticketInfo}
                      setTicketInfo={setTicketInfo}
                      slots={response.AvailableSlots}
                      formMsg={formMsg}
                      firstRef={firstRef}
                      middleRef={middleRef}
                      lastRef={lastRef}
                      emailRef={emailRef}
                      slotRef={slotRef}
                    >
                      TicketID={response.TicketID}
                      ConfirmationNumber={response.ConfirmationNumber}
                    </GroupRegistrationForm>
                  )
                })
              }

              <div>
                <div className="cardFooter centerCardFooter">
                  <Button className="btn btn-info" onClick={() => { handleSubmit() }}>SUBMIT</Button>
                </div>
              </div>
            </> :
            <>
              <center><p dangerouslySetInnerHTML={{ __html: formMsg }} /></center>
            </>}
        </div>

      </div>

      <Footer></Footer>

    </React.Fragment>

  )

};

export default GroupRegistration;
