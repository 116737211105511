import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router';
import axios from 'axios';

export default function WaiverView() {
  const GuestID = useParams();
  const encodedstring = atob(GuestID.GuestID);
  const [ticketDetails, setTicketDetails] = useState([])
  const obj = {
    "GuestID": "",
    "FirstName": "",
    "MiddleName": "",
    "LastName": "",
    "EmailAddress": "",
    "IsMinor": "",
    "WaiverStatus": "",
    "ConsentSignature": "",
    "EventDate": "",
    "Slot": "",
    "ConsentSignedDate": "",
    "Terms_Value": "",
    "Policy_Value": "",

  }
  useEffect(() => {
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_WEBSITE_NAMES}/getpolicydetails?GuestID=${encodedstring}`,
      headers: {
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
      }
    };
    axios(config)
      .then(function (response) {
        let arr = [];
        arr.push(obj);
        arr[0] = {
          ...arr[0], Terms_Value: response.data.Guest[0].Terms_Value, Policy_Value: response.data.Guest[0].Policy_Value
          , FirstName: response.data.Guest[0].FirstName, MiddleName: response.data.Guest[0].MiddleName, LastName: response.data.Guest[0].LastName
          , EmailAddress: response.data.Guest[0].EmailAddress, IsMinor: response.data.Guest[0].IsMinor, WaiverStatus: response.data.Guest[0].WaiverStatus
          , ConsentSignature: response.data.Guest[0].ConsentSignature, EventDate: response.data.Guest[0].EventDate, Slot: response.data.Guest[0].Slot
          , ConsentSignedDate: response.data.Guest[0].ConsentSignedDate
        }
        setTicketDetails(arr);
      })
  }, [])
  console.log("myarr", ticketDetails[0])
  const handlePrint = () => {
    window.print();
  };
  return (
    <>

      <div className='mainbackground'>

        {ticketDetails.length > 0 ? <>
          <div className='totalhtmlbackground'>
            <div className='download-backgroundmain'>
              <button onClick={handlePrint}><i class="fa fa-print download-background" aria-hidden="true"></i></button>
            </div>
            <div id='content-to-convert'>
              {/* <a onClick={handlePrint()} className='fa fa-print' href=''></a> */}
              {/* <i onClick={window.print()} class="fa fa-print download-background" aria-hidden="true"></i> */}

              <p dangerouslySetInnerHTML={{ __html: ticketDetails[0].Terms_Value }} />
              <p dangerouslySetInnerHTML={{ __html: ticketDetails[0].Policy_Value }} />
              <div className="mainWrap mainTopMargin individualWrapp">
                {ticketDetails && ticketDetails.length > 0 ? <><div className="container">
                  <div className="individualBlockforGuest">
                    <div>
                      <p><b>Guest Information</b></p>
                    </div>
                    <Card className="card">
                      <div className="card-body">
                        <div className="formBlockDefault">
                          <Row className="row">
                            <Col className="col-lg-3 col-md-4 col-sm-6">
                              <div className="form-mb">
                                <label className="form-label">First Name</label>
                                <label>{ticketDetails[0].FirstName}</label>
                              </div>
                            </Col>

                            <Col className="col-lg-3 col-md-4 col-sm-6">
                              <div className="form-mb">
                                <label className="form-label">Middle Name</label>
                                <label> {ticketDetails[0].MiddleName}</label>
                              </div>
                            </Col>

                            <Col className="col-lg-3 col-md-4 col-sm-6">
                              <div className="form-mb">
                                <label className="form-label">Last Name</label>
                                <label>{ticketDetails[0].LastName}</label>
                              </div>
                            </Col>

                            <Col className="col-lg-3 col-md-4 col-sm-6">
                              <div className="form-mb">
                                <label className="form-label">Email Address</label>
                                <label>{ticketDetails[0].EmailAddress}</label>
                              </div>
                            </Col>
                          </Row>
                          {ticketDetails[0].IsMinor == 0 ?
                            <>
                              <div className="individual_mediaLicense" >
                                <Row className="row">
                                  <Col className="col-md-12">
                                    <div className="mb-3">
                                      <h3>SoFi Stadium Tour Media License and Release, Waiver of Liability/Indemnity, and Marketing Consent
                                      </h3>
                                      <p>By signing my last name below, I &nbsp;<span className="signingIndividual">
                                        {ticketDetails[0].length > 0 ? ticketDetails[0].FirstName + " " + (ticketDetails[0].MiddleName ? (ticketDetails[0].MiddleName + " ") : "") +
                                          (ticketDetails[0].LastName) : ticketDetails[0].FirstName + " " + (ticketDetails[0].MiddleName ? (ticketDetails[0].MiddleName + " ") : "") +
                                        ticketDetails[0].LastName}</span>&nbsp;  acknowledge
                                        that I have had the opportunity to review the  {""}
                                        <a href="https://www.sofistadium.com/tourswaiver" target="_blank"> SoFi Stadium Media License and Release and Waiver of Liability/Indemnity Agreement</a>
                                        {""} and that I agree to be bound by all of its terms and conditions.
                                      </p>
                                    </div>
                                  </Col>

                                  <Col className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="mb-3">
                                      <label className="form-label">Last Name</label>

                                      <label>{ticketDetails[0].LastName}</label>

                                    </div>
                                  </Col>

                                  <Col className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="mb-3">
                                      <label className="form-label">Date</label>
                                      <div className="date datepickerCss" data-date-format="mm-dd-yyyy">
                                        <label>{ticketDetails[0].ConsentSignedDate}</label>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div></> : <>
                              <p>
                                I am the parent or legal guardian of the minor named above. I have the legal right to consent to and, by signing below,
                                I hereby do consent in all respects to the terms and conditions of the   {""}
                                <a href="https://www.sofistadium.com/tourswaiver" target="_blank">SoFi Stadium Media License and Release and Waiver of Liability/Indemnity Agreement</a>
                                {""} and agree that both the minor and I shall be bound by all of its terms and conditions</p>
                              <Row className="row">
                                <Col className="col-lg-4 col-md-12 col-sm-9">
                                  <div className="mb-3">
                                    <label className="form-label">Last Name</label>
                                    <label>{ticketDetails[0].LastName}</label>
                                  </div>
                                </Col>
                                <Col className="col-lg-4 col-md-12 col-sm-9">
                                  <div className="mb-3">
                                    <label className="form-label" >Printed Name of Parent or Legal Guardian * </label>
                                    <label>{ticketDetails[0].FirstName + " " + (ticketDetails[0].MiddleName ? (ticketDetails[0].MiddleName + " ") : "") + ticketDetails[0].LastName}</label>
                                  </div>
                                </Col>
                                <Col className="col-lg-4 col-md-12 col-sm-9">
                                  <div className="mb-3">
                                    <label className="form-label">Date *</label>
                                    <div className="date datepickerCss" data-date-format="mm-dd-yyyy">
                                      <label>{ticketDetails[0].ConsentSignedDate}</label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </>}
                        </div>
                      </div>
                    </Card>
                  </div>
                </div></> : ""}
              </div></div></div></> : ""}

      </div>
    </>
  )
}