import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Card, Row, Col, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { useState, useRef } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router";

import { isValidEmail, isValidName, isValidDate } from "../utilities";

const Individual = () => {
  let newDate = new Date();
  let date = moment(newDate).format("MM-DD-YYYY")
  const ShowTime = moment(newDate).format("h:mm A")
  const [response, setResponse] = useState([]);
  const [ticketInfo, setTicketInfo] = useState([]);
  const [agree, setAgree] = useState(false);
  const [formMsg, setFormMsg] = useState("<br/><br/><h1>Loading...</h1>");
  const firstRef = useRef([]);
  const lastRef = useRef([]);
  const middleRef = useRef([]);
  const emailRef = useRef([]);
  const consentSignatureRef = useRef([])
  /*added on 14-04-2023*/
  const [acceptAgree, setAcceptAgree] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate()
  const obj = {
    "GuestID": "",
    "FirstName": "",
    "MiddleName": "",
    "LastName": "",
    "EmailAddress": "",
    "Active": true,
    "WaiverStatus": false,
    "IsMinor": false,
    "ConsentPrintName": "",
    "ConsentSignature": "",
    "ConsentSignedDate": "",
    "errFirstName": "",
    "errMiddleName": "",
    "errLastName": "",
    "errEmailAddress": "",
    "errConsentPrintName": "",
    "errConsentSignature": "",
    "errConsentSignedDate": "",
  }

  useEffect(() => {
    let data = window.location.search.replace("?", "")

    /***** TO GET TICKET DETAILS => GET SERVICE   *****/
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_WEBSITE_NAMES}/getguestdetailsforwaiverstatus?queryparams=${data}`,
      headers: {
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
      }
    };
    axios(config)
      .then(function (response) {
        if (response.data.length && response.data.length > 0) {
          setResponse(response.data[0])
          let arr = [];
          let length = response.data[0].Minors ? response.data[0].Minors.length + 1 : 1
          for (let i = 0; i < length; i++) {
            arr.push(obj)
            if (i >= 1 && response.data[0].Minors && response.data[0].Minors.length > 0) {
              arr[i] = {
                ...arr[i], GuestID: response.data[0].Minors[i - 1].GuestID, ConsentSignedDate: date,
                IsMinor: true, ConsentPrintName: response.data[0].FirstName + " " + (response.data[0].MiddleName ? (response.data[0].MiddleName + " ") : "") + response.data[0].LastName,
              }
            }
          }

          arr[0] = {
            ...arr[0], FirstName: response.data[0].FirstName, MiddleName: response.data[0].MiddleName,
            LastName: response.data[0].LastName, EmailAddress: response.data[0].EmailAddress,
            GuestID: response.data[0].GuestID, ConsentSignedDate: date, IsMinor: false,
            ConsentPrintName: response.data[0].FirstName + " " + (response.data[0].MiddleName ? (response.data[0].MiddleName + " ") : "") + response.data[0].LastName,
          };
          setTicketInfo(arr)
        }
        else if (response.data.StatusCode && response.data.StatusCode == "1004") {
          navigate('/SuccessfullMessage')

        }
        else if (response.data.StatusCode && eval(response.data.StatusCode) >= 1005) {
          setFormMsg(response.data.Message)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [])


  /**-----   FORM VALIDATIONS   ------**/
  const notValidate = () => {
    let doNotValidate = false
    let arr = [...ticketInfo]

    for (let i = 0; i < ticketInfo.length; i++) {
      arr[i] = { ...arr[i], errFirstName: '' }
      arr[i] = { ...arr[i], errMiddleName: '' }
      arr[i] = { ...arr[i], errLastName: '' }
      arr[i] = { ...arr[i], errEmailAddress: '' }
      arr[i] = { ...arr[i], errConsentPrintName: '' }
      arr[i] = { ...arr[i], errConsentSignature: '' }
      arr[i] = { ...arr[i], errConsentSignedDate: '' }




      if ((ticketInfo[i].FirstName).trim() == "") {
        arr[i] = { ...arr[i], errFirstName: 'Required' }

        doNotValidate = true
      }
      else if ((isValidName(ticketInfo[i].FirstName) && ((!ticketInfo[i].IsMinor) || (ticketInfo[i].IsMinor)))) {
        arr[i] = { ...arr[i], errFirstName: 'Invalid Name' }
        doNotValidate = true
      }
      if ((!ticketInfo[i].MiddleName || (ticketInfo[i].MiddleName).trim() == "") &&
        ((!ticketInfo[i].IsMinor) || (ticketInfo[i].IsMinor))) {
        arr[i] = { ...arr[i], errMiddleName: '' }
      }
      else if (isValidName(ticketInfo[i].MiddleName) &&
        ((!ticketInfo[i].IsMinor) || (ticketInfo[i].IsMinor))) {
        arr[i] = { ...arr[i], errMiddleName: 'Invalid Name' }
        doNotValidate = true
      }

      if ((ticketInfo[i].LastName).trim() == "") {

        arr[i] = { ...arr[i], errLastName: 'Required' }

        doNotValidate = true
      }
      else if ((isValidName(ticketInfo[i].LastName) && ((!ticketInfo[i].IsMinor) || (ticketInfo[i].IsMinor)))) {
        arr[i] = { ...arr[i], errLastName: 'Invalid Name' }
        doNotValidate = true

      }
      if (ticketInfo[i].IsMinor == 0 && ticketInfo[i].EmailAddress.trim() == "") {

        arr[i] = { ...arr[i], errEmailAddress: 'Required' }

        doNotValidate = true
      } else if (ticketInfo[i].IsMinor == 0 && !isValidEmail(ticketInfo[i].EmailAddress)) {
        arr[i] = { ...arr[i], errEmailAddress: 'Invalid Email' }
        doNotValidate = true
      }

      if (!(ticketInfo[0].ConsentSignature)) {
        arr[0] = { ...arr[0], errConsentSignature: 'Required' }
        doNotValidate = true
      }
      else if ((ticketInfo[0].ConsentSignature) && ticketInfo[0].ConsentSignature != ticketInfo[0].LastName) {
        arr[0] = { ...arr[0], errConsentSignature: 'Signature/Last Name match with the signers (guests) last name' }
        doNotValidate = true
      }
      if ((!ticketInfo[i].ConsentSignature) && i > 0) {
        arr[i] = { ...arr[i], errConsentSignature: 'Read and check here to Agree' }
        doNotValidate = true
      }

      if (!(ticketInfo[i].ConsentSignedDate)) {
        arr[i] = { ...arr[i], errConsentSignedDate: 'Required' }

        doNotValidate = true
      }

    }

    let count = 1;

    arr.map((items, index) => {
      if (arr.length > 0 && count == 1) {

        if (items.errFirstName) {
          firstRef.current[index].focus();
          count = 0;
        }
        else if (items.errMiddleName) {
          middleRef.current[index].focus();
          count = 0;
        }
        else if (items.errLastName) {
          lastRef.current[index].focus();
          count = 0;
        }
        else if (items.errEmailAddress) {
          emailRef.current[index].focus();
          count = 0;
        }
        else if (items.errConsentSignature && (index == 0 || arr.length == (index + 1))) {
          consentSignatureRef.current[index == 0 ? index : 1].focus();
          count = 0;
        }
      }
    })
    setTicketInfo(arr)

    return doNotValidate

  }

  const checkBoxHandler = (val) => {
    if (val == true) {
      setAgree(val);
      setIsShow(val);
      setAcceptAgree(val);
    }
    else {
      setAcceptAgree(val);
    }

  }
  /*----- ON SUBMIT HANDLE FUNCTION ------ */
  const onSubmit = () => {
    if (notValidate()) return
    let finalArray = []
    finalArray[0] = {

      "ConfirmationNumber": response.ConfirmationNumber,
      "TicketDetails": [...ticketInfo]
    }
    const reqData = JSON.stringify(finalArray)

    //----- UPDATE REQUEST ------//

    const config = {
      method: 'PUT',
      url: `${process.env.REACT_APP_WEBSITE_NAMES}/updatewaiverstatus`,
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
      },
      data: reqData,

    };
    axios(config)
      .then(function (response) {
        if (response.data == 1) {
          navigate('/SuccessfullMessage')
        }
      })
      .catch(function (error) {
      });

  }

  const onChangeFirstName = (val) => {
    let arr = [...ticketInfo]
    arr[0] = { ...arr[0], FirstName: val, errFirstName: '' }
    setTicketInfo(arr)
  }
  const onChangeMiddleName = (val) => {
    let arr = [...ticketInfo]
    arr[0] = { ...arr[0], MiddleName: val, errMiddleName: '' }
    setTicketInfo(arr)
  }
  const onChangeLastName = (val) => {
    let arr = [...ticketInfo]
    arr[0] = { ...arr[0], LastName: val, errLastName: '', ConsentPrintName: val }
    for (let i = 1; i < arr.length; i++) {
      arr[i] = { ...arr[i], ConsentPrintName: val }
    }


    setTicketInfo(arr)
  }
  const onChangeEmailAddress = (val) => {
    let arr = [...ticketInfo]
    arr[0] = { ...arr[0], EmailAddress: val, Guardian: val, errEmailAddress: '' }
    setTicketInfo(arr)
  }
  const onChangeConsentSignature = (val) => {

    let arr = [...ticketInfo]
    arr[0] = { ...arr[0], ConsentSignature: val, errConsentSignature: '' };
    setTicketInfo(arr)
  }

  const onChangeConsentSignedDate = (val) => {
    isValidDate(val)
    let arr = [...ticketInfo]
    for (let index = 0; index < arr.length; index++) {
      arr[index] = { ...arr[index], ConsentSignedDate: val, errConsentSignedDate: '' }
    }
    setTicketInfo(arr)
  }
  const onChangeMinorFirstName = (val, index) => {
    let arr = [...ticketInfo]
    arr[index + 1] = { ...arr[index + 1], FirstName: val, errFirstName: '' }
    setTicketInfo(arr)
  }
  const onChangeMinorMiddleName = (val, index) => {
    let arr = [...ticketInfo]
    arr[index + 1] = { ...arr[index + 1], MiddleName: val, errMiddleName: '' }
    setTicketInfo(arr)
  }
  const onChangeMinorLastName = (val, index) => {
    let arr = [...ticketInfo]
    arr[index + 1] = { ...arr[index + 1], LastName: val, errLastName: '' }
    setTicketInfo(arr)
  }
  const onChangeMinorGuardianSignature = (val, index) => {
    let arr = [...ticketInfo]
    if (val && index != 0) {
      for (let index = 1; index < arr.length; index++) {
        arr[index] = { ...arr[index], ConsentSignature: arr[0].LastName, errConsentSignature: '' }
      }
    }
    else {
      arr[index] = { ...arr[index], ConsentSignature: val, errConsentSignature: '' }
    }
    setTicketInfo(arr)
  }

  const onChangeMinorGuardianName = (val, index) => {
    let arr = [...ticketInfo]
    for (index = 1; index < arr.length; index++) {

      arr[index] = { ...arr[index], ConsentPrintName: val, errConsentPrintName: '' }
    }
    setTicketInfo(arr)
  }

  /*added on 14-04-2023*/
  const onAccept = () => {
    setIsShow(false)
    setAgree(false);
    setAcceptAgree(true);
  }
  const onCancel = () => {
    setIsShow(false);
    setAgree(false);
    setAcceptAgree(false);
  }
  const handleKeyDown = e => {
		if (e.key === " ") {
			e.preventDefault();
		}
	};
  return (
    <>
      <Header></Header>
      <div className="mainWrap mainTopMargin individualWrapp">
        <div className="container">
          {ticketInfo.length > 0 ? <>
            <div className="mainHeadeing">
              <h1>My Tour Registration</h1>
              <div className="subRow">
                <div className="leftsubRow">
                  <p className="asterick">Note:<span className="asterick-size">' * '</span>Fields that are required must be filled out.</p>
                </div>
                <div className="rightsubRow">
                  <h2 className="ticketInformationLabel">Your Ticket Information:</h2>
                  <h6><i className="fa fa-calendar-check-o" aria-hidden="true"></i> {response.EventDate}</h6>
                  <h6><i className="fa fa-clock-o" aria-hidden="true"></i> {response.Slot}</h6>
                  <h6><i className="fa fa-user" aria-hidden="true"></i>{response.TicketType}</h6>
                </div>
              </div>
            </div>
            <div className="individualBlock">
              <Card className="card">
                <div className="card-body">
                  <div className="formBlockDefault">
                    <Row className="row">
                      <Col className="col-lg-3 col-md-4 col-sm-6">
                        <div className="form-mb">
                          <label className="form-label">First Name *<span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[0].errFirstName : ""}</span></label>
                          <input type="text" ref={(ref) => (firstRef.current[0] = ref)} maxLength="100" className="form-control" placeholder="Enter first name" defaultValue={response.FirstName} onChange={(e) => { onChangeFirstName(e.target.value) }} />
                        </div>
                      </Col>

                      <Col className="col-lg-3 col-md-4 col-sm-6">
                        <div className="form-mb">
                          <label className="form-label">Middle Name<span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[0].errMiddleName : ""}</span></label>
                          <input type="text" ref={(ref) => (middleRef.current[0] = ref)} maxLength="100" className="form-control" placeholder="Enter middle name" defaultValue={response.MiddleName} onChange={(e) => { onChangeMiddleName(e.target.value) }} />
                        </div>
                      </Col>

                      <Col className="col-lg-3 col-md-4 col-sm-6">
                        <div className="form-mb">
                          <label className="form-label">Last Name *<span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[0].errLastName : ""}</span></label>
                          <input type="text" ref={(ref) => (lastRef.current[0] = ref)} maxLength="100" className="form-control" placeholder="Enter last name" defaultValue={response.LastName} onChange={(e) => { onChangeLastName(e.target.value) }} />
                        </div>
                      </Col>

                      <Col className="col-lg-3 col-md-4 col-sm-6">
                        <div className="form-mb">
                          <label className="form-label">Email Address *<span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[0].errEmailAddress : ""}</span></label>
                          <input type="text" ref={(ref) => (emailRef.current[0] = ref)} maxLength="256" readOnly className="form-control" defaultValue={response.EmailAddress} onChange={(e) => { onChangeEmailAddress(e.target.value) }}
                            placeholder="Enter email address"  onKeyDown={handleKeyDown}/>
                        </div>
                      </Col>
                    </Row>

                    <div className="individual_mediaLicense" >
                      <Row className="row">
                        <Col className="col-md-12">
                          <div className="mb-3">
                            <h3>SoFi Stadium Tour Media License and Release, Waiver of Liability/Indemnity, and Marketing Consent
                            </h3>
                            <p>By signing my last name below, I &nbsp;<span className="signingIndividual">
                              {ticketInfo.length > 0 ? ticketInfo[0].FirstName + " " + (ticketInfo[0].MiddleName ? (ticketInfo[0].MiddleName + " ") : "") +
                                (ticketInfo[0].LastName) : response.FirstName + " " + (response.MiddleName ? (response.MiddleName + " ") : "") +
                              response.LastName}</span>&nbsp;  acknowledge
                              that I have had the opportunity to review the  {""}
                              <a href="https://www.sofistadium.com/tourswaiver" target="_blank"> SoFi Stadium Media License and Release and Waiver of Liability/Indemnity Agreement</a>
                              {""} and that I agree to be bound by all of its terms and conditions.
                            </p>
                          </div>
                        </Col>

                        <Col className="col-lg-6 col-md-6 col-sm-6">
                          <div className="mb-3">
                            <label className="form-label">Last Name *<span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[0].errConsentSignature : ""}</span></label>

                            <input maxLength="100" ref={(ref) => (consentSignatureRef.current[0] = ref)} className="signatureControl" onChange={(e) => { onChangeConsentSignature(e.target.value) }} />

                          </div>
                        </Col>

                        <Col className="col-lg-4 col-md-6 col-sm-6">
                          <div className="mb-3">
                            <label className="form-label">Date *</label>
                            <div className="date datepickerCss" data-date-format="mm-dd-yyyy">
                              <input readOnly className="form-control" type="text" placeholder="mm-dd-yyyy" value={ticketInfo.length > 0 ? ticketInfo[0].ConsentSignedDate : ""} onChange={(e) => { onChangeConsentSignedDate(e.target.value) }} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="individual_mediaLicense minorsInformation">
                      {response.Minors ? <>       <h3>Required To Be Completed If Guest Is A Minor    </h3>
                        <h4>Minor's Information</h4>
                      </> : ""}
                      {response.Minors && response.Minors.length !== 0 &&
                        (response.Minors.map((item, index) => {
                          return (

                            <Row lassName="row">
                              <Col className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-mb">
                                  <label className="form-label">First Name * <span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[index + 1].errFirstName : ""}</span></label>
                                  <input type="text" ref={(ref) => (firstRef.current[index + 1] = ref)} className="form-control" placeholder="Enter first name" onChange={(e) => { onChangeMinorFirstName(e.target.value, index) }} />
                                </div>
                              </Col>

                              <Col className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-mb">
                                  <label className="form-label">Middle Name <span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[index + 1].errMiddleName : ""}</span></label>
                                  <input type="text" ref={(ref) => (middleRef.current[index + 1] = ref)} className="form-control" placeholder="Enter middle name" onChange={(e) => { onChangeMinorMiddleName(e.target.value, index) }} />
                                </div>
                              </Col>

                              <Col className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-mb">
                                  <label className="form-label">Last Name *<span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[index + 1].errLastName : ""}</span></label>
                                  <input type="text" ref={(ref) => (lastRef.current[index + 1] = ref)} className="form-control" placeholder="Enter last name" onChange={(e) => { onChangeMinorLastName(e.target.value, index) }} />
                                </div>
                              </Col>
                            </Row>
                          )
                        }))}
                    </div>

                    {response.Minors ? <>  <span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[ticketInfo.length - 1].errConsentSignature : ""}</span>  <p>
                      <input ref={(ref) => (consentSignatureRef.current[1] = ref)} className="form-check-input thisIsMe" type="checkbox" onChange={(e) => {
                        onChangeMinorGuardianSignature(e.target.checked, [ticketInfo.length - 1])
                      }} value="" id="flexCheckDefault2" />
                      I am the parent or legal guardian of the minor named above. I have the legal right to consent to and, by signing below,
                      I hereby do consent in all respects to the terms and conditions of the   {""}
                      <a href="https://www.sofistadium.com/tourswaiver" target="_blank">SoFi Stadium Media License and Release and Waiver of Liability/Indemnity Agreement</a>
                      {""} and agree that both the minor and I shall be bound by all of its terms and conditions</p>


                      <Row className="row">
                        {!response.Minors &&
                          <Col className="col-lg-6 col-md-12 col-sm-12">
                            <div className="mb-3">
                              <label className="form-label">Last Name *<span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[ticketInfo.length - 1].errConsentSignature : ""}</span> </label>
                              <input ref={(ref) => (consentSignatureRef.current[0] = ref)} maxLength="100" className="signatureControl" onChange={(e) => { onChangeMinorGuardianSignature(e.target.value) }}>

                              </input>
                            </div>
                          </Col>}

                        <Col className="col-lg-3 col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label" >Printed Name of Parent or Legal Guardian * </label>
                            <input maxLength="100" type="text" className="form-control" placeholder="Enter name" value={ticketInfo.length > 0 ? ticketInfo[0].FirstName + " " + (ticketInfo[0].MiddleName ? (ticketInfo[0].MiddleName + " ") : "") +
                              (ticketInfo[0].LastName) : response.FirstName + " " + (response.MiddleName ? (response.MiddleName + " ") : "") +
                            response.LastName} onChange={(e) => { onChangeMinorGuardianName(e.target.value) }} />
                          </div>
                        </Col>

                        {!response.Minors && <Col className="col-lg-3 col-md-6 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label">Date *</label>
                            <div className="date datepickerCss" data-date-format="mm-dd-yyyy">
                              <input readOnly className="form-control" type="text" placeholder="mm-dd-yyyy" value={ticketInfo.length > 0 ? ticketInfo[0].ConsentSignedDate : ""} onChange={(e) => { onChangeConsentSignedDate(e.target.value) }} />
                            </div>
                          </div>
                        </Col>}
                      </Row></> : ""}

                  </div>
                </div>
              </Card>
            </div>
            <div className="individual_termsPrivacy_checkbox ">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" checked={(acceptAgree == false) ? false : true} id="flexCheckDefault3" onChange={(e) => { checkBoxHandler(e.target.checked) }} />

                <label className="form-check-label" for="flexCheckDefault3">I agree to the Terms and Privacy Policy</label>
              </div>
            </div>
            <div className="cardFooter centerCardFooter">
              <Button disabled={!acceptAgree} className="btn btn-info" onClick={() => onSubmit()}>SUBMIT</Button>
            </div>
          </> :
            <>
              <center><p dangerouslySetInnerHTML={{ __html: formMsg }} /></center>
            </>}
        </div>
      </div>
      {agree && isShow &&
        <Modal centered show className="individualBlock-iframe">
          <div className="modal-content">
            <Modal.Body className="privacy-policy">
              <div>
                <iframe className="privacy-policy-iframe" src="https://urldefense.com/v3/__https:/hollywoodparkca.com/terms-of-use/__;!!P9g1NVG6!pO7V-L-_MFtJ1hwjU5l1tOaOO3W7tzfrZ72HS2sxtBbKWj7aB0UsKjq2fbk9QNJXH6YCvlSPQDxQZU5OlFKthTrSZQpk3A$">
                </iframe>
              </div>
              <div>
                <iframe className="privacy-policy-iframe" src="https://urldefense.com/v3/__https:/hollywoodparkca.com/policies/__;!!P9g1NVG6!pO7V-L-_MFtJ1hwjU5l1tOaOO3W7tzfrZ72HS2sxtBbKWj7aB0UsKjq2fbk9QNJXH6YCvlSPQDxQZU5OlFKthToSXoXHgw$">
                </iframe>
              </div>
              <div className="cardFooter centerCardFooter">
                <><Button className="btn-iagree btn-info" onClick={() => onAccept()} >I  ACCEPT</Button>
                  <Button className="btn-iagree btn-info" onClick={() => onCancel()} >Cancel</Button>
                </>
              </div>
            </Modal.Body>
          </div>
        </Modal>}
      <Footer></Footer>
    </>
  )
}
export default Individual;

