import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Login from "../../AzureLogin/Login";
import ValidateGraphData from "../../AzureLogin/ValidateGraphAPI";
import GetAccessToken from "../../AzureLogin/GetAccessToken";
import { isValidEmail, isValidDigit, isValidTime, isValidDate } from "../utilities";
/*Added webhooks*/
import { webhooks } from "../WebHooksApi/webhooks";
import moment from "moment";
import { useEffect } from "react";
import GetUTCTimeZone from "../GetUTCTimeZone";


const ManageTicketsAdd = () => {
	const ticketTypearr = [{}]
	const currentdate = new Date();
	let date = moment(GetUTCTimeZone(currentdate)).format("MM-DD-YYYY");
	//let date = moment(currentdate).format("MM-DD-YYYY")
	let utcDate = moment(GetUTCTimeZone(currentdate)).format("YYYYMMDDhhmmss")
	const location = useLocation();
	const navigate = useNavigate();
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState('');
	const [disable, setDisable] = useState(false)
	const [firstName, setFirstName] = useState({ firstName: "", errFirstName: "" })
	const [lastName, setLastName] = useState({ lastName: "", errLastName: "" })
	const [confirmationNumber, setConfirmationNumber] = useState({ confirmationNumber: "", errConfirmationNumber: "" })
	const [emailAddress, setEmailAddress] = useState({ emailAddress: "", errEmailAddress: "" })
	const [TicketType, setTicketType] = useState(1);
	const [NoofTickets, setNoofTickets] = useState({ NoofTickets: "", errNoofTickets: "" });
	const [slot, setSlot] = useState({ Slot: "", errSlot: "" });
	const [eventDate, setEventDate] = useState({ EventDate: "", errEventDate: "" })
	const { instance, accounts } = useMsal();
	const [graphData, setGraphData] = useState(null);
	const [valgraphData, setvalGraphData] = useState(false)
	//console.log("utcdate", date);

	var addTicketDetails = location?.state?.ticketDetails ? location?.state?.ticketDetails : "";

	useEffect(() => {
		if (addTicketDetails == "") {
			const config = {
				method: 'GET',
				url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid`,
				headers: {
					'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
				}
			};

			axios(config)
				.then(function (response) {
					console.log("tickettype", response)
					if (response.data && response.data.length > 0) {
						setOptions(response.data)
					}
				})
		}
	}, [])

	let obj = {
		"FirstName": "",
		"MiddleName": "",
		"LastName": "",
		"Slot": location?.state?.ticketDetails?.Slot ? location.state.ticketDetails.Slot : "",
		"EmailAddress": location?.state?.ticketDetails?.EmailAddress ? location.state.ticketDetails.EmailAddress : "",
		// "ConfirmationID": location?.state?.ticketDetails?.ConfirmationID ? location.state.ticketDetails.ConfirmationID : "",
		"ConfirmationID": location?.state?.ticketDetails?.ConfirmationID ? location.state.ticketDetails.ConfirmationID : utcDate,
		"TicketID": location?.state?.ticketDetails?.TicketID ? location.state.ticketDetails.TicketID : null,
		"NoofTickets": null,
		"IsMinor": location?.state?.ticketDetails?.Minor ? location.state.ticketDetails.Minor : false,
		"TicketType": "",
		"errFirstName": "",
		"errMiddleName": "",
		"errLastName": "",
		"errConfirmationNumber": "",
		"errEmailAddress": "",
		"errNoofTickets": "",
		"errSlot": "",
		"GroupName": location?.state?.ticketDetails?.GroupName ? location.state.ticketDetails.GroupName : "Individual",
		"EventDate": location?.state?.ticketDetails?.EventDate ? location?.state?.ticketDetails?.EventDate : date,
		"errEventDate": "",
		"PaperWaiver": location?.state?.ticketDetails?.WaiverStatus ? location.state.ticketDetails.WaiverStatus : 0,
		"IsGroup": false /*Added for Pre-Production changes on 14-07-2023*/
	}

	const [ticketInfo, setTicketInfo] = useState(obj);

	const handleTimeChange = (event) => {
		const inputTime = event.target.value;
		const colonAddedTime = insertColonAfterHour(inputTime);
		setTicketInfo({
			...ticketInfo, Slot: colonAddedTime, errSlot: ''
		})
	};

	const insertColonAfterHour = (timeStr) => {
		const cleanedTime = timeStr.replace(/[^\d]/g, '');
		if (cleanedTime.length > 2) {
			const hour = cleanedTime.slice(0, 2);
			const minute = cleanedTime.slice(2);
			return `${hour}:${minute}`;
		}

		return cleanedTime;
	};
	/*****   Form Validations   *****/
	const notValidate = () => {
		let doNotValidate = false
		if (ticketInfo.FirstName == "") {
			setFirstName((old) => { return ({ ...old, errFirstName: " Required" }) })
			doNotValidate = true
		}
		else {
			setFirstName((old) => { return ({ ...old, errFirstName: "" }) })
		}

		if (ticketInfo.LastName == "") {
			setLastName((old) => { return ({ ...old, errLastName: " Required" }) })
			doNotValidate = true
		}
		else {
			setLastName((old) => { return ({ ...old, errLastName: "" }) })

		}
		if (ticketInfo.ConfirmationID === "") {
			setConfirmationNumber((old) => { return ({ ...old, errConfirmationNumber: " Required" }) })
			doNotValidate = true
		}
		else {
			setConfirmationNumber((old) => { return ({ ...old, errConfirmationNumber: "" }) })
		}
		if (ticketInfo.EmailAddress === "") {
			setEmailAddress((old) => { return ({ ...old, errEmailAddress: " Required" }) })
			doNotValidate = true
		}
		else if (!isValidEmail(ticketInfo.EmailAddress)) {
			setEmailAddress((old) => { return ({ ...old, errEmailAddress: " Invalid Email" }) })
			doNotValidate = true
		}
		else {
			setEmailAddress((old) => { return ({ ...old, errEmailAddress: "" }) })

		}
		if (ticketInfo.Slot === "") {
			setSlot((old) => { return ({ ...old, errSlot: " Required" }) })
			doNotValidate = true
		}
		else if (!isValidTime(ticketInfo.Slot)) {
			setSlot((old) => { return ({ ...old, errSlot: " Invalid Slot Time" }) })
			doNotValidate = true
		}
		else {
			setSlot((old) => { return ({ ...old, errSlot: "" }) })

		}

		if (!isValidDigit(ticketInfo.NoofTickets) && (addTicketDetails == "")) {
			setNoofTickets((old) => { return ({ ...old, errNoofTickets: " Invalid Number" }) })
			doNotValidate = true
		}
		else {
			setNoofTickets((old) => { return ({ ...old, errNoofTickets: "" }) })
		}

		if (ticketInfo.EventDate === "" && location?.state?.ticketDetails == "") {
			setEventDate((old) => { return ({ ...old, errEventDate: " Required" }) })
			doNotValidate = true
		}
		else if (!isValidDate(ticketInfo.EventDate) && location?.state?.ticketDetails == "") {
			setEventDate((old) => { return ({ ...old, errEventDate: " Invalid EventDate " }) })
			doNotValidate = true
		}
		else {
			setEventDate((old) => { return ({ ...old, errEventDate: "" }) })

		}
		return doNotValidate
	}

	const handleConfirmationId = (val) => {
		setConfirmationNumber((old) => { return ({ ...old, errConfirmationNumber: "" }) })
		val = val
		setTicketInfo({
			...ticketInfo, ConfirmationID: val, errConfirmationNumber: ''
		})
	}

	const handleFirstName = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, FirstName: val, errFirstName: ''
		})
	}
	const handleMiddleName = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, MiddleName: val
		})
	}
	const handleLastName = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, LastName: val, errLastName: ''
		})
	}
	const handleEmailAddress = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, EmailAddress: val, errEmailAddress: ''
		})

	}
	const handleSlot = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, Slot: val, errSlot: ''
		})
	}
	const handleIsMinorYes = (val) => {
		if (val === true) {
			setTicketInfo({
				...ticketInfo, IsMinor: val
			});
		}
		else {
			setTicketInfo({
				...ticketInfo, IsMinor: !val
			});

		}
	}
	const handleIsMinorNo = (val) => {

		if (val === true) {
			setTicketInfo({
				...ticketInfo, IsMinor: !val,
				errFirstName: '', errLastName: ''
			});

			setDisable(false)
		}
		else {
			setTicketInfo({
				...ticketInfo, IsMinor: val
			});

			setDisable(true)
		}
	}
	const handleIsPaper = (val) => {
		if (val == true) {
			setNoofTickets("1");
			setTicketInfo({
				...ticketInfo, PaperWaiver: 2, NoofTickets: 1
			})
		}
		else {
			setTicketInfo({
				...ticketInfo, PaperWaiver: 0
			})
		}

	}
	const handleTicketType = (val) => {
		setTicketType(val);
	}
	const handleNoofTickets = (val) => {
		val = val;
		setNoofTickets(val);
		setTicketInfo({
			...ticketInfo, NoofTickets: val, errNoofTickets: ''
		})

	}
	const handleEventDate = (val) => {
		val = val;
		setTicketInfo({
			...ticketInfo, EventDate: val, errEventDate: ''
		})
	}
	const handleIsGroup = (val) => {
		val = val;
		setTicketInfo({
			...ticketInfo, IsGroup: val
		})
	}

	const handleOptionChange = (val) => {
		const optionval = val
		setSelectedOption(optionval);
		options.map((val) => {
			if (optionval != "") {
				if (optionval == val.TicketType) {
					setTicketType(val.TicketTypeID);
					setTicketInfo({
						...ticketInfo, TicketType: val.TicketType
					})
				}
			}
		})
	};

	const handleSubmit = () => {
		if (notValidate()) {
			return;
		}
		else {
			let finalArray = [];
			let webhooksArray = [];
			var ticketInfoArr = []
			ticketInfoArr.push(ticketInfo)
			finalArray[0] =
			{
				"ConfirmationNumber": location?.state?.ticketDetails.ConfirmationID ? location?.state?.ticketDetails.ConfirmationID : ticketInfo.ConfirmationID,
				"EmailAddress": location?.state?.ticketDetails.EmailAddress ? location?.state?.ticketDetails.EmailAddress : ticketInfo.EmailAddress,
				"TicketID": location?.state?.ticketDetails.TicketID?.null,
				"TicketType": TicketType,
				"TicketDetails": ticketInfoArr
			}
			const reqData = JSON.stringify(finalArray)
			const config = {
				method: 'POST',
				url: `${process.env.REACT_APP_WEBSITE_NAMES}/insertguestdetails?type=AddByAdmin`,
				headers: {
					'Content-Type': 'application/json',
					'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
				},

				data: reqData
			};
			axios(config)
				.then(function (response) {
					debugger;
					if (response.data == 1) {
						if (ticketInfo.PaperWaiver == 2 && ticketInfo.TicketID != null) {
							navigate('/ManageTickets', { state: ticketInfoArr })
						}
						else if (ticketInfo.IsGroup == true) {
							const config = {
								method: 'GET',
								url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?ConfirmationID=${ticketInfoArr[0].ConfirmationID ? ticketInfoArr[0].ConfirmationID.trim() : ''}&EmailAddress=${ticketInfoArr[0].EmailAddress ? ticketInfoArr[0].EmailAddress.trim() : ''}`,
								headers: {
									'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
								}
							};
							axios(config)
								.then(function (response) {
									navigate('/GroupRegistration?' + response.data)
								})
								.catch(function (error) {
									console.log(error);
								});
						}
						else {
							const config = {
								method: 'GET',
								url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?queryparams=""&ConfirmationID=${ticketInfoArr[0].ConfirmationID ? ticketInfoArr[0].ConfirmationID.trim() : ''}`,
								headers: {
									'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
								}
							};

							axios(config)
								.then(function (response) {
									debugger;
									let value = `${process.env.REACT_APP_REDIRECT_REG_ON_NEWGUEST}`
									if (response.data && value.toLowerCase() == "true" && ticketInfo.PaperWaiver != 2) {
										navigate('/OnsiteRegistration', { state: response.data })
									}
									else if (response.data && response.data.TicketDetails && response.data.TicketDetails.length > 0) {
										response.data.TicketDetails.map((guest) => {
											if (ticketInfo.PaperWaiver == 2 && guest.GuestID != "") {
												if (guest.TicketTypeID == 2 || guest.TicketTypeID == 3) {
													let value = `${process.env.REACT_APP_WEBHOOKS_ISACTIVE}`
													if (value.toLowerCase() == "true" && (guest.TicketType == "VIP" || guest.TicketType == "Group")) {
														webhooks(guest.GuestID, guest.TicketType, guest.FirstName, guest.LastName, guest.EmailAddress)
													}
												}
												navigate('/ManageTickets', { state: response.data.TicketDetails })
											}
											else {
												navigate('/ManageTickets')
											}
										})
									}
									else {
										navigate('/ManageTickets')
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						}
					}

				})
				.catch(function (error) {
					if (error.response.status && error.response.status == 409) {
						setConfirmationNumber((old) => { return ({ ...old, errConfirmationNumber: " ConfirmationID Already Exists" }) })
					}
				})
		}
	}
	console.log("Ticket details", ticketInfo)
	console.log("Ticket Details are", ticketTypearr);
	const handleKeyDown = e => {
		if (e.key === " ") {
			e.preventDefault();
		}
	};
	return (
		<>
			<AuthenticatedTemplate>
				<>
					{graphData && graphData.value.length > 0 ? (
						<>
							{valgraphData ? (
								<>
			<Header></Header>
			<div className="mainWrap mainTopMargin">
				<div className="container">
					<div className="mainHeadeing">
						<h1>Add Tour Registration</h1>
					</div>
					<>
						<p className="asterick">Note:<span className="asterick-size">' * '</span>Fields that are required must be filled out.</p>
						<Card className="card">
							<div className="card-body">
								<div className="formBlockDefault">
									{location?.state?.ticketDetails ? "" : <>
										<Row className="row">
											<Col className="col-lg-3 col-md-4 col-sm-6">
												<div className="form-mb">
													<div className="form-check form-check-inline">
														<label className="form-check-label" for="flexGroupDefault">Is Group</label>
														<input className="form-check-input" type="checkbox" id="flexGroupDefault"
															value={ticketInfo.IsGroup == true ? true : false}
															onChange={(e) => { handleIsGroup(e.target.checked) }} />
													</div>
												</div>
											</Col>
										</Row>
									</>}
									<Row className="row">
										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="form-mb">
												<label className="form-label">Waiver Type</label>
												<div className="form-check form-check-inline">
													<label className="form-check-label" for="flexCheckDefault" disabled={ticketInfo.IsGroup == true}>Paper</label>
													<input className="form-check-input" type="checkbox" onChange={(e) => { handleIsPaper(e.target.checked) }}
														checked={ticketInfo.PaperWaiver == 2 ? true : false} id="flexCheckDefault" disabled={ticketInfo.IsGroup == true} />
												</div>
											</div>
										</Col>
										<Col className="col-lg-3 col-md-6 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Confirmation ID *<span className="errMsg1" >{confirmationNumber.errConfirmationNumber}</span></label>
												<input type="text" defaultValue={ticketInfo.ConfirmationID ? ticketInfo.ConfirmationID : utcDate}
													// <input type="text" defaultValue={ticketInfo.ConfirmationID ? ticketInfo.ConfirmationID : ""}
													className="form-control" placeholder="Enter confirmation Id"
													onChange={(e) => { handleConfirmationId(e.target.value) }} />
											</div>
										</Col>

										{location?.state?.ticketDetails ? "" :
											<>
												<Col className="col-lg-3 col-md-6 col-sm-6">
													<div className="mb-3">
														<label className="form-label"> Ticket Type</label>
														<select className="form-select" value={selectedOption} onChange={(e) => { handleOptionChange(e.target.value) }} >
															<>
																{options.map((dropdownoption) => (

																	dropdownoption = 1 ?
																		<>
																			<option value={dropdownoption.TicketType}>{dropdownoption.TicketType}
																			</option>
																		</>
																		:
																		<>
																			<option key={dropdownoption.TicketTypeID}
																				value={dropdownoption.TicketType}>
																				{dropdownoption.TicketType}
																			</option>
																		</>

																))}
															</>
														</select>
														{/* <select className="form-select" value={selectedOption} onChange={handleOptionChange}>
															{options.map((option) => {
																<option key={option.TicketTypeID} value={option.TicketType}>
																	{option.TicketType}
																</option>
															}
															)}

														</select> */}
													</div>

												</Col>
												<Col className="col-lg-3 col-md-6 col-sm-6">
													<div className="mb-3">
														<label className="form-label">No Of Tickets <span className="errMsg1" >{NoofTickets.errNoofTickets}</span></label>
														<input type="text" className="form-control" placeholder="Enter no of tickets" value={ticketInfo.NoofTickets ? ticketInfo.NoofTickets : ""}
															onChange={(e) => { handleNoofTickets(e.target.value) }} disabled={ticketInfo.PaperWaiver == 2 ? true : false}
														/>
													</div>
												</Col></>}


									</Row>

									<Row className="row">
										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">First Name *<span className="errMsg1" >{firstName.errFirstName}</span></label>
												<input type="text" className="form-control" placeholder="Enter first name"
													disabled={disable} maxLength="100"
													onChange={(e) => { handleFirstName(e.target.value) }} />
											</div>
										</Col>

										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Middle Name</label>
												<input type="text" className="form-control" placeholder="Enter middle name"
													disabled={disable} maxLength="100" onChange={(e) => { handleMiddleName(e.target.value) }} />
											</div>
										</Col>

										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Last Name *<span className="errMsg1" >{lastName.errLastName}</span></label>
												<input type="text" className="form-control" placeholder="Enter last name"
													disabled={disable} maxLength="100" onChange={(e) => { handleLastName(e.target.value) }} />
											</div>
										</Col>

										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Email Address *<span className="errMsg1" >{emailAddress.errEmailAddress}</span></label>
												<input type="text" className="form-control" defaultValue={ticketInfo.EmailAddress}
													placeholder="Enter email address" maxLength="256" onKeyDown={handleKeyDown}
													onChange={(e) => { handleEmailAddress(e.target.value) }} />
											</div>
										</Col>

									</Row>


									<Row>
										{ticketInfo.PaperWaiver == 2 ?
											<>
												<Col className="col-lg-3 col-md-4 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Minor *</label>
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadioYes1"
																checked={(ticketInfo.IsMinor == true ? true : false)}
																onChange={(e) => handleIsMinorYes(e.target.checked)} />
															<label className="form-check-label" for="inlineRadioYes1">Yes</label>
														</div>
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadioNo1"
																checked={(ticketInfo.IsMinor == true ? false : true)}
																onChange={(e) => handleIsMinorNo(e.target.checked)} />
															<label className="form-check-label" for="inlineRadioNo1">No</label>
														</div>
													</div>
												</Col></> : ""}
										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Slot * (24-hour format)<span className="errMsg1" >{slot.errSlot}</span></label>
												<input
													className="form-control slot"
													type="text"
													id="timeInput"
													defaultValue={ticketInfo.Slot}
													value={ticketInfo.Slot}
													onChange={handleTimeChange}
													placeholder="HH:MM"
													maxLength="5" // Restrict input to 5 characters (e.g., "hh:mm")
													pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
												/>
												{/* <input type="time" className="form-control slot" defaultValue={ticketInfo.Slot}
													placeholder="HH:MM" maxLength="4" value={ticketInfo.Slot} pattern="[0-9]{2}:[0-9]{2}"
													onChange={(e) => { handleSlot(e.target.value) }} /> */}
											</div>
										</Col>
										{location?.state?.ticketDetails ? "" :
											<Col className="col-lg-3 col-md-4 col-sm-6">
												<div className="mb-3">
													<label className="form-label">Event Date *<span className="errMsg1" >{eventDate.errEventDate}</span></label>
													<input type="text" className="form-control slot" defaultValue={ticketInfo.EventDate}
														placeholder="mm-dd-yyyy" maxLength="10"
														onChange={(e) => { handleEventDate(e.target.value) }} />
												</div>
											</Col>}
									</Row>
								</div>
							</div>
						</Card>
					</>

					<div className="footerButtonBlock">
						<div className="pull-right">
							<a href="/ManageTickets" className="btn btn-info grayBt">Cancel</a>
							{ticketInfo.PaperWaiver == 2 ? <>
								<a className="btn btn-info" onClick={() => handleSubmit()}>Submit and Print</a>
							</> : <>
								<a className="btn btn-info" onClick={() => handleSubmit()}>Submit</a>
							</>
							}
						</div>
					</div>

				</div>
			</div>
			<Footer></Footer>
			</>
							) : (<ValidateGraphData setvalGraphData={setvalGraphData} graphData={graphData} />)}
						</>
					) : (<GetAccessToken setGraphData={setGraphData} graphData={graphData} account={accounts} instance={instance} />)}
				</>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</>
	)
}
export default ManageTicketsAdd;