import axios from "axios"
export const webhooks=(TicketID,TicketType,FirstName,LastName,EmailAddress)=>{
    let webhooksArray=
    {
        "lanyardId":TicketID,
        "active":1,
        "ticketType":TicketType,
        "firstname":FirstName,
        "lastname":LastName,
        "email":EmailAddress,
    }
    const reqData = JSON.stringify(webhooksArray)
    const config = {
        method: 'POST',
        url: `${process.env.REACT_APP_WEB_HOOKS_API_URL}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${process.env.REACT_APP_WEB_HOOKS_AUTHORIZATION_KEY}`
        },

        data: reqData
    };
    axios(config)
        .then(function (response) {
        })
}