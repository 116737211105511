import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { isValidEmail } from "../utilities";
import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";

export default function LoginPage() {
    const [ticketDetails, setTicketDetails] = useState([]);
    const [disable, setDisable] = useState(true);
    const obj = {
        "ConfirmationNumber": "",
        "EmailAddress": "",
        "emailErrMsg": ""
    }
    const location=useLocation();

    useEffect(()=>{
        window.history.pushState(null,null,location.href)
        window.onpopstate = function(event) {
            window.history.go(1);
        };
    },[])
    const DisableFun = (value1, value2) => {
        if (value1 != "" || (value2 != "" && value2 != undefined)) {
            setDisable(false)
        }
        else {
            setDisable(true)
        }
    }
    if (ticketDetails && ticketDetails.length == 0) {
        setTicketDetails(obj)
    }
    const handleConfirmationNumber = (val) => {
        val = val.trim();
        setTicketDetails({
            ...ticketDetails, ConfirmationNumber: val
        })
    }
    const handleEmailAddress = (val) => {
        val = val.trim();
        setTicketDetails({
            ...ticketDetails, EmailAddress: val
        })
    }
    const navigate = useNavigate();

    const validateForm = () => {
        let val = ""
        var isFormValid = false;
        let validEmail = false;
        let validConfirm = false;
        if (isValidEmail(ticketDetails.EmailAddress) && ticketDetails.EmailAddress.trim()) {
            validEmail = true
        }
        let val2 = ""
        if (ticketDetails.ConfirmationNumber.trim()) {
            validConfirm = true
        }
        if (validEmail || validConfirm) {
            isFormValid = true
        }
        return isFormValid;
    }
    const onSubmit = (e) => {
        e.preventDefault()

        if (validateForm()) {
            const config = {
                method: 'GET',
                url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?queryparams=""&ConfirmationID=${ticketDetails.ConfirmationNumber ? ticketDetails.ConfirmationNumber.trim() : ''}
                        &EmailAddress=${ticketDetails.EmailAddress ? ticketDetails.EmailAddress.trim() : ''}`,
                headers: {
                    'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                }
            };

            axios(config)
                .then(function (response) {
                    if (response.data)

                        navigate('/OnsiteRegistration', { state: response.data })
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    return (
        <>
            <Header />
            <div className="mainWrap mainTopMargin">
                <div className="container">
                    <div className="loginWrapp">
                        <div className="loginBlock">
                            <h1>Onsite Registration</h1>
                            <div className="form-mb">
                                <label className="form-label" >Confirmation ID</label>
                                <input type="text" className="form-control" placeholder="" value={ticketDetails.ConfirmationNumber} onChange={(e) => {
                                    handleConfirmationNumber(e.target.value)
                                    DisableFun(e.target.value, ticketDetails.EmailAddress)
                                }}
                                />
                            </div>
                            <h6><center>OR</center></h6>
                            <div className="form-mb">
                                <label className="form-label"  >Email Address
                                </label>
                                <input type="text" className="form-control" placeholder="" value={ticketDetails.EmailAddress}
                                    onChange={(e) => {
                                        handleEmailAddress(e.target.value)
                                        DisableFun(e.target.value, ticketDetails.ConfirmationNumber)
                                    }}
                                />
                            </div>
                            <div className="cardFooter centerCardFooter">
                                <Button disabled={disable} className="btn btn-info" onClick={(e) => { onSubmit(e) }}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}