import React from "react";
import logo from '../../assets/images/logo.svg';
import {useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from "../../authConfig";

export default function DashBoard() {
	const { instance } = useMsal();
	const handlesignout=()=>{
		instance.logout();
	}
	return (
		<>
			<div className="stickyWrap">
				<div className="headerFixer fixedPosition">
					<header className="pageHeader" >
						<div className="container clearfix">
							<div className="logo">
								<a href="/Admin">
									<img src={logo} className="img-fluid" alt="Sofi Tours Logo" />
								</a>
							</div>

							<nav className="">
								<div className="rightHeader">
									<a href="/Admin" className={window.location.pathname === "/Admin" ? "btn headerBt navActive" : "btn headerBt"} alt="Tours">Today's Snapshot</a>
									<a href="/ManageTickets" className={window.location.pathname === "/ManageTickets" ? "btn headerBt navActive" : "btn headerBt"} alt="Tours">Manage Tickets</a>
									<a href="/MyTour" className={window.location.pathname === "/MyTour" ? "btn headerBt navActive" : "btn headerBt"} alt="Tours">My Tours</a>
									<button className="btn headerBt" onClick={(e)=>{handlesignout()}}><i class="fa fa-sign-out" aria-hidden="true">&nbsp;Sign Out</i></button>
								</div>
							</nav>
						</div>
					</header>
				</div>
			</div>

		</>
	)
}