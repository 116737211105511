import { useEffect } from 'react';
import {useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

export default function Login(){
    const { instance } = useMsal();
    const isAuthenticated=useIsAuthenticated();

    useEffect(() => {
        if(!isAuthenticated){
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }
    }, [isAuthenticated]);

}