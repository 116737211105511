import React from 'react'

export default function GetUTCTimeZone(currentdate) {
    const inputDate = new Date(currentdate);
    

    // Use Intl.DateTimeFormat to format the date and time in the user's time zone
    const formattedDateTime = new Intl.DateTimeFormat('en-US', {
        timeZone: 'UTC',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }).format(inputDate);
   
    return formattedDateTime;
}
