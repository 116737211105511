import React from "react";

export default function Footer() {
    return (
      <>
     
          <div className="footer footerWrapp">
				<p>Copyright © 2022 SoFi Stadium Tours. All Rights Reserved.</p>
			</div>
      </>
    )
  }