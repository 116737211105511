export async function callMsGraph(accessToken,userid) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("MODE", "CORS")
    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(`${process.env.REACT_APP_AZURE_AD_GRAPH_URL}/${userid}/memberOf`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
