import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DashBoard from "../DashBoard/DashBoard";
import { useEffect } from "react";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import ValidateGraphData from "../../AzureLogin/ValidateGraphAPI";
import GetAccessToken from "../../AzureLogin/GetAccessToken";
import Login from "../../AzureLogin/Login";

export default function Admin() {
	let newDate = new Date();
	let todayDate = moment(newDate).format("MM-DD-YYYY")
	newDate.setDate(newDate.getDate() + 1);
	let nxtDate = moment(newDate).format("MM-DD-YYYY")
	newDate.setDate(newDate.getDate() + 7);
	let nxtWeek = moment(newDate).format("MM-DD-YYYY")
	const [Today, setTodays] = useState({ 'groupTodaysCompleted': 0, 'individualTodaysCompleted': 0, 'groupTodaysPending': 0, 'individualTodayPending': 0, 'fromDate': todayDate, 'toDate': todayDate })
	const [Tomorrow, setTommorow] = useState({ 'groupTomorrowCompleted': 0, 'individualTomorrowCompleted': 0, 'groupTomorrowPending': 0, 'individualTomorrowPending': 0, 'fromDate': nxtDate, 'toDate': nxtDate })
	const [nextWeek, setNextWeek] = useState({ 'groupNextWeekCompleted': 0, 'individualNextWeekCompleted': 0, 'groupNextWeekPending': 0, 'individualNextWeekPending': 0, 'fromDate': nxtDate, 'toDate': nxtWeek })
	const { instance, accounts } = useMsal();
	const [graphData, setGraphData] = useState(null);
	const [valgraphData, setvalGraphData] = useState(false)

	useEffect(() => {
	}, [Today])
	useEffect(() => {

		const config =
		{
			method: 'get',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/filtertoursticketcount`,
			headers: {
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
			}
		};

		axios(config)
			.then(function (response) {
				let groupTodaysCompleted = response.data.filter((item) => {
					return (item.Label == "Today's Snapshot" && item.WaiverStatus == "Completed" && item.ISGROUP == "Group")
				})
				let groupTodaysPending = response.data.filter((item) => {
					return (item.Label == "Today's Snapshot" && item.WaiverStatus == "Pending" && item.ISGROUP == "Group")
				})
				let individualTodaysCompleted = response.data.filter((item) => {
					return (item.Label == "Today's Snapshot" && item.WaiverStatus == "Completed" && item.ISGROUP == "Individual")
				})
				let individualTodayPending = response.data.filter((item) => {
					return (item.Label == "Today's Snapshot" && item.WaiverStatus == "Pending" && item.ISGROUP == "Individual")
				})

				setTodays(
					{
						'groupTodaysCompleted': groupTodaysCompleted[0] ? groupTodaysCompleted[0].COUNT : 0,
						'individualTodaysCompleted': individualTodaysCompleted[0] ? individualTodaysCompleted[0].COUNT : 0,
						'groupTodaysPending': groupTodaysPending[0] ? groupTodaysPending[0].COUNT : 0,
						'individualTodayPending': individualTodayPending[0] ? individualTodayPending[0].COUNT : 0,
						'fromDate': todayDate,
						'toDate': todayDate

					})
				let groupTomorrowCompleted = response.data.filter((item) => {
					return (item.Label == "Tomorrow's Snapshot" && item.WaiverStatus == "Completed" && item.ISGROUP == "Group")
				})
				let groupTomorrowPending = response.data.filter((item) => {
					return (item.Label == "Tomorrow's Snapshot" && item.WaiverStatus == "Pending" && item.ISGROUP == "Group")
				})
				let individualTomorrowCompleted = response.data.filter((item) => {
					return (item.Label == "Tomorrow's Snapshot" && item.WaiverStatus == "Completed" && item.ISGROUP == "Individual")
				})
				let individualTomorrowPending = response.data.filter((item) => {
					return (item.Label == "Tomorrow's Snapshot" && item.WaiverStatus == "Pending" && item.ISGROUP == "Individual")
				})

				setTommorow(
					{
						'groupTomorrowCompleted': groupTomorrowCompleted[0] ? groupTomorrowCompleted[0].COUNT : 0,
						'individualTomorrowCompleted': individualTomorrowCompleted[0] ? individualTomorrowCompleted[0].COUNT : 0,
						'groupTomorrowPending': groupTomorrowPending[0] ? groupTomorrowPending[0].COUNT : 0,
						'individualTomorrowPending': individualTomorrowPending[0] ? individualTomorrowPending[0].COUNT : 0,
						'fromDate': nxtDate,
						'toDate': nxtDate
					})


				let groupNextWeekCompleted = response.data.filter((item) => {
					return (item.Label == "Next Week Tours's Snapshot" && item.WaiverStatus == "Completed" && item.ISGROUP == "Group")
				})
				let groupNextWeekPending = response.data.filter((item) => {
					return (item.Label == "Next Week Tours's Snapshot" && item.WaiverStatus == "Pending" && item.ISGROUP == "Group")
				})
				let individualNextWeekCompleted = response.data.filter((item) => {
					return (item.Label == "Next Week Tours's Snapshot" && item.WaiverStatus == "Completed" && item.ISGROUP == "Individual")
				})
				let individualNextWeekPending = response.data.filter((item) => {
					return (item.Label == "Next Week Tours's Snapshot" && item.WaiverStatus == "Pending" && item.ISGROUP == "Individual")
				})
				setNextWeek(
					{
						'groupNextWeekCompleted': groupNextWeekCompleted[0] ? groupNextWeekCompleted[0].COUNT : 0,
						'individualNextWeekCompleted': individualNextWeekCompleted[0] ? individualNextWeekCompleted[0].COUNT : 0,
						'groupNextWeekPending': groupNextWeekPending[0] ? groupNextWeekPending[0].COUNT : 0,
						'individualNextWeekPending': individualNextWeekPending[0] ? individualNextWeekPending[0].COUNT : 0,
						'fromDate': nxtDate,
						'toDate': nxtWeek
					})
			})

			.catch(function (error) {
				console.log(error);
			});

	}, [])
	return (

		<React.Fragment>
			<AuthenticatedTemplate>
				<>
					{(graphData && graphData.value.length > 0) ? (
						<>
							{valgraphData ? (
								<>
									<DashBoard></DashBoard>

									<div className="mainWrap mainTopMargin">
										<div className="container">

											<div className="adminDashboardBlock">
												<div className="mainHeadeing">
													<h1>Today's Snapshot</h1>
												</div>

												<Row className="row">
													<Col className="col-md-4">
														<div state={{ title: "Today's Total Registrations" }} className="dashcard">
															<div className="roundedIcon blueColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink
																to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=all&status=-1` }}
																condition={(parseInt(Today.groupTodaysCompleted) + parseInt(Today.groupTodaysPending) + parseInt(Today.individualTodaysCompleted) + parseInt(Today.individualTodayPending)) > 0}>
																<div className="dashTotalReg">
																	<h3>{parseInt(Today.groupTodaysCompleted) + parseInt(Today.groupTodaysPending) + parseInt(Today.individualTodaysCompleted) + parseInt(Today.individualTodayPending)}</h3>
																	<p>Total Registrations</p>
																</div>
															</ConditionalLink>
															<div className="dashGroupIndv">
																<ConditionalLink
																	to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=group&status=-1` }}
																	condition={(parseInt(Today.groupTodaysCompleted) + parseInt(Today.groupTodaysPending)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i> {parseInt(Today.groupTodaysCompleted) + parseInt(Today.groupTodaysPending)}</h4>
																</ConditionalLink>
																<hr />
																<ConditionalLink
																	to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=individual&status=-1` }}
																	condition={(parseInt(Today.individualTodaysCompleted) + parseInt(Today.individualTodayPending)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i>{parseInt(Today.individualTodaysCompleted) + parseInt(Today.individualTodayPending)}</h4>
																</ConditionalLink>
															</div>
														</div>
													</Col>

													<Col className="col-md-4">
														<div state={{ title: "Today's Completed Registrations" }} className="dashcard">
															<div className="roundedIcon greenColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink
																to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=all&status=1` }}
																condition={(parseInt(Today.groupTodaysCompleted) + parseInt(Today.individualTodaysCompleted)) > 0}>
																<div className="dashTotalReg">
																	<h3>{parseInt(Today.groupTodaysCompleted) + parseInt(Today.individualTodaysCompleted)}</h3>
																	<p>Completed Registrations</p>
																</div></ConditionalLink>
															<div className="dashGroupIndv">
																<ConditionalLink
																	to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=group&status=1` }}
																	condition={(parseInt(Today.groupTodaysCompleted)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i>{Today.groupTodaysCompleted}</h4>
																</ConditionalLink>
																<hr />
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=individual&status=1` }}
																	condition={(parseInt(Today.individualTodaysCompleted)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i>{Today.individualTodaysCompleted}</h4>
																</ConditionalLink>
															</div>
														</div>
													</Col>

													<Col className="col-md-4">
														<div state={{ title: "Today's Pending Registrations" }} className="dashcard">
															<div className="roundedIcon orangeColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=all&status=0` }}
																condition={(parseInt(Today.groupTodaysPending) + parseInt(Today.individualTodayPending)) > 0}>
																<div className="dashTotalReg">
																	<h3>{parseInt(Today.groupTodaysPending) + parseInt(Today.individualTodayPending)}</h3>
																	<p>Pending Registrations</p>
																</div></ConditionalLink>
															<div className="dashGroupIndv">
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=group&status=0` }}
																	condition={(parseInt(Today.groupTodaysPending)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i>{Today.groupTodaysPending}</h4>
																	<hr /></ConditionalLink>
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Today.fromDate}&toDate=${Today.toDate}&type=individual&status=0` }}
																	condition={(parseInt(Today.groupTodaysPending)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i> {Today.individualTodayPending}</h4>
																</ConditionalLink>
															</div>
														</div>
													</Col>
												</Row>
											</div>

											<div className="adminDashboardBlock">
												<div className="mainHeadeing">
													<h1>Tomorrow's Snapshot</h1>
												</div>

												<Row className="row">
													<Col className="col-md-4">
														<div state={{ title: "Tomorrow's Total Registrations" }} className="dashcard">
															<div className="roundedIcon blueColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=all&status=-1` }}
																condition={(parseInt(Tomorrow.groupTomorrowCompleted) + parseInt(Tomorrow.groupTomorrowPending) + parseInt(Tomorrow.individualTomorrowCompleted) + parseInt(Tomorrow.individualTomorrowPending)) > 0}>
																<div className="dashTotalReg">
																	<h3>{parseInt(Tomorrow.groupTomorrowCompleted) + parseInt(Tomorrow.groupTomorrowPending) + parseInt(Tomorrow.individualTomorrowCompleted) + parseInt(Tomorrow.individualTomorrowPending)}</h3>
																	<p>Total Registrations</p>
																</div>
															</ConditionalLink>
															<div className="dashGroupIndv">
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=group&status=-1` }}
																	condition={(parseInt(Tomorrow.groupTomorrowCompleted) + parseInt(Tomorrow.groupTomorrowPending)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i>{(parseInt(Tomorrow.groupTomorrowCompleted) + parseInt(Tomorrow.groupTomorrowPending))}</h4>
																	<hr /></ConditionalLink>
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=individual&status=-1` }}
																	condition={(parseInt(Tomorrow.individualTomorrowCompleted) + parseInt(Tomorrow.individualTomorrowPending)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i> {(parseInt(Tomorrow.individualTomorrowCompleted) + parseInt(Tomorrow.individualTomorrowPending))}</h4>
																</ConditionalLink>
															</div>
														</div>
													</Col>

													<div className="col-md-4">
														<div state={{ title: "Tomorrow's Completed Registrations" }} className="dashcard">
															<div className="roundedIcon greenColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=all&status=1` }}
																condition={(parseInt(Tomorrow.groupTomorrowCompleted) + parseInt(Tomorrow.individualTomorrowCompleted)) > 0}>
																<div className="dashTotalReg">
																	<h3>{parseInt(Tomorrow.groupTomorrowCompleted) + parseInt(Tomorrow.individualTomorrowCompleted)}</h3>
																	<p>Completed Registrations</p>
																</div>
															</ConditionalLink>
															<div className="dashGroupIndv">
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=group&status=1` }}
																	condition={(parseInt(Tomorrow.groupTomorrowCompleted)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i>{Tomorrow.groupTomorrowCompleted}</h4></ConditionalLink>
																<hr />
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=individual&status=1` }}
																	condition={(parseInt(Tomorrow.individualTomorrowCompleted)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i> {Tomorrow.individualTomorrowCompleted}</h4>
																</ConditionalLink>
															</div>
														</div>
													</div>

													<Col className="col-md-4">
														<div state={{ title: "Tomorrow's Pending Registrations" }} className="dashcard">
															<div className="roundedIcon orangeColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=all&status=0` }}
																condition={(parseInt(Tomorrow.groupTomorrowPending) + parseInt(Tomorrow.individualTomorrowPending)) > 0}></ConditionalLink>
															<a href={`/RegisteredDetails?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=all&status=0`}>
																<div className="dashTotalReg">
																	<h3>{parseInt(Tomorrow.groupTomorrowPending) + parseInt(Tomorrow.individualTomorrowPending)}</h3>
																	<p>Pending Registrations</p>
																</div></a>
															<div className="dashGroupIndv">
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=group&status=0` }}
																	condition={(parseInt(Tomorrow.groupTomorrowPending)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i>{Tomorrow.groupTomorrowPending}</h4>
																</ConditionalLink>
																<hr />
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${Tomorrow.fromDate}&toDate=${Tomorrow.toDate}&type=individual&status=0` }}
																	condition={(parseInt(Tomorrow.individualTomorrowPending)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i> {Tomorrow.individualTomorrowPending}</h4>
																</ConditionalLink>
															</div>
														</div>
													</Col>
												</Row>
											</div>

											<div className="adminDashboardBlock">
												<div className="mainHeadeing">
													<h1>Next Week Tours</h1>
												</div>

												<Row className="row">
													<Col className="col-md-4">
														<div state={{ title: "Next Week's Total Registrations" }} className="dashcard">
															<div className="roundedIcon blueColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=all&status=-1` }}
																condition={(parseInt(nextWeek.groupNextWeekCompleted) + parseInt(nextWeek.groupNextWeekPending) + parseInt(nextWeek.individualNextWeekCompleted) + parseInt(nextWeek.individualNextWeekPending)) > 0}>
																<div className="dashTotalReg">
																	<h3>{parseInt(nextWeek.groupNextWeekCompleted) + parseInt(nextWeek.groupNextWeekPending) + parseInt(nextWeek.individualNextWeekCompleted) + parseInt(nextWeek.individualNextWeekPending)}</h3>
																	<p>Total Registrations</p>
																</div></ConditionalLink>
															<div className="dashGroupIndv">
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=group&status=-1` }}
																	condition={(parseInt(nextWeek.groupNextWeekCompleted) + parseInt(nextWeek.groupNextWeekPending)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i>{parseInt(nextWeek.groupNextWeekCompleted) + parseInt(nextWeek.groupNextWeekPending)}</h4>
																</ConditionalLink>
																<hr />
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=individual&status=-1` }}
																	condition={(parseInt(nextWeek.individualNextWeekCompleted) + parseInt(nextWeek.individualNextWeekPending)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i>{parseInt(nextWeek.individualNextWeekCompleted) + parseInt(nextWeek.individualNextWeekPending)}</h4>
																</ConditionalLink>
															</div>
														</div>
													</Col>

													<Col className="col-md-4">
														<div state={{ title: "Next Week's Completed Registrations" }} className="dashcard">
															<div className="roundedIcon greenColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=all&status=1` }}
																condition={(parseInt(nextWeek.groupNextWeekCompleted) + parseInt(nextWeek.individualNextWeekCompleted)) > 0}>
																<div className="dashTotalReg">
																	<h3>{parseInt(nextWeek.groupNextWeekCompleted) + parseInt(nextWeek.individualNextWeekCompleted)}</h3>
																	<p>Completed Registrations</p>
																</div>
															</ConditionalLink>
															<div className="dashGroupIndv">
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=group&status=1` }}
																	condition={(parseInt(nextWeek.groupNextWeekCompleted)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i>{nextWeek.groupNextWeekCompleted}</h4>
																</ConditionalLink>
																<hr />
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=individual&status=1` }}
																	condition={(parseInt(nextWeek.individualNextWeekCompleted)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i>{nextWeek.individualNextWeekCompleted}</h4></ConditionalLink>
															</div>
														</div>
													</Col>

													<Col className="col-md-4">
														<div state={{ title: "Next Week's Pending Registrations" }} className="dashcard">
															<div className="roundedIcon orangeColor"><i className="fa fa-user" aria-hidden="true"></i></div>
															<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=all&status=0` }}
																condition={(parseInt(nextWeek.groupNextWeekPending) + parseInt(nextWeek.individualNextWeekPending)) > 0}>
																<div className="dashTotalReg">
																	<h3>{parseInt(nextWeek.groupNextWeekPending) + parseInt(nextWeek.individualNextWeekPending)}</h3>
																	<p>Pending Registrations</p>
																</div>
															</ConditionalLink>
															<div className="dashGroupIndv">
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=group&status=0` }}
																	condition={(parseInt(nextWeek.groupNextWeekPending)) > 0}>
																	<h4><i className="fa fa-users" aria-hidden="true"></i>{nextWeek.groupNextWeekPending}</h4>
																</ConditionalLink>
																<hr />
																<ConditionalLink to={{ pathname: "/RegisteredDetails", search: `?fromDate=${nextWeek.fromDate}&toDate=${nextWeek.toDate}&type=individual&status=0` }}
																	condition={(parseInt(nextWeek.individualNextWeekPending)) > 0}>
																	<h4><i className="fa fa-user" aria-hidden="true"></i>{nextWeek.individualNextWeekPending}</h4></ConditionalLink>
															</div>
														</div>
													</Col>
												</Row>
											</div>
										</div>
									</div>
									<Footer></Footer>
								</>
							) : (<ValidateGraphData setvalGraphData={setvalGraphData} valgraphData={valgraphData} graphData={graphData} />)}
						</>
					) : (<GetAccessToken setGraphData={setGraphData} graphData={graphData} account={accounts} instance={instance} />)}
				</>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</React.Fragment>

	)
}
function ConditionalLink({ children, condition, ...props }) {
	return !!condition && props.to ? <Link {...props}>{children}</Link> : <>{children}</>
}