import React, { useState } from "react";
import { read, utils } from 'xlsx';
import { CSVLink } from "react-csv";

export default function GroupRegistrationTop(props) {
    const [tourrgt, settourregt] = useState([]);
    const [Invalidfile, setInvalidfile] = useState("");
    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files && files.length && files[0].type == "text/csv") {
            setInvalidfile("");
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    props.parenttochild(rows)
                }
                else {
                    props.parenttochild([])
                }

            }
            reader.readAsArrayBuffer(file);
        }
        else {
            setInvalidfile("Invalid file")
            props.parenttochild([])

        }
        $event.target.value = null;
    }
    const headers = [
        { label: "FirstName", key: "firstName" },
        { label: "MiddleName", key: "middleName" },
        { label: "LastName", key: "lastName" },
        { label: "EmailAddress", key: "emailAddress" },
        { label: "IsMinor", key: "isMinor" },
        { label: "PaperWaiver", key: "PaperWaiver" },
        { label: "Slot", key: "Slot" }
    ];
    const data = props.ticketInfo

    const csvReport = {
        data: [],
        headers: headers,
        filename: 'SoFiToursTicketDetails.csv',
    };

    return (
        data.length > 0 ?
            <>
                <div className="mainHeadeing">
                    <h1>Tour Registration</h1>
                </div>

                <div className="subRow">
                    <div className="leftsubRow"><span className="errMsg1" >{props.groupName.errGroupName}</span>
                        <div className="formInlineLabel" >
                            <label>Group Name * :</label>
                            <input ref={(ref) => (props.groupRef.current[0] = ref)} type="text" className="form-control" placeholder="Enter group name" maxLength="50"
                                onChange={(e) => {
                                    props.setGroupName((old) => {
                                        return ({ ...old, groupName: e.target.value.trim() })
                                    })
                                }} />

                        </div>
                        <p className="asterick">Note:<span className="asterick-size">' * '</span>Fields that are required must be filled out.</p>
                    </div>
                    <div className="rightsubRow custom_width-200">
                        <div className="upload-btn-wrapper me-3" >

                            <button className="btn btn-info uploadBt">Upload CSV</button>
                            <input type="file" name="myfile" required onChange={handleImport} accept=".csv" />
                        </div>
                        <div className="upload-btn-wrapper me-auto">
                            <button className="btn btn-info uploadBt"><CSVLink className="download-csv" {...csvReport}> Download Template</CSVLink></button>

                        </div>
                        <span className="errMsg">{(Invalidfile && Invalidfile != "") ? Invalidfile : (props.errormessage && props.errormessage != null ? props.errormessage : "")}</span>
                    </div>
                </div>

                <div className="alertMessage">

                    <p>{props.blurbMessage}</p>
                </div>
            </> :
            <>
                <center><p dangerouslySetInnerHTML={{ __html: props.formMsg }} /></center>
            </>
    )
}