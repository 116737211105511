import { loginRequest } from '../authConfig';
import { callMsGraph } from './GraphQL';

export default function GetAccessToken(props) {
        props.instance
			.acquireTokenSilent({
				...loginRequest,
				account: props.account[0],
			})
			.then((response) => {
				callMsGraph(response.accessToken, props.account[0].localAccountId).then((response) => props.setGraphData(response));
			});
	}