import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import DashBoard from "../DashBoard/DashBoard";
import Footer from "../Footer/Footer";
import axios from "axios";
import moment from "moment";
import img from "../../assets/images/id-card.jpg"
import { Modal } from "react-bootstrap";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Login from "../../AzureLogin/Login";
import QRCode from "react-qr-code";
import ValidateGraphData from "../../AzureLogin/ValidateGraphAPI";
import GetAccessToken from "../../AzureLogin/GetAccessToken";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router";
import { webhooks } from "../WebHooksApi/webhooks";
// import jsPDF from "jspdf";
import view from "../../assets/images/book.png";
import { Link } from "react-router-dom";
export default function ManageTickets() {
	const navigate = useNavigate();
	const location = useLocation();
	const [mapData, setMapData] = useState([])
	const [EmailAddress, setEmailAddress] = useState("");
	const [WaiverStatus, setWaiverStatus] = useState("-1");
	const [isPrintClicked, setIsPrintClicked] = useState(false);
	const [isAllChecked, setIsAllChecked] = useState(0);
	const [printDetails, setPrintDetails] = useState([]);
	const [paginationArr, setPaginationArr] = useState([]);
	const [selectedPageNo, setSelectedPageNo] = useState(1);
	const [pageNoEnd, setPageNoEnd] = useState(3)
	const [pageNoStart, setPageNoStart] = useState(0)
	const [totalGuest, setTotalGuest] = useState(0)
	const [isDeleteClicked, setIsDeleteClicked] = useState(false);
	const [singleTicketData, setSingleTicketData] = useState({});
	const [deleteErrMsg, setDeleteErrMsg] = useState("");
	const { instance, accounts } = useMsal();
	const [graphData, setGraphData] = useState(null);
	const [valgraphData, setvalGraphData] = useState(false)
	const [valGuestData, setGuestData] = useState([]);
	const [dateFilter, setDateFilter] = useState(new Date());
	const [disabled, setDisable] = useState(false);
	const [printDisable, setPrintDisabled] = useState(true);
	const [deleteDisable, setDeleteDisabled] = useState(true);
	const [checkedCountDelete, setCheckedCountDelete] = useState();
	const [typestatus, setTypeStatus] = useState(2);
	const [placeholderText, setPlaceholderText] = useState();
	const [count, setCount] = useState(0);
	const [selectedpageNo, setselectedpageNo] = useState('');
	const [pageSelection, setpageSelection] = useState(10);
	const [viewresp, setViewResp] = useState('');
	const printRef = useRef();
	let newDate = new Date();
	let date = moment(newDate).format("MM-DD-YYYY")
	let FilterDate = dateFilter ? moment(dateFilter).format("MM-DD-YYYY").trim() : "";
	const pagesizeno = [10, 20, 50, 100, 200]
	var resp = "";
	const CustomInput = React.forwardRef((props, ref) => {
		return (
			<div className="date-picker-css" >
				<label onClick={props.onClick} ref={ref}>
					{props.value || props.placeholder}
				</label>
				<FontAwesomeIcon icon={faCalendarAlt} onClick={props.onClick} />
			</div>
		);
	});
	useEffect(() => {
		if (isPrintClicked == false && window.history.state && window.history.state.usr && window.history.state.usr.length > 0 && count == 1) {
			window.history.replaceState({}, location.state);
		}
	}, [isPrintClicked, location.state])

	useEffect(() => {
		let arr = [];
		if (window.history.state && window.history.state.usr && window.history.state.usr.length > 0 && count == 0) {
			if (location.state[0].TicketID && (location.state[0].TicketID != null && location.state[0].TicketID != "")) {
				setCount(1)
				arr.push(location.state[0])
				setPrintDetails(arr)
				setIsPrintClicked(true)
			}
		}
	}, [])
	useMemo(() => {

		const config = {
			method: 'GET',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/getguestdetailsbywaiverstatus`,
			headers: {
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`,
			},
			params: {
				"ConfirmationID": EmailAddress,
				"WaiverStatus": WaiverStatus,
				"PageNumber": selectedPageNo,
				"EventDate": FilterDate,
				"SearchType": typestatus,
				"pagesize": pageSelection
			}
		};
		axios(config)
			.then(function (response) {
				var newArr = [];
				var itemNo = 1;
				var itemArr = [];
				if (response.data.Guests != null) {
					response.data.Guests.map(data => {
						newArr.push({ ...data, isChecked: false })
					})
					setMapData(newArr);
				}
				else {
					setMapData(null);
					setTotalGuest(0)
					setPaginationArr([])
				}
				itemNo = Math.ceil(response.data.Pagination.TotalRecords / response.data.Pagination.PageSize)
				for (let i = 1; i <= itemNo; i++) {
					itemArr.push(i)
				}
				setPaginationArr(itemArr);
				setTotalGuest(response.data.Pagination.TotalRecords)
			})
	}, [selectedPageNo])

	const getGuestDetail = (val) => {

		const config = {
			method: 'GET',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/getguestdetailsbywaiverstatus`,
			headers: {
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`,
			},
			params: {
				"ConfirmationID": val,
				"WaiverStatus": WaiverStatus,
				"PageNumber": selectedPageNo,
				"EventDate": FilterDate,
				"SearchType": 1,
				"pagesize": pageSelection
			}
		};
		axios(config)
			.then(function (response) {
				if (response.data.Guests != null && response.data.Guests[0].ValidateTicket) {
					setGuestData(response.data.Guests)
					setDisable(false)
				}
				else {
					setGuestData(null);
					setDisable(true);
				}

			})
	}
	useEffect(() => {
		if (isPrintClicked == true && (window.history.state == null || (window.history.state && window.history.state.usr == undefined))) {

			var filterData = mapData.filter((data, index) => {
				return data.isChecked
			})
			setPrintDetails(filterData)
			let finalArray = [];
			var ticketInfoArr = []
			finalArray[0] =
			{
				"TicketDetails": filterData,

			}
			const reqData = JSON.stringify(finalArray)
			UpdateGuestDetails(reqData)
		}
	}, [isPrintClicked])

	const UpdateGuestDetails = (val) => {
		const config = {
			method: 'PUT',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/updateguestdetails?type=Update`,
			headers: {
				'Content-Type': 'application/json',
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
			},
			data: val
		};
		axios(config)
			.then(function (response) {
				if (response.data == 1) {
					navigate('/ManageTickets')
				}

			})
			.catch(function (error) {
				console.log(error)
			})
	}

	const onchangeDataFilter = (val) => {
		val = val.trim()
		setTypeStatus(val)
	}
	const onchangeEmailAddress = (val) => {
		val = val.trim()
		setEmailAddress(val)
	}
	const onchangeWaiverStatus = (val) => {
		val = val.trim()
		setWaiverStatus(val)
	}
	const onchangeDateFilter = (val) => {
		val = val;
		setDateFilter(val);
	}

	const printContent = React.useCallback(() => {
		return printRef.current;
	}, [printRef.current])


	const handleSubmit = () => {
		setIsAllChecked(0)
		const config = {
			method: 'GET',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/getguestdetailsbywaiverstatus`,
			headers: {
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
			},
			params: {
				"ConfirmationID": EmailAddress,
				"WaiverStatus": WaiverStatus,
				"PageNumber": 1,
				"EventDate": FilterDate,
				"SearchType": typestatus,
				"pagesize": pageSelection

			}
		};
		axios(config)
			.then(function (response) {
				if (response.data.Guests != null) {
					setMapData(response.data.Guests)
					setTotalGuest(response.data.Pagination.TotalRecords)
					var itemNo = 1;
					var itemArr = [];
					itemNo = Math.ceil(response.data.Pagination.TotalRecords / response.data.Pagination.PageSize)
					for (let i = 1; i <= itemNo; i++) {
						itemArr.push(i)
					}
					setSelectedPageNo(1);
					setPageNoStart(0);
					setPageNoEnd(3)
					setPaginationArr(itemArr);
				}
				else {
					setMapData(null);
					setPaginationArr([]);
					setTotalGuest(response.data.Pagination.TotalRecords)
				}
			})
	}

	const checkedPrintData = (item, index, value) => {
		setSingleTicketData(item)
		mapData.splice(index, 1, { ...item, isChecked: value })
		setMapData(mapData)

		var filterData = mapData.filter((data, index) => {
			return data.isChecked
		})
		setPrintDetails(filterData)
	}

	const validateGuest = (item, index, value) => {

		if (value == true) {
			setSingleTicketData(item)
			mapData.splice(index, 1, { ...item, isChecked: value })
			setMapData(mapData)
			var filterData = mapData.filter((data, index) => {
				return data.isChecked
			})
			if (filterData.length > 0) {
				getGuestDetail(filterData[0].ConfirmationID)
			}
		}
		else {
			setDisable(false)
		}
	}

	const validatePrint = (item, index, value) => {
		mapData.splice(index, 1, { ...item, isChecked: value })
		setMapData(mapData)
		var filterData = mapData.filter((data, index) => {
			return data.isChecked
		})
		setPrintDisabled(true);
		for (let i = 0; i < filterData.length; i++) {

			if (filterData.length > 0 && filterData[i].isChecked && filterData[i].PaperWaiver > 0) {
				setPrintDisabled(false);
			}
			else if (!filterData[i].isChecked) {
				setPrintDisabled(true);
			}
		}
	}


	const validateDelete = (item, index, value) => {
		mapData.splice(index, 1, { ...item, isChecked: value })
		setMapData(mapData)
		var filterData = mapData.filter((data, index) => {
			return data.isChecked
		})
		if (filterData.length > 0) {
			setDeleteDisabled(false);
		}
		else {
			setDeleteDisabled(true);
		}
	}
	const deleteTicket = () => {
		var guestArr = [];
		mapData.map(item => {
			if (item.isChecked) guestArr.push({ GuestID: item.GuestID });
		})
		const reqData = JSON.stringify(guestArr);

		const config = {
			method: 'POST',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/deleteguestdetails`,
			headers: {
				'Content-Type': 'application/json',
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
			},
			data: reqData
		};
		axios(config)
			.then(function (response) {
				if (response.status == 200) {
					navigate('/ManageTickets')
					setIsDeleteClicked(false)
					setDeleteErrMsg("")
					window.location.reload(false)

				} else if (response.status === 204) {
					setDeleteErrMsg("No content found!!")
				}

			})
			.catch(function (error) {
				console.log(error)
			})
	}
	const onChangeGroupName = (item, index, val) => {

		let arr = [...mapData]
		arr[index] = { ...arr[index], GroupName: val }
		setMapData(arr)
		setGuestData(arr)
	}
	const onChangeFirstName = (item, index, val) => {

		let arr = [...mapData]
		arr[index] = { ...arr[index], FirstName: val }
		setMapData(arr)
		setGuestData(arr)
	}
	const onChangeMiddleName = (item, index, val) => {

		let arr = [...mapData]
		arr[index] = { ...arr[index], MiddleName: val }
		setMapData(arr)
		setGuestData(arr)

	}
	const onChangeLastName = (item, index, val) => {

		let arr = [...mapData]
		arr[index] = { ...arr[index], LastName: val }
		setMapData(arr)
		setGuestData(arr)

	}

	const onChangeSlot = (item, index, val) => {

		let arr = [...mapData]
		arr[index] = { ...arr[index], Slot: val }
		setMapData(arr)
		setGuestData(arr)

	}

	useEffect(() => {
		if ((valGuestData && valGuestData.length > 0) && !(valGuestData[0].ValidateTicket)) {
			setGuestData([])
		}
	})

	const sortdata = (data, sortBy, direction) => {

		let arr = [];
		let count = 0;
		arr = data
		if (arr.length > 0) {
			if (sortBy == 'GroupName') {
				arr = arr.sort((a, b) => {
					if (a[sortBy] && !b[sortBy]) { return 1; }
					if (b[sortBy] && !a[sortBy]) { return -1; }
					let x = a[sortBy].toLowerCase();
					let y = b[sortBy].toLowerCase();
					if (x < y) { return direction === "ascending" ? -1 : 1 }
					if (x > y) { return direction === "ascending" ? 1 : -1 }
					return 0;
				});
			}
			if (sortBy == 'EventDate') {
				arr = arr.sort((a, b) => {
					if (a[sortBy] && !b[sortBy]) { return 1; }
					if (b[sortBy] && !a[sortBy]) { return -1; }
					const date1 = new Date(a[sortBy])
					const date2 = new Date(b[sortBy])
					if (direction === "ascending") {
						return date1 - date2;
					}
					else if (direction === "descending") {
						return date2 - date1
					}
					return 0;
				});
			}
			if (sortBy == 'Slot') {
				arr = arr.sort((a, b) => {
					var slot_a = a[sortBy] ? a[sortBy] : "00:00"
					var slot_b = b[sortBy] ? b[sortBy] : "00:00"

					if (slot_a && !slot_b) { return 1; }
					if (slot_b && !slot_a) { return -1; }

					var x = moment(slot_a, "HH:mm");
					var y = moment(slot_b, "HH:mm");

					if (x < y) { return direction === "ascending" ? -1 : 1 }
					if (x > y) { return direction === "ascending" ? 1 : -1 }
					return 0;

				});
			}
			for (let i = arr.length; i < data.length; i++) {
				arr.push(arr);
			}
			setGuestData(arr);
		}
	}

	useEffect(() => {
		if (mapData && mapData.length > 0 && mapData[0].isChecked == false) {
			setIsAllChecked(0)
		}
	})

	useEffect(() => {
		if (typestatus == 1) {
			setPlaceholderText("Enter Confirmation ID")
		}
		else if (typestatus == 2) {
			setPlaceholderText("Enter Email Address")
		}
		else if (typestatus == 3) {
			setPlaceholderText("Enter Group Name")
		}
		else if (typestatus == 4) {
			setPlaceholderText("Enter Last Name")
		}
	})

	const handlepagesizechange = (event) => {
		debugger;
		const optionval = event.target.value;
		setpageSelection(optionval);
		const config = {
			method: 'GET',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/getguestdetailsbywaiverstatus`,
			headers: {
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`,
			},
			params: {
				"ConfirmationID": EmailAddress,
				"WaiverStatus": WaiverStatus,
				"PageNumber": selectedPageNo,
				"EventDate": FilterDate,
				"SearchType": 1,
				"pagesize": optionval
			}
		};
		axios(config)
			.then(function (response) {
				var newArr = [];
				var itemNo = 1;
				var itemArr = [];
				if (response.data.Guests != null) {
					response.data.Guests.map(data => {
						newArr.push({ ...data, isChecked: false })
					})
					setMapData(newArr);
				}
				else {
					setMapData(null);
					setTotalGuest(0)
					setPaginationArr([])
				}
				itemNo = Math.ceil(response.data.Pagination.TotalRecords / response.data.Pagination.PageSize)
				for (let i = 1; i <= itemNo; i++) {
					itemArr.push(i)
				}
				setPaginationArr(itemArr);
				setTotalGuest(response.data.Pagination.TotalRecords)
			})

	};

	// const handleGeneratePdf = (response) => {
	// 	console.log("response of  pdf", response)
	// 	const doc = new jsPDF({
	// 		format: 'a4',
	// 		unit: 'px',
	// 	});

	// 	// Adding the fonts.
	// 	doc.setFont('Arial', 'normal');
	// 	doc.setFontSize(10);

	// 	doc.html(response, {
	// 		async callback(doc) {
	// 			await doc.save('document');
	// 		},
	// 	});
	// };
	const filterconfirmationid = (confid) => {
		const filteredData = mapData.filter(item => item.ConfirmationID === confid);
		return filteredData;
	};

	return (
		<React.Fragment>
			<AuthenticatedTemplate>
				<>
					{graphData && graphData.value.length > 0 ? (
						<>
							{valgraphData ? (
								<>
			<DashBoard></DashBoard>

			<div className="mainWrap mainTopMargin">
				<div className="container">
					<div className="mainHeadeing">
						<h1>Manage Tickets</h1>
					</div>
					<div className="subRow1">
						<div>
							<div className="leftsubRow">
								<div className="blockFormLabel">
									<div className="form-mb">
										<select className="form-select" onChange={(e) => { onchangeDataFilter(e.target.value) }}>
											<option value="1">Confirmation ID</option>
											<option selected value="2">Email Address</option>
											<option value="3">Group Name</option>
											<option value="4">Last Name</option>
										</select>
									</div>
									<div className="form-mb">
										<label className="form-label"></label>
										<input type="text" className="form-control" placeholder={placeholderText ? placeholderText : "Enter Email Address"}
											onChange={(e) => { onchangeEmailAddress(e.target.value) }} />
									</div>

									<div className="form-mb width-130">
										<label className="form-label">Waiver Status</label>
										<select className="form-select" onChange={(e) => { onchangeWaiverStatus(e.target.value) }}>
											<option value="-1" selected="" >All</option>
											<option value="1">Completed</option>
											<option value="0">Pending</option>
											<option value="2">Paper</option>
										</select>
									</div>
									<div className="form-mb">
										<label className="form-label">Date </label>
										<ReactDatePicker className="form-control1"
											selected={dateFilter}
											dateFormat="MM-dd-yyyy"
											placeholderText="mm-dd-yyyy"
											onChange={(date) => setDateFilter(date)}
											isClearable
											customInput={<CustomInput />}
										/>

									</div>
									<button className="btn btn-info" onClick={() => { handleSubmit() }}>Search</button>

								</div>

							</div>
						</div>

						<div className="rightsubRow managticMTsubrow">
							<h6><i className="fa fa-ticket" aria-hidden="true"></i>  {totalGuest} </h6>

							<ul className="btnSubrow">
								<li onClick={() => {
									var checkCount = 0;
									if (mapData && mapData.length > 0) {
										mapData.map(item => {
											if (item.isChecked) {
												if (valGuestData && valGuestData[0].ValidateTicket) {
													checkCount = checkCount + 1;
													navigate("/ManageTicketsAdd", { state: { ticketDetails: singleTicketData } })
												}
												else {
													checkCount = -1
													setDisable(true)
												}
											}
											else {
												setDisable(false)
											}
										})
									}
									if (checkCount == 0) {
										setDisable(false)
										navigate("/ManageTicketsAdd")
									}
									else {
										setDisable(true)
									}
								}} >
									<div>
										<a className={disabled ? `btndisabled` : ``} href="javascript:void(0)">
											<i className="fa fa-plus" aria-hidden="true"></i>
										</a>
									</div>
								</li>
								<li onClick={() => {
									var checkCount = 0;
									mapData.map(item => {
										if (item.isChecked && item.PaperWaiver) checkCount = checkCount + 1;
									})
									if (checkCount >= 1) {
										setIsPrintClicked(true)
									};

								}}>
									<a className={printDisable ? `btndisabled` : ``} href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#printModal">
										<i className="fa fa-print" aria-hidden="true"></i></a></li>
								<li onClick={() => {
									var checkCount = 0;
									mapData.map(item => {
										if (item.isChecked) {
											checkCount = checkCount + 1;

										}
									})
									if (checkCount >= 1) {
										setIsDeleteClicked(true);
										setCheckedCountDelete(checkCount)
									}

								}}>
									<a className={deleteDisable ? `btndisabled` : ``} href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#deleteModal"><i className="fa fa-trash" aria-hidden="true"></i></a></li>
							</ul>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table  align-middle">
							<thead>
								<tr>
									<th><input className="form-check-input" type="checkbox" checked={isAllChecked == 0 ? false : true}
										onClick={(e) => {
											var newArr = [];
											mapData.map(data => {
												newArr.push({ ...data, isChecked: e.target.checked }
												)

											})
											setMapData(newArr);
											setPrintDetails(mapData)
											setIsAllChecked(mapData.length)
											if (e.target.checked == true) {
												setPrintDisabled(false)
												setDeleteDisabled(false)
											}
											else {
												setIsAllChecked(0)
												setPrintDisabled(true)
												setDeleteDisabled(true)
											}
										}} />
										All
									</th>
									<th>Confirmation ID </th>
									<th>Group Name <div className="sort-icon">
										<i className="sort-by-asc" onClick={() => { sortdata(mapData, 'GroupName', 'ascending') }}></i>
										<i className="sort-by-desc" onClick={() => { sortdata(mapData, 'GroupName', 'descending') }} ></i>
									</div>
									</th>
									<th>Name  </th>
									<th>Email Address</th>
									<th>Event Date <div className="sort-icon">
										<i className="sort-by-asc" onClick={() => { sortdata(mapData, 'EventDate', 'ascending') }}></i>
										<i className="sort-by-desc" onClick={() => { sortdata(mapData, 'EventDate', 'descending') }} ></i>
									</div></th>
									<th className="tablehead-width">Slot Time <div className="sort-icon">
										<i className="sort-by-asc" onClick={() => { sortdata(mapData, 'Slot', 'ascending') }}></i>
										<i className="sort-by-desc" onClick={() => { sortdata(mapData, 'Slot', 'descending') }} ></i>
									</div></th>
									<th>Waiver Status</th>
									<th>Minor</th>
									<th>Edit</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<>
									{mapData && mapData.length > 0 ?
										<>
											{mapData.map((item, index) => {
												return (
													<>
														<tr>
															<td><input className="form-check-input"
																{...item?.isChecked ? { checked: true } : { checked: false }}
																type="checkbox"
																onClick={(e) => {
																	checkedPrintData(item, index, e.target.checked)
																	validateGuest(item, index, e.target.checked)
																	validatePrint(item, index, e.target.checked)
																	validateDelete(item, index, e.target.checked)

																}} /></td>
															<td>{item.ConfirmationID}</td>
															<td>{(item.isChecked && item.PaperWaiver != 0) ? <input className="form-control height30" type="text" defaultValue={item.GroupName} onChange={(e) => { onChangeGroupName(item, index, e.target.value) }} id="txtgroupname" /> : (item.GroupName)}</td>
															<td className="table-td-input">
																{(item.isChecked && item.PaperWaiver != 0) ? <div className="table-inputs">
																	<div className="tableform-input">
																		<label className="form-label">FirstName</label>
																		<input type="text" id="txtfirstname" defaultValue={item.FirstName} className="form-control" onChange={(e) => { onChangeFirstName(item, index, e.target.value) }} />
																	</div>
																	<div className="tableform-input">
																		<label className="form-label">MiddleName</label>
																		<input type="text" id="txtmiddlename" defaultValue={item.MiddleName} className="form-control" onChange={(f) => { onChangeMiddleName(item, index, f.target.value) }} />
																	</div>
																	<div className="tableform-input">
																		<label className="form-label">LastName</label>
																		<input type="text" id="txtlastname" defaultValue={item.LastName} className="form-control" onChange={(g) => { onChangeLastName(item, index, g.target.value) }} />
																	</div></div> : (item.FirstName + ' ' + (item.MiddleName != "" ? (item.MiddleName + ' ') : '') + item.LastName)}
															</td>
															<td>{item.EmailAddress}</td>
															<td class="column-width">{item.EventDate}</td>
															<td>{(item.isChecked && item.PaperWaiver != 0) ? <input className="form-control height30" type="text" defaultValue={item.Slot} onChange={(e) => { onChangeSlot(item, index, e.target.value) }} /> : (item.Slot)}</td>
															<td>{(item.PaperWaiver == 2) ? "Paper" : ((item.PaperWaiver == 0) ? "Pending" : "Completed")}</td>
															<td>{item.Minor ? "Yes" : "No"}</td>
															<td><a href="" onClick={() => {
																navigate("/ManageTicketsEdit", { state: { ticketDetails: item } })
															}}><i className="fa fa-pencil" aria-hidden="true"></i></a></td>
															{(item.PaperWaiver == 1 || item.PaperWaiver == 2) ? <><td><Link target="_blank" style={{ marginTop: '50px' }} to={`/WaiverView/${btoa(item.GuestID)}`}>View</Link></td></> : <td></td>}

														</tr>
													</>
												)
											})}


										</>
										:
										<>
											<td></td>
											<td></td>
											<td></td>
											<h2><center>No Record Found!</center></h2></>
									}
								</>
							</tbody>
						</table>
					</div>
					
					<div className="tablepagination">
						<ul className="tableitems">
							<p className="para-select">Items per page</p>
							<p className="pagesize-select-list">

								<select value={pageSelection} className="form-select" onChange={handlepagesizechange}>
									<>
										{pagesizeno.map((option) => (
											<option value={option}>
												{option}
											</option>
										))}

									</>
								</select>
							</p>
						</ul>
						<ul className="pagination" onClick={(e) => {
							setSelectedPageNo(Number(e.target.value == 0 ? selectedPageNo : e.target.value));
						}}>

							<li className="page-item active" class="page-link">
								<button
									onClick={() => {
										if (pageNoStart !== 0) {

											setPageNoStart(pageNoStart - 1)
											setPageNoEnd(pageNoEnd - 1)
										}
									}}
									value={selectedPageNo - 1} disabled={selectedPageNo <= 1} style={{ border: "none" }}
								>Previous</button>
							</li>
							{paginationArr.slice(pageNoStart, pageNoEnd).map((item, index) => {
								return (
									<>
										<a>
											<li value={item}
												className="page-item active" class={selectedPageNo == item ? "page-link pagination-color" : "page-link"}>
												{item}
											</li>
										</a>
									</>
								)
							})}
							{paginationArr.length > pageNoEnd && <li className="page-item active" class="page-link">...</li>}

							<li className="page-item active" class="page-link">
								<button value={selectedPageNo + 1}
									onClick={() => {
										if (paginationArr.length > pageNoEnd) {
											setPageNoStart(pageNoStart + 1)
											setPageNoEnd(pageNoEnd + 1)
										}
									}}
									disabled={selectedPageNo >= paginationArr.length} style={{ border: "none" }}> Next</button>
							</li>
						</ul>
						</div>
					
					{/* <nav aria-label="Page navigation example" className="pull-right">
						<div className="list-inlineblock">
							<ul className="unordered-list-block">
									<p className="para-select">Items per page</p>
									<p className="pagesize-select-list">

									<select value={pageSelection} className="form-select" onChange={handlepagesizechange}>
										<>
											{pagesizeno.map((option) => (
												<option value={option}>
													{option}
												</option>
											))}

										</>
									</select>
								</p>
							</ul>
							<ul className="pagination" onClick={(e) => {
								setSelectedPageNo(Number(e.target.value == 0 ? selectedPageNo : e.target.value));
							}}>

								<li className="page-item active" class="page-link">
									<button
										onClick={() => {
											if (pageNoStart !== 0) {

												setPageNoStart(pageNoStart - 1)
												setPageNoEnd(pageNoEnd - 1)
											}
										}}
										value={selectedPageNo - 1} disabled={selectedPageNo <= 1} style={{ border: "none" }}
									>Previous</button>
								</li>
								{paginationArr.slice(pageNoStart, pageNoEnd).map((item, index) => {
									return (
										<>
											<a>
												<li value={item}
													className="page-item active" class={selectedPageNo == item ? "page-link pagination-color" : "page-link"}>
													{item}
												</li>
											</a>
										</>
									)
								})}
								{paginationArr.length > pageNoEnd && <li disabled="disabled" className="page-item active" class="page-link">...</li>}

								<li className="page-item active" class="page-link">
									<button value={selectedPageNo + 1}
										onClick={() => {
											if (paginationArr.length > pageNoEnd) {
												setPageNoStart(pageNoStart + 1)
												setPageNoEnd(pageNoEnd + 1)
											}
										}}
										disabled={selectedPageNo >= paginationArr.length} style={{ border: "none" }}> Next</button>
								</li>
							</ul>
						</div>
					</nav> */}
				</div>
			</div>




			{/*------ Delete Modal ----*/}

			<Modal centered
				show={isDeleteClicked}
				onHide={() => setIsDeleteClicked(false)} className="modal fade deleteModalStyle" id="deleteModal" aria-hidden="true">

				<div className="modal-content">
					<Modal.Header className="modal-header" closeButton>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</Modal.Header>
					<Modal.Body className="modal-body">
						{checkedCountDelete > 1 ?
							<p>Are you sure you want to delete these {checkedCountDelete} items?</p>
							:
							<p>Are you sure you want to delete this item?</p>}
						<div className="deleteModalBt">
							<button className="btn btn-info grayBt" data-bs-dismiss="modal"
								onClick={() => setIsDeleteClicked(false)}>Cancel</button>
							<button className="btn btn-info" data-bs-dismiss="modal"
								onClick={deleteTicket}>Submit</button>
							<p>{deleteErrMsg && deleteErrMsg}</p>
						</div>
					</Modal.Body>
				</div>

			</Modal>


			<Modal centered
				show={isPrintClicked}
				onHide={() => setIsPrintClicked(false)} className="modal fade printModalStyle" id="printModal" tabindex="-1" >
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<Modal.Header className="modal-header" closeButton>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</Modal.Header>
						<Modal.Body className="modal-body print-card" ref={printRef}>
							{printDetails.map((data) => {
								if (data.WaiverStatus || data.PaperWaiver || data.PaperWaiver == 2) {
									let value = `${process.env.REACT_APP_WEBHOOKS_ISACTIVE}`
									if (value.toLowerCase() == "true" && (data.TicketType == "VIP" || data.TicketType == "Group")) {
										webhooks(data.GuestID, data.TicketType, data.FirstName, data.LastName, data.EmailAddress)
									}
									return (
										<>

											<div style={{ pageBreakAfter: 'always' }}>
												<div className="bodyPrint" >
													<img src={img} />
												</div>
												<div className="contPrint">
													<div className="namePrint">
														<div>
															<h5 class="firstNameStyle" > {data.FirstName + ' ' + (data.MiddleName != "" ? (data.MiddleName + ' ') : '') + data.LastName}</h5>
															<h5>{data.EventDate}</h5>
															<div className="qrcode">
																<QRCode value={data.GuestID} size={50} />
																<br />
																<span className="confidsize">{data.GuestID}</span>
																<br />
															</div>
															<h6>{data.Slot}</h6>
															<p>{data.TicketType}</p><br />
														</div>

													</div>
												</div>
											</div>
										</>
									)
								}
							})

							}
						</Modal.Body>
						<Modal.Footer>
							<ReactToPrint
								content={printContent}
								removeAfterPrint>
								<PrintContextConsumer>
									{({ handlePrint }) => (
										<div className="print-btn">
											<button className="btn btn-info" onClick={() => handlePrint()} data-bs-dismiss="modal">Continue Print</button>
										</div>
									)}
								</PrintContextConsumer>
							</ReactToPrint>
						</Modal.Footer>
					</div>
				</div>
			</Modal>


			<Footer></Footer>
			</>
							) : (<ValidateGraphData setvalGraphData={setvalGraphData} graphData={graphData} />)}
						</>
					) : (<GetAccessToken setGraphData={setGraphData} graphData={graphData} account={accounts} instance={instance} />)}
				</>
			</AuthenticatedTemplate >
			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</React.Fragment >

	)
}