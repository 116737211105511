import React, { useEffect, useState } from "react";
import logo from '../../assets/images/successfully-icon.svg'
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useLocation } from "react-router";
export default function SuccessfullMessage() {
    const location = useLocation()
    const [confNumber, setConfNumber] = useState("");
    useEffect(() => {
        if (window.history.state && window.history.state.usr && window.history.state.usr.length > 0) {
            if (location.state[0] && (location.state[0] != null && location.state[0] != "")) {
                setConfNumber(location.state)
            }
        }
        // }
        // window.history.pushState(null, null, location.href)
        window.onpopstate = function (event) {
            window.history.go(1);
        };

    })
    return (
        <>
            <Header></Header>
            <div className="mainWrap mainTopMargin">
                <div className="container">
                    <div className="successfullyWrapp">
                        <img src={logo} alt="" />
                        <h1>{process.env.REACT_APP_SUCCESS_MSG}</h1>
                        <p dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_CONTACT_MSG }} />

                    </div>
                    {confNumber != "" ? <>
                        <div className="successfullyWrapp">
                            <h1><p>Your Confirmation Number is:</p>{confNumber}</h1>
                        </div>
                        <div className="successfullyloginWrapp">
                            <p>Click the <a href="/onsitelogin">OnSiteLogin</a> to go back to the Login page</p>
                        </div>
                        </> : ""
                            }
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}