import { React, useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Card, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import Login from "../../AzureLogin/Login";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from "../../AzureLogin/GraphQL";
import ValidateGraphData from "../../AzureLogin/ValidateGraphAPI";
import GetAccessToken from "../../AzureLogin/GetAccessToken";
import { isValidTime, containsOnlyNumbers } from "../utilities";
/*Added on 16-06-2023*/
import moment from "moment";
import { webhooks } from "../WebHooksApi/webhooks";

const ManageTicketsEdit = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { instance, accounts } = useMsal();
	const [graphData, setGraphData] = useState(null);
	const [valgraphData, setvalGraphData] = useState(false)
	const currentdate = new Date()
	let date = moment(currentdate).format("MM-DD-YYYY")
	const [btnDisabled, setbtnDisabled] = useState(false);
	const handleTimeChange = (event) => {
		const inputTime = event.target.value;
		const colonAddedTime = insertColonAfterHour(inputTime);
		setTicketInfo({
			...ticketInfo, Slot: colonAddedTime, errSlot: ''
		})
	  };
	
	  const insertColonAfterHour = (timeStr) => {
		const cleanedTime = timeStr.replace(/[^\d]/g, '');
	
		if (cleanedTime.length > 2) {
		  const hour = cleanedTime.slice(0, 2);
		  const minute = cleanedTime.slice(2);
		  return `${hour}:${minute}`;
		}
	
		return cleanedTime;
	  };
	let obj = {
		"TicketID": location?.state?.ticketDetails?.TicketID ? location.state.ticketDetails.TicketID : "",
		"FirstName": location?.state?.ticketDetails?.FirstName ? location.state.ticketDetails.FirstName : "",
		"MiddleName": location?.state?.ticketDetails?.MiddleName ? location.state.ticketDetails.MiddleName : "",
		"LastName": location?.state?.ticketDetails?.LastName ? location.state.ticketDetails.LastName : "",
		"EmailAddress": location?.state?.ticketDetails?.EmailAddress ? location.state.ticketDetails.EmailAddress : "",
		"ConfirmationID": location?.state?.ticketDetails?.ConfirmationID ? location.state.ticketDetails.ConfirmationID : "",
		"IsMinor": location?.state?.ticketDetails?.Minor ? location.state.ticketDetails.Minor : false,
		"errFirstName": "",
		"errMiddleName": "",
		"errLastName": "",
		"PaperWaiver": location?.state?.ticketDetails?.PaperWaiver ? location.state.ticketDetails.PaperWaiver : 0,
		"TicketType": location?.state?.ticketDetails?.TicketType ?
			(location.state.ticketDetails.TicketType == "Standard Admission" ? "1"
				: location.state.ticketDetails.TicketType == "VIP" ? "2"
					: location.state.ticketDetails.TicketType == "Group" ? "3" : "1") : "1",
		"EventDate": location?.state?.ticketDetails?.EventDate ? location.state.ticketDetails.EventDate : "",
		"Slot": location?.state?.ticketDetails?.Slot ? location.state.ticketDetails.Slot : "",
		"errSlot": "",
		"validatewaiverforminor": location?.state?.ticketDetails?.WaiverStatus ? location.state.ticketDetails.WaiverStatus : 0,
		"isDisabled": false
	}

	const [ticketInfo, setTicketInfo] = useState(obj);
	const [firstName, setFirstName] = useState({ firstName: "", errFirstName: "" })
	const [lastName, setLastName] = useState({ lastName: "", errLastName: "" })
	const [disable, setDisable] = useState(false)
	const [slot, setSlot] = useState({ Slot: "", errSlot: "" });
	const [TicketType, setTicketType] = useState(3);

	const firstRef = useRef([]);
	const lastRef = useRef([]);
	const slotRef = useRef([]);

	useEffect(() => {
		if (location?.state?.ticketDetails?.TicketType) {
			setTicketType(location.state.ticketDetails.TicketType == "Standard Admission" ? "1"
				: location.state.ticketDetails.TicketType == "VIP" ? "2"
					: location.state.ticketDetails.TicketType == "Group" ? "3" : "1")
		}
		/*Added for Production changes*/
		if (location?.state?.ticketDetails?.EventDate) {
			debugger;
			const eventDate = Date.parse(location.state.ticketDetails.EventDate)
			const currentDate = Date.parse(date)
			if (eventDate < currentDate) {
				setbtnDisabled(true);
			}
		}
	}, [])

	/*****   Form Validations   *****/
	const notValidate = () => {
		let doNotValidate = false
		if (ticketInfo.FirstName == "") {
			setFirstName((old) => { return ({ ...old, errFirstName: " Required" }) })
			doNotValidate = true
		}
		else {
			setFirstName((old) => { return ({ ...old, errFirstName: "" }) })
		}

		if (ticketInfo.LastName == "") {
			setLastName((old) => { return ({ ...old, errLastName: " Required" }) })
			doNotValidate = true
		}
		else {
			setLastName((old) => { return ({ ...old, errLastName: "" }) })

		}

		if (ticketInfo.Slot === "") {
			setSlot((old) => { return ({ ...old, errSlot: " Required" }) })
			doNotValidate = true
		}
		else if (!isValidTime(ticketInfo.Slot)) {
			setSlot((old) => { return ({ ...old, errSlot: " Invalid Slot Time" }) })
			doNotValidate = true
		}
		else {
			setSlot((old) => { return ({ ...old, errSlot: "" }) })

		}

		let count = 1;
		if (ticketInfo.errFirstName) {
			firstRef.current[0].focus();
			count = 0;
		}
		else if (ticketInfo.errLastName) {
			lastRef.current[0].focus();
			count = 0;
		}
		else if (ticketInfo.errSlot) {
			slotRef.current[0].focus();
			count = 0;
		}

		return doNotValidate
	}
	/*****   Handle functions *****/

	const handleFirstName = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, FirstName: val, errFirstName: ''
		})

	}
	const handleMiddleName = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, MiddleName: val,
		})
	}
	const handleLastName = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, LastName: val, errLastName: ''
		})
	}

	const handleEmailAddress = (val) => {
		val = val
		setTicketInfo({
			...ticketInfo, EmailAddress: val
		})

	}
	const handleIsMinorYes = (val) => {
		if (val === true) {
			setTicketInfo({
				...ticketInfo, IsMinor: val, errFirstName: '', errLastName: ''
			});
			setDisable(false)
		}
		else {
			setTicketInfo({
				...ticketInfo, IsMinor: !val
			});
			setDisable(false)
		}
	}
	const handleIsMinorNo = (val) => {
		if (val === true) {
			setTicketInfo({
				...ticketInfo, IsMinor: !val,
				errFirstName: '', errLastName: ''
			});
			setDisable(false)
		}
		else {
			setTicketInfo({
				...ticketInfo, IsMinor: val
			});
			setDisable(true)
		}
	}
	const handleIsPaperYes = (val) => {

		setTicketInfo({
			...ticketInfo, PaperWaiver: 2, validatewaiverforminor: 2
		})
	}
	const handleIsPaperNo = (val) => {

		setTicketInfo({
			...ticketInfo, PaperWaiver: 0, validatewaiverforminor: 0
		})
	}
	const handleTicketType = (val) => {
		debugger;
		if (val != null) {
			setTicketType(val)
			setTicketInfo({
				...ticketInfo, TicketType: val ? (val == "1" ? "Standard Admission" : val == "2" ? "VIP" : val == "3" ? "Group" : "1") : "1"
			})
		}

	}
	const handleSlot = (val) => {
		debugger;
		val = val
		setTicketInfo({
			...ticketInfo, Slot: val, errSlot: ''
		})

		// for (let i = 0; i < val.length; i++) {
		// 	if(val.le)
		// 	var check = containsOnlyNumbers(val[i]);
		// 	if (check == true) {
		// 		if (val.length == 2 && val[i] !== ":") {
		// 			val = val.substring(0, 2) + ":" + val.slice(2);
		// 		}
		setTicketInfo({
			...ticketInfo, Slot: val, errSlot: ''
		})
		// 	}
		// 	else
		// 	{
		// 		if(val.length==2&&val[i]==":")
		// 		{
		// 			setTicketInfo({
		// 				...ticketInfo, Slot: val, errSlot: ''
		// 			})
		// 		}
		// 	}


		// }
		// if (val.length > 2 && val[2] !== ":") {
		// 	val = val.substring(0, 2) + ":" + val.slice(2);
		// }
		// if (containsOnlyNumbers(val) == true) {
		// setTicketInfo({
		// 	...ticketInfo, Slot: val, errSlot: ''
		// })
		// }
		// else
		// {
		// setTicketInfo({
		// 	...ticketInfo, Slot: val, errSlot: ''
		// })
		// }
	}


	const handleSubmit = () => {
		if (notValidate()) {
			return;
		}
		else {
			let finalArray = [];
			var ticketInfoArr = []
			ticketInfoArr.push({ ...ticketInfo, 'GuestID': location?.state?.ticketDetails.GuestID, TicketType: TicketType ? (TicketType == "1" ? "Standard Admission" : TicketType == "2" ? "VIP" : TicketType == "3" ? "Group" : "Standard Admission") : "Standard Admission" })
			finalArray[0] =
			{
				"ConfirmationNumber": location?.state?.ticketDetails.ConfirmationID,
				"EmailAddress": location?.state?.ticketDetails.EmailAddress,
				"TicketID": location?.state?.ticketDetails.TicketID,
				"TicketType": TicketType,
				"TicketDetails": ticketInfoArr,

			}
			const reqData = JSON.stringify(finalArray)


			const config = {
				method: 'PUT',
				url: `${process.env.REACT_APP_WEBSITE_NAMES}/updateguestdetails?type=Update`,
				headers: {
					'Content-Type': 'application/json',
					'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
				},
				data: reqData
			};
			axios(config)
				.then(function (response) {
					if (response.data == 1) {
						if (ticketInfo.PaperWaiver == 0) {
							navigate('/onsitelogin')
						}
						else {
							const config = {
								method: 'GET',
								url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?queryparams=""&ConfirmationID=${location?.state?.ticketDetails?.ConfirmationID ? location.state.ticketDetails.ConfirmationID : ""}`,
								headers: {
									'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
								}
							};
							axios(config)
								.then(function (response) {
									debugger;
									let value = `${process.env.REACT_APP_REDIRECT_REG_ON_NEWGUEST}`
									if (response.data && response.data.TicketDetails.length > 0) {
										response.data.TicketDetails.map((guest) => {
											if (ticketInfo.PaperWaiver == 2 && guest.GuestID != "") {
												if (guest.TicketTypeID == 2 || guest.TicketTypeID == 3) {
													let value = `${process.env.REACT_APP_WEBHOOKS_ISACTIVE}`
													if (value.toLowerCase() == "true" && (guest.TicketType == "VIP" || guest.TicketType == "Group")) {
														webhooks(guest.GuestID, guest.TicketType, guest.FirstName, guest.LastName, guest.EmailAddress)
													}
												}
											}
										})
									}
								})
								.catch(function (error) {
									console.log(error);
								});
							navigate('/ManageTickets', { state: ticketInfoArr })
						}
					}

				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}



	return (
		<>
			<AuthenticatedTemplate>
				<>
					{graphData ? (
						<>
							{valgraphData ? (
								<>
			<Header></Header>
			<div className="mainWrap mainTopMargin">
				<div className="container">
					<div className="mainHeadeing">
						<h1>Edit Tour Registration</h1>
					</div>
					<> <p className="asterick">Note:<span className="asterick-size">' * '</span>Fields that are required must be filled out.</p>
						<Card className="card">
							<div className="card-body">
								<div className="formBlockDefault">
									<Row className="row">
										<Col className="col-lg-4 col-md-6 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Confirmation ID *</label>
												<input readOnly disabled type="text" value={ticketInfo.ConfirmationID} className="form-control" placeholder="" />
											</div>
										</Col>
										<Col className="col-lg-3 col-md-6 col-sm-6">
											<div className="mb-3">
												<label className="form-label"> Ticket Type</label>
												<select className="form-select" value={TicketType ? TicketType : "1"} onChange={(e) => { handleTicketType(e.target.value) }} onSelect={ticketInfo.TicketType}>
													<option value="1">Standard Admission</option>
													<option value="2">VIP</option>
													<option value="3">Group</option>
												</select>
											</div>

										</Col>
									</Row>

									<Row className="row">
										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">First Name *<span className="errMsg1" >{firstName.errFirstName}</span></label>
												<input type="text" ref={(ref) => (firstRef.current[0] = ref)} value={ticketInfo.FirstName} className="form-control" placeholder="Enter first name"
													disabled={disable} maxLength="100" onChange={(e) => { handleFirstName(e.target.value) }} />
											</div>
										</Col>

										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Middle Name</label>
												<input type="text" value={ticketInfo.MiddleName} className="form-control" placeholder="Enter middle name"
													disabled={disable} maxLength="100" onChange={(e) => { handleMiddleName(e.target.value) }} />
											</div>
										</Col>

										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Last Name *<span className="errMsg1" >{lastName.errLastName}</span></label>
												<input type="text" ref={(ref) => (lastRef.current[0] = ref)} value={ticketInfo.LastName} className="form-control" placeholder="Enter last name"
													disabled={disable} maxLength="100" onChange={(e) => { handleLastName(e.target.value) }} />
											</div>
										</Col>

										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Email Address *</label>
												<input type="text" className="form-control"
													defaultValue={ticketInfo.EmailAddress}
													placeholder="Enter Email Address" maxLength="256"
													onChange={(e) => { handleEmailAddress(e.target.value) }} readOnly disabled />
											</div>
										</Col>
									</Row>
									<Row>
										<Col className="col-lg-3 col-md-4 col-sm-6">
											<div className="mb-3">
												<label className="form-label">Minor *</label>
												<div className="form-check form-check-inline">
													<input disabled={ticketInfo.validatewaiverforminor == 1} className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadioYes1"
														checked={(ticketInfo.IsMinor == true ? true : false)}
														onChange={(e) => handleIsMinorYes(e.target.checked)} />
													<label className="form-check-label" for="inlineRadioYes1">Yes</label>
												</div>
												<div className="form-check form-check-inline">
													<input disabled={ticketInfo.validatewaiverforminor == 1} className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadioNo1"
														checked={(ticketInfo.IsMinor == true ? false : true)}
														onChange={(e) => handleIsMinorNo(e.target.checked)} />
													<label className="form-check-label" for="inlineRadioNo1">No</label>
												</div>
											</div>
										</Col>
										{ticketInfo.PaperWaiver != 1 ?
											<>
												<Col className="col-lg-3 col-md-4 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Paper *</label>
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadioYes"
																checked={(ticketInfo.PaperWaiver == 2 ? true : false)}
																onChange={(e) => handleIsPaperYes(e.target.checked)} />
															<label className="form-check-label" for="inlineRadioYes">Yes</label>
														</div>
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadioNo"
																checked={(ticketInfo.PaperWaiver == 2 ? false : true)}
																onChange={(e) => handleIsPaperNo(e.target.checked)} />
															<label className="form-check-label" for="inlineRadioNo">No</label>
														</div>
													</div>
												</Col>
											</> : ""
										}

										<Col className="col-lg-3 col-md-4 col-sm-6">


											<div className="mb-3">
												<label className="form-label">Slot * (24-hour format)<span className="errMsg1" >{slot.errSlot}</span></label>
												<input
													ref={(ref) => (slotRef.current[0] = ref)}
													className="form-control slot"
													type="text"
													id="timeInput"
													defaultValue={ticketInfo.Slot}
													value={ticketInfo.Slot}
													onChange={handleTimeChange}
													placeholder="HH:MM"
													maxLength="5" // Restrict input to 5 characters (e.g., "hh:mm")
													pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
												/>
												
												{/* <input type="time" ref={(ref) => (slotRef.current[0] = ref)} className="form-control slot" defaultValue={ticketInfo.Slot}
													placeholder="HH:MM" maxLength="5" value={ticketInfo.Slot} pattern="[0-9]{2}:[0-9]{2}"
													onChange={(e) => { handleSlot(e.target.value) }} /> */}
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</Card>
					</>

					<div className="footerButtonBlock">
						<div className="pull-right">
							<a href="/ManageTickets" className="btn btn-info grayBt">Cancel</a>
							{(ticketInfo.PaperWaiver == 1 || ticketInfo.PaperWaiver == 2) ? <>
								<a className={btnDisabled ? `btn btn-info btndisabled` : `btn btn-info`} onClick={() => handleSubmit()}>Save and Print</a>
							</> : <>
								<a className={btnDisabled ? `btn btn-info btndisabled` : `btn btn-info`} disabled={ticketInfo.EventDate ? (ticketInfo.EventDate < date ? true : false) : false} onClick={() => handleSubmit()}>Submit</a>
							</>
							}
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
								</>
							) : (<ValidateGraphData setvalGraphData={setvalGraphData} graphData={graphData} />)}
						</>
					) : (<GetAccessToken setGraphData={setGraphData} graphData={graphData} account={accounts} instance={instance} />)}
				</>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</>
	)
}
export default ManageTicketsEdit