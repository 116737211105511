import { useState } from "react";

export default function ValidateGraphData(props) {

	let groupID = `${process.env.REACT_APP_GROUP_ID}`
	let splitgroupid = groupID.split(',')
	if (props.graphData && props.graphData.value.length > 0) {
		let condition = false;
		splitgroupid.map((id) => {
				props.graphData.value.forEach(element => {
					if (element.id === id && !condition) {
						props.setvalGraphData(true);
						condition = true;
						return;
					}
				});
		});
		
	}
}