import React, { useEffect, useState, useRef, useDebugValue } from "react";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import moment from "moment";
import { isValidDate, isValidEmail, isValidName } from "../utilities";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import { webhooks } from "../WebHooksApi/webhooks";


export default function BuyerIndividual() {

  const [response, setResponse] = useState({});
  const [ticketInfo, setTicketInfo] = useState([]);
  const [disable, setDisable] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [IsMinorOn, setIsMinorOn] = useState({});
  const [thisMeOn, setThisIsMeOn] = useState({ val: false, index: 0 });
  const [preEmail, setPreEmail] = useState("");
  const [isNotValid, setIsNotValid] = useState(false);
  const [blurbMessage, setBlurbMessage] = useState(process.env.REACT_APP_GROUP_BLURB);
  const [blurbMinorMessage, setBlurbMinorMessage] = useState(process.env.REACT_APP_GROUP_BLURB);
  const [formMsg, setFormMsg] = useState("<br/><br/><h1>Loading...</h1>");
  const [iamParent, setIamParent] = useState();
  const navigate = useNavigate();
  const firstRef = useRef([]);
  const lastRef = useRef([]);
  const middleRef = useRef([]);
  const emailRef = useRef([]);
  const consentSignatureRef = useRef([]);
  const isAgreeRef = useRef([]);
  const [acceptAgree, setAcceptAgree] = useState(false);
  const [agree, setAgree] = useState(false);
  const [isHide, setisHide] = useState(false);

  let newDate = new Date();
  let date = moment().format("MM-DD-YYYY")
  const ShowTime = moment().format("h:mm A")

  let obj = {
    "FirstName": "",
    "MiddleName": "",
    "LastName": "",
    "EmailAddress": "",
    "IsMinor": false,
    "Guardian": "",
    "WaiverStatus": false,
    "Active": true,
    "ConsentPrintName": "",
    "ConsentSignature": "",
    "ConsentSignedDate": "",
    "errFirstName": "",
    "errMiddleName": "",
    "errLastName": "",
    "errConsentPrintName": "",
    "errConsentSignature": "",
    "errConsentSignedDate": "",
    "ISGroup": "",
    "Slot": "",
    "isAgree": false,
    "errIsAgree": ""
  }
  useEffect(() => {
  }, [IsMinorOn])
  const handleFirstName = (val, index) => {
    val = val
    let arr = [...ticketInfo]
    if (val.trim()) {
      arr[index] = { ...arr[index], errFirstName: '' }
    }
    arr[index] = { ...arr[index], FirstName: val, errFirstName: '' }
    setTicketInfo(arr)

  }
  const handleLastName = (val, index) => {
    let arr = [...ticketInfo]
    if (val.trim()) {
      arr[index] = { ...arr[index] }
    }
    let ConPrintName = arr[index].FirstName ? (arr[index].FirstName + " ") : "" + arr[index].MiddleName ? (arr[index].MiddleName + " ") : "" + val
    arr[index] = {
      ...arr[index], LastName: val, ConsentPrintName: ConPrintName,
      errLastName: ''
    }

    setTicketInfo(arr)

  }
  const handleMiddleName = (val, index) => {
    let arr = [...ticketInfo]
    if (val.trim()) {
      arr[index] = { ...arr[index] }
    }
    arr[index] = { ...arr[index], MiddleName: val, errMiddleName: '' }
    setTicketInfo(arr)

  }
  
  const handleKeyDown = e => {
		if (e.key === " ") {
			e.preventDefault();
		}
	};

  const handleEmailAddress = (val, index) => {
    let arr = [...ticketInfo]
    if (val.trim()) {
      ticketInfo.map((item, i) => {
        if (i == 0 && thisMeOn.val && item.EmailAddress == val.trim()) {
          setIsMinorOn({ val: true, index: index });
          setIamParent(true);
        }
      })
      arr[index] = { ...arr[index], errEmailAddress: '' }
    }
    arr[index] = {
      ...arr[index], EmailAddress: val.trim(), errEmailAddress: '',
      ConsentPrintName: (thisMeOn.val && arr[index].IsMinor && arr[0].EmailAddress == val) ?
        (arr[0].FirstName + " " + (arr[0].MiddleName ? (arr[0].MiddleName + " ") : "") + (arr[0].LastName)) : ""
    }
    setTicketInfo(arr)
  }
  const handleIsMinor = (val, index) => {

    let arr = [...ticketInfo]
    arr[index] = {
      ...arr[index], IsMinor: val, ConsentSignedDate: (val ? arr[0].ConsentSignedDate : ''),
      errFirstName: '', errMiddleName: '', errLastName: '', MiddleName: '',
      FirstName: '', LastName: '', EmailAddress: '',
      ConsentPrintName: (thisMeOn.val && val && arr[0].EmailAddress == arr[index].EmailAddress) ?
        (arr[0].FirstName + " " + (arr[0].MiddleName ? (arr[0].MiddleName + " ") : "") + (arr[0].LastName)) : ""
    }
    setTicketInfo(arr);
    setIsMinorOn({ val: val, index: index });
    setDisable(val)

  }
  const handleIamParent = (val, index) => {
    let arr = [...ticketInfo]
    if (val && thisMeOn.val) {
      arr[index] = { ...arr[index], EmailAddress: preEmail, errEmailAddress: '' }
      setTicketInfo(arr);
      setIamParent({ val: val, index: index })
    }
    else {
      arr[index] = {
        ...arr[index], EmailAddress: '', FirstName: '', MiddleName: '', LastName: '',
        errFirstName: '', errMiddleName: '', errLastName: '', errMiddleName: ''
      }
      setTicketInfo(arr);
    }
  }
  const handleSlotChange = (val) => {
    let arr = [...ticketInfo]
    for (let i = 0; i < arr.length; i++) {
      arr[i] = { ...arr[i], Slot: val }
    }
    setTicketInfo(arr)
  }
  const handleThisIsMe = (val, index) => {
    let arr = [...ticketInfo]
    if (val) {
      arr[index] = {
        ...arr[index], IsMinor: false, FirstName: response.FirstName,
        MiddleName: response.MiddleName ? response.MiddleName : "", LastName: response.LastName,
        EmailAddress: response.EmailAddress, errFirstName: '', errMiddleName: '', errLastName: '', errConsentSignature: '', errIsAgree: ''
      }
    }
    else {

      for (let i = 1; i < arr.length; i++) {
        if (arr[i].IsMinor) {
          arr[i] = {
            ...arr[i], FirstName: '', MiddleName: '', LastName: '', EmailAddress: '',
            errFirstName: '', errMiddleName: '', errLastName: '', errConsentSignature: '', errIsAgree: ''
          }
        }
      }
    }
    setTicketInfo(arr)
    setThisIsMeOn({ val: val, index: index })
    setIsShow(true)
  }
  const handleConsentPrintName = (val, index) => {
    let arr = ticketInfo
    arr.splice(index, 1, { ...arr[index], ConsentPrintName: val })
    setTicketInfo(arr)
  }
  const handlePrintConsentSignature = (val, index) => {
    let arr = ticketInfo
    if (val && index != 0) {
      arr[index] = { ...arr[index], ConsentSignature: ticketInfo[0].LastName, errConsentSignature: '' }
    }
    else {
      arr[index] = { ...arr[index], ConsentSignature: val, errConsentSignature: '' }

    }
    setTicketInfo(arr)

  }
  const handleConsentSignedDate = (val, index) => {
    isValidDate(val)
    let arr = [...ticketInfo]
    if (val) {
      arr[index] = { ...arr[index] }
    }
    arr[index] = { ...arr[index], ConsentSignedDate: val, errConsentSignedDate: '' }
    setTicketInfo(arr)
  }
  const checkBoxHandler = (val) => {

    let arr = [...ticketInfo]
    if (val == true) {
      arr[0] = { ...arr[0], isAgree: val, errIsAgree: '' }
      setTicketInfo(arr)
      setAgree(val)
      setisHide(val);
    }
    else {
      let arr = [...ticketInfo]
      arr[0] = { ...arr[0], isAgree: val, errIsAgree: '' }
      setTicketInfo(arr)
      setAgree(val)
      setisHide(val);
    }

  }

  const onAccept = () => {
    debugger
    setisHide(false)
    setAcceptAgree(true);
  }
  const onCancel = () => {
    setisHide(false);
    setAcceptAgree(false);
    let arr = ticketInfo
    arr[0] = { ...arr, isAgree: false, errIsAgree: '' }
  }

  //Validation function

  const notValidate = () => {
    var doNotValidate = false
    let arr = [...ticketInfo]
    for (let i = 0; i < ticketInfo.length; i++) {
      arr[i] = { ...arr[i], errFirstName: '' }
      arr[i] = { ...arr[i], errLastName: '' }
      arr[i] = { ...arr[i], errMiddleName: '' }
      arr[i] = { ...arr[i], errEmailAddress: '' }
      arr[i] = { ...arr[i], errConsentPrintName: '' }
      arr[i] = { ...arr[i], errConsentSignature: '' }
      arr[i] = { ...arr[i], errConsentSignedDate: '' }
      if (((ticketInfo[i].FirstName).trim() === "" && !ticketInfo[i].IsMinor) || ((ticketInfo[i].FirstName).trim() === "" && !ticketInfo[i].IsMinor) && (preEmail !== ticketInfo[i].EmailAddress && ticketInfo[i].IsMinor)) {
        arr[i] = { ...arr[i], errFirstName: 'Required' }
        doNotValidate = true
      }
      else if ((ticketInfo[i].FirstName).trim() === "" && (preEmail === ticketInfo[i].EmailAddress && ticketInfo[i].IsMinor && thisMeOn.val)) {
        arr[i] = { ...arr[i], errFirstName: 'Required' }
        doNotValidate = true
      }

      else if (isValidName(ticketInfo[i].FirstName) &&
        (!ticketInfo[i].IsMinor)) {
        arr[i] = { ...arr[i], errFirstName: 'Invalid Name' }
        doNotValidate = true

      }
      else if ((isValidName(ticketInfo[i].FirstName)) && (preEmail === ticketInfo[i].EmailAddress && ticketInfo[i].IsMinor && thisMeOn.val)) {
        arr[i] = { ...arr[i], errFirstName: 'Invalid Name' }
        doNotValidate = true
      }

      if ((!ticketInfo[i].MiddleName || (ticketInfo[i].MiddleName).trim() == "") &&
        ((!ticketInfo[i].IsMinor) || (ticketInfo[i].IsMinor))) {
        arr[i] = { ...arr[i], errMiddleName: '' }

      }
      else if (isValidName(ticketInfo[i].MiddleName) &&
        ((!ticketInfo[i].IsMinor) || (ticketInfo[i].IsMinor))) {
        arr[i] = { ...arr[i], errMiddleName: 'Invalid Name' }
        doNotValidate = true
      }

      if (((ticketInfo[i].LastName.trim()) == "" && !ticketInfo[i].IsMinor) || ((ticketInfo[i].LastName.trim()) == ""
        && !ticketInfo[i].IsMinor) && (preEmail !== ticketInfo[i].EmailAddress && ticketInfo[i].IsMinor)) {

        arr[i] = { ...arr[i], errLastName: 'Required' }
        doNotValidate = true
      }
      else if ((ticketInfo[i].LastName).trim() === "" && (preEmail === ticketInfo[i].EmailAddress && ticketInfo[i].IsMinor && thisMeOn.val)) {
        arr[i] = { ...arr[i], errLastName: 'Required' }
        doNotValidate = true
      }
      else if (isValidName(ticketInfo[i].LastName) &&
        (!ticketInfo[i].IsMinor)) {
        arr[i] = { ...arr[i], errLastName: 'Invalid Name' }
        doNotValidate = true
      }
      else if ((isValidName(ticketInfo[i].LastName)) && (preEmail === ticketInfo[i].EmailAddress && ticketInfo[i].IsMinor && thisMeOn.val)) {
        arr[i] = { ...arr[i], errLastName: 'Invalid Name' }
        doNotValidate = true
      }

      if (((ticketInfo[i].EmailAddress.trim()) == "")) {

        arr[i] = { ...arr[i], errEmailAddress: 'Required' }

        doNotValidate = true
      } else if (!isValidEmail(ticketInfo[i].EmailAddress.trim())) {
        arr[i] = { ...arr[i], errEmailAddress: 'Invalid Email' }
        doNotValidate = true
      }
      if (!(ticketInfo[0].ConsentSignature) && (thisMeOn.val)) {
        arr[0] = { ...arr[0], errConsentSignature: 'Required' }

        doNotValidate = true
      }
      else if ((ticketInfo[0].ConsentSignature) && (thisMeOn.val) && ticketInfo[0].ConsentSignature != ticketInfo[0].LastName) {
        arr[0] = { ...arr[0], errConsentSignature: 'Signature/Last Name match with the signers (guests) last name' };
        doNotValidate = true
      }
      if (!(ticketInfo[i].ConsentSignature) && ticketInfo[i].IsMinor && i > 0 && ticketInfo[i].EmailAddress == ticketInfo[0].EmailAddress && thisMeOn.val) {
        arr[i] = { ...arr[i], errConsentSignature: 'Read and check here to Agree' }

        doNotValidate = true
      }
      else if ((isValidName(ticketInfo[i].ConsentSignature) && ticketInfo[i].IsMinor && i > 0 && ticketInfo[i].EmailAddress == ticketInfo[0].EmailAddress && thisMeOn.val)) {
        arr[i] = { ...arr[i], errConsentSignature: 'Invalid signature' }
        doNotValidate = true

      }
      else if ((ticketInfo[i].ConsentSignature) && ticketInfo[i].IsMinor && i > 0 && ticketInfo[i].ConsentSignature != ticketInfo[0].LastName
        && ticketInfo[i].EmailAddress == ticketInfo[0].EmailAddress && thisMeOn.val) {
        arr[i] = { ...arr[i], errConsentSignature: '' };
        doNotValidate = true
      }

      if ((ticketInfo[0].ConsentSignedDate) == "" && (thisMeOn.val)) {
        arr[0] = { ...arr[0], errConsentSignedDate: 'Required' }

        doNotValidate = true
      }

      else if (!isValidDate(ticketInfo[0].ConsentSignedDate) && (thisMeOn.val)) {
        arr[0] = { ...arr[0], errConsentSignedDate: 'Invalid date' }
        doNotValidate = true
      }

      if ((ticketInfo[i].ConsentSignedDate) == "" && ticketInfo[i].IsMinor && i > 0 && ticketInfo[i].EmailAddress == ticketInfo[0].EmailAddress && thisMeOn.val) {
        arr[i] = { ...arr[i], errConsentSignedDate: 'Required' }

        doNotValidate = true
      }

      else if (!isValidDate(ticketInfo[i].ConsentSignedDate) && ticketInfo[i].IsMinor && i > 0 && ticketInfo[i].EmailAddress == ticketInfo[0].EmailAddress && thisMeOn.val) {
        arr[i] = { ...arr[i], errConsentSignedDate: 'Invalid date' }
        doNotValidate = true
      }
      debugger
      if (ticketInfo[0].isAgree == false) {
        arr[0] = { ...arr[0], errIsAgree: 'Required' }
        doNotValidate = true

      }
      arr[i] = {
        ...arr[i], ConsentPrintName: arr[i].FirstName ? (arr[i].FirstName + " ") : "" +
          arr[i].MiddleName ? (arr[i].MiddleName + " ") : "" + arr[i].LastName ? (arr[i].LastName + " ") : ""
      }

    }

    let count = 1;

    arr.map((items, index) => {
      if (arr.length > 0 && count == 1) {

        if (items.errFirstName) {
          firstRef.current[index].focus();
          count = 0;
        }
        else if (items.errMiddleName) {
          middleRef.current[index].focus();
          count = 0;
        }
        else if (items.errLastName) {
          lastRef.current[index].focus();
          count = 0;
        }
        else if (items.errEmailAddress) {
          emailRef.current[index].focus();
          count = 0;
        }
        else if (items.errConsentSignature) {
          consentSignatureRef.current[index].focus();
          count = 0;
        }
        else if (items.errIsAgree && thisMeOn.val) {
          isAgreeRef.current[0].focus();
          count = 0;
        }
      }
    })
    setIsNotValid(doNotValidate)
    setTicketInfo(arr)
    return doNotValidate;
  }
  //  Get Ticket Details --- Get Request

  useEffect(() => {
    let path = window.location.search.replace("?", "")

    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?queryparams=${path}`,
      headers: {
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
      }
    };
    axios(config)
      .then(function (response) {


        if (response.data.length && response.data.length > 0) {
          setPreEmail(response.data[0].EmailAddress)
          setResponse(response.data[0]);
          let arr = [];
          response.data.map((tkt) => {
            for (let i = 0; i < response.data[0].NoOfTickets; i++) {
              let oGuest = obj;
              oGuest = { ...oGuest, TicketID: tkt.TicketID, ConfirmationNumber: tkt.ConfirmationNumber };
              if (i == 0)
                oGuest = {
                  ...oGuest, FirstName: tkt.FirstName, MiddleName: tkt.MiddleName, LastName: tkt.LastName,
                  EmailAddress: tkt.EmailAddress, IsMinor: false, ConsentSignedDate: tkt.EventDate,
                  ConsentPrintName: (tkt.FirstName + " " + (tkt.MiddleName ? (tkt.MiddleName + " ") : "") + tkt.LastName), ISGroup: tkt.ISGroup, Slot: tkt.Slot
                };
              arr.push(oGuest)
              setTicketInfo(arr);

            }
          })
          for (let i = 0; i < response.data[0].Messages.length; i++) {

            if (response.data[0].Messages[i].ID == 1001) {
              setBlurbMessage(response.data[0].Messages[i].Message)

            }
            else if (response.data[0].Messages[i].ID == 1008) {
              setBlurbMinorMessage(response.data[0].Messages[i].Message)
            }


          }
          response.NoOfTickets = arr.length;
        }
        else if (response.data.StatusCode && response.data.StatusCode == "1004") {
          navigate('/SuccessfullMessage')
        }
        else if (response.data.StatusCode && eval(response.data.StatusCode) >= 1005) {
          setFormMsg(response.data.Message)
        }
      })
      .catch(function (error) {
        console.log(error)
      })

  }, [])

  const onSubmit = () => {
    if (notValidate()) {

      return;
    }
    if (!notValidate()) {
      let finalArray = [];
      var tktInfoArry = [];
      let confNo = ''; let faCnt = -1;
      ticketInfo.map((guest) => {
        // if (guest.ConsentSignature != '' && guest.ConsentSignedDate != '') {
        //   if (guest.TicketType == "VIP" || guest.TicketType == "Group") {
        //     let value = `${process.env.REACT_APP_WEBHOOKS_ISACTIVE}`
        //     if (value.toLowerCase() == "true") {
        //       webhooks(guest.TicketID, guest.TicketType, guest.FirstName, guest.LastName, guest.EmailAddress)
        //     }
        //   }
        // }
        let tktInfo = {
          FirstName: guest.FirstName, MiddleName: guest.MiddleName, LastName: guest.LastName, EmailAddress: guest.EmailAddress,
          IsMinor: guest.IsMinor, ConsentPrintName: guest.ConsentPrintName,
          ConsentSignature: guest.ConsentSignature, ConsentSignedDate: guest.ConsentSignedDate,
          ISGroup: guest.ISGroup, Slot: guest.Slot

        }
        if (confNo != guest.ConfirmationNumber) {

          if (faCnt >= 0) { finalArray[faCnt].TicketDetails = tktInfoArry; }
          faCnt++;
          tktInfoArry = [];
          confNo = guest.ConfirmationNumber;
          finalArray.push({
            "TicketID": guest.TicketID,
            "ConfirmationNumber": guest.ConfirmationNumber,
            "TicketType": guest.TicketType
          });
          tktInfoArry = [...tktInfoArry, tktInfo]

        }
        else {
          tktInfoArry = [...tktInfoArry, tktInfo];
        }
      })
      finalArray[faCnt].TicketDetails = tktInfoArry;
      const reqData = JSON.stringify(finalArray)
      //insert guest details
      var config = {
        method: 'POST',
        url: `${process.env.REACT_APP_WEBSITE_NAMES}/insertguestdetails`,
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
        },
        data: reqData
      };

      axios(config)
        .then(function (response) {
          if (response.data == 1) {
            /*Added webhooks api for production changes on 06-07-2023*/
            const config = {
              method: 'GET',
              url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?queryparams=""&ConfirmationID=${confNo ? confNo : ''}`,
              headers: {
                'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
              }
            };
            
            axios(config)
              .then(function (response) {
                if(response.data&&response.data.TicketDetails.length>0)
                {
                  response.data.TicketDetails.map((guest)=>{
                        if(guest.ConsentPrintName!=""||guest.ConsentSignature!="")
                        {
                          let value = `${process.env.REACT_APP_WEBHOOKS_ISACTIVE}`
                          if (value.toLowerCase() == "true" && (guest.TicketType == "VIP" || guest.TicketType == "Group")) {
                            webhooks(guest.GuestID, guest.TicketType, guest.FirstName, guest.LastName, guest.EmailAddress)
                          }
                        }
                      })
                }
              })
              .catch(function (error) {
                console.log(error);
              });
            navigate('/SuccessfullMessage')
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  return (
    <React.Fragment>

      <Header></Header>

      <div className="mainWrap mainTopMargin">
        <div className="container">
          {ticketInfo.length > 0 ? <>
            <div className="mainHeadeing">
              <h1>Tour Registration</h1>
            </div>
            <p className="asterick">Note:<span className="asterick-size">' * '</span>Fields that are required must be filled out.</p>

            <div className="alertMessage">
              <p>{blurbMessage}</p>
            </div>

            <section>
              <div className="buyerIndividualBlock">
                <section>
                  <div className="subRow ticketInformationBlock">
                    <div className="rightsubRow">
                      <h2>Guests Type: <span>{response.TicketType}</span></h2>
                    </div>

                    <div className="leftsubRow">
                      <h2 className="ticketInformationLabel">Your Ticket Information:</h2>
                      <h6><i className="fa fa-calendar-check-o" aria-hidden="true"></i> {response.EventDate}</h6>
                      <h6><i className="fa fa-clock-o" aria-hidden="true"></i> {response.Slot}</h6>
                      <h6><i className="fa fa-ticket" aria-hidden="true"></i> {response.NoOfTickets}</h6>

                    </div>
                  </div>

                </section>

                {ticketInfo.map((items, index) => {
                  return (
                    <section>
                      <Card className="card">
                        <div className="card-body">
                          <div className="formBlockDefault">

                            <Col className="col-lg-3 col-md-4 col-sm-6">
                              <div class="form-mb">
                                {index == 0 && (
                                  <Form.Group>
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input thisIsMe" type="checkbox" onChange={(e) => {
                                        handleThisIsMe(e.target.checked, index)
                                      }} value="" id="flexthisismecheckbox" />
                                      <label className="form-check-label" for="flexthisismecheckbox">This is me</label>
                                    </div>
                                  </Form.Group>)
                                }


                                <Col>
                                  {index !== 0 && (
                                    <Form.Group>
                                      <div className="form-check">
                                        <input className="form-check-input"
                                          type="checkbox" onChange={(e) => { handleIsMinor(e.target.checked, index) }} value={items.IsMinor} id={`flexcheckdefault-${index}`} />
                                        <Form.Label className="form-check-label" htmlFor={`flexcheckdefault-${index}`}>Is a Minor (anyone under the age of 18)</Form.Label>
                                      </div>
                                    </Form.Group>
                                  )}
                                </Col>
                                <Col>
                                  {index !== 0 && items.IsMinor && thisMeOn.val && (<div className="form-check form-check-inline"><input className="form-check-input" value={items.IsMinor}
                                    type="checkbox" onChange={(e) => { handleIamParent(e.target.checked, index) }} id="flexCheckDefault2" />
                                    <label className="form-check-label" for="flexCheckDefault2">I am the Parent</label>
                                  </div>
                                  )}
                                </Col>

                              </div>
                            </Col>
                            <div className="individual_mediaLicense minorsInformation">
                              {((IsMinorOn.val && ((IsMinorOn.index === index)) || (IsMinorOn.index && index != 0)) && thisMeOn.val && preEmail === items.EmailAddress) && (<><h3>Required To Be Completed If Guest Is A Minor</h3>
                                <h3 style={{ color: '#00A9CE' }}>Minor's Information</h3></>)}
                            </div>
                            <Row className="row">
                              <Col className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-mb">
                                  <label className="form-label">First Name * <span className="errMsg1">{items.errFirstName}</span></label>
                                  <input type="text" ref={(ref) => (firstRef.current[index] = ref)} className="form-control" placeholder="Enter first name" maxLength="100"
                                    onChange={(e) => { handleFirstName(e.target.value, index) }}
                                    value={(index !== 0 && items.IsMinor && preEmail != items.EmailAddress) ? "" : items.FirstName} disabled={(items.IsMinor && (preEmail != items.EmailAddress || (!thisMeOn.val && preEmail == items.EmailAddress)))} />
                                </div>
                              </Col>

                              <Col className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-mb">
                                  <label className="form-label">Middle Name <span className="errMsg1">{items.errMiddleName}</span></label>
                                  <input type="text" ref={(ref) => (middleRef.current[index] = ref)} className="form-control" placeholder="Enter middle name" maxLength="100"
                                    onChange={(e) => { handleMiddleName(e.target.value, index) }}
                                    value={(index !== 0 && items.IsMinor && preEmail != items.EmailAddress) ? "" : items.MiddleName} disabled={(items.IsMinor && (preEmail != items.EmailAddress || (!thisMeOn.val && preEmail == items.EmailAddress)))} />
                                </div>
                              </Col>

                              <Col className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-mb">
                                  <label className="form-label">Last Name * <span className="errMsg1">{items.errLastName}</span></label>
                                  <input type="text" ref={(ref) => (lastRef.current[index] = ref)} className="form-control" placeholder="Enter last name" maxLength="100"
                                    onChange={(e) => { handleLastName(e.target.value, index) }}
                                    value={(index !== 0 && items.IsMinor && preEmail != items.EmailAddress) ? "" : items.LastName} disabled={(items.IsMinor && (preEmail != items.EmailAddress || (!thisMeOn.val && preEmail == items.EmailAddress)))} />
                                </div>
                              </Col>

                              <Col className="col-lg-3 col-md-4 col-sm-6">
                                <div className="form-mb">
                                  <label className="form-label" >{items.IsMinor ? 'Parent or Legal Guardian Email Address *' : " Email Address *"}<span className="errMsg1">{items.errEmailAddress}</span></label>
                                  <input readOnly={index === 0} disabled={preEmail == items.EmailAddress && thisMeOn.val} type="text" className="form-control" placeholder="Enter email address" maxLength="256"
                                    ref={(ref) => (emailRef.current[index] = ref)}
                                    onChange={(e) => {
                                      if (index === 0) {
                                      } handleEmailAddress(e.target.value, index)
                                    }}
                                    defaultValue={items.EmailAddress} value={items.EmailAddress}
                                    onKeyDown={handleKeyDown} />
                                </div>
                              </Col>

                            </Row>
                            <Row>
                            </Row>

                            {((thisMeOn.val && thisMeOn.index === index) || (items.IsMinor && thisMeOn.val && preEmail === items.EmailAddress)) ?
                              (<div className="individual_mediaLicense" >
                                <Row className="row">
                                  <Col className="col-md-12">
                                    <div className="mb-3">
                                      <h3>SoFi Stadium Tour Media License and Release, Waiver of Liability/Indemnity, and Marketing Consent
                                      </h3>
                                      {items.IsMinor ? <span className="errMsg1">{items.errConsentSignature}</span> : ""}
                                      {(!items.IsMinor) ? <p>By signing my last name below, I    <span className="signingIndividual" >{(index !== 0 && items.IsMinor) ? "" :
                                        (ticketInfo[0].FirstName) + " " + (ticketInfo[0].MiddleName ? (ticketInfo[0].MiddleName + " ") : "") + (ticketInfo[0].LastName)}  </span> acknowledge that
                                        I have had the opportunity to review the <a href="https://www.sofistadium.com/tourswaiver" target="_blank">SoFi Stadium Media License and Release and Waiver of Liability/Indemnity Agreement</a> and that I agree to be bound by all of its terms and conditions.
                                      </p>
                                        : <p><input className="form-check-input thisIsMe" ref={(ref) => (consentSignatureRef.current[index] = ref)} type="checkbox" onChange={(e) => {
                                          handlePrintConsentSignature(e.target.checked, index)
                                        }} value="" id="flexCheckDefault" />
                                          I am the parent or legal guardian of the minor named above.
                                          I have the legal right to consent to and, by signing below, I hereby do consent
                                          in all respects to the terms and conditions of the
                                          <a href="https://www.sofistadium.com/tourswaiver" target="_blank">SoFi Stadium Media License
                                            and Release and Waiver of Liability/Indemnity Agreement</a> and agree that both the minor
                                          and I shall be bound by all of its terms and conditions</p>}
                                    </div>
                                  </Col>
                                  {!items.IsMinor ? <>
                                    <Col className="col-lg-6 col-md-6 col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">{index == 0 ? "Last Name *" : "Signature *"} <span className="errMsg1">{items.errConsentSignature}</span></label>

                                        <input className="signatureControl"
                                          ref={(ref) => (consentSignatureRef.current[index] = ref)}
                                          onChange={(e) => (handlePrintConsentSignature(e.target.value, index))} maxLength="100" />

                                      </div>
                                    </Col>

                                    <Col className="col-lg-4 col-md-6 col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">Date *<span className="errMsg1">{items.errConsentSignedDate}</span></label>
                                        <div className="date datepickerCss" data-date-format="mm-dd-yyyy">
                                          <input readOnly className="form-control" type="text" placeholder="mm-dd-yyyy" value={ticketInfo[0].ConsentSignedDate}
                                            onChange={(e) => { handleConsentSignedDate(e.target.value, index) }} />
                                        </div>
                                      </div>
                                    </Col></> : ""}
                                </Row>
                              </div>)
                              : ""}
                            {thisMeOn.val && index == 0 ? <><div className="onSitetermsandconditions_checkbox ">
                              <div className="onsiteErrmsg">
                                <span className="errMsg1">{ticketInfo[0].errIsAgree}</span>
                              </div>
                              <div className="form-check form-check-inline">

                                <input className="form-check-input" type="checkbox" checked={ticketInfo.length > 0 ? ticketInfo[0].isAgree : false} id="flexCheckDefault3"
                                  ref={(ref) => (isAgreeRef.current[0] = ref)}
                                  onChange={(e) => { checkBoxHandler(e.target.checked) }} />
                                <label className="form-check-label" for="flexCheckDefault3">I agree to the Terms and Privacy Policy</label>
                              </div>
                            </div></> : ""}
                            {(items.IsMinor && thisMeOn.val && items.EmailAddress != preEmail) ? <>
                              <Row className="row">
                                <Col className="col-md-12">
                                  <div className="mb-3">
                                    <>
                                      <p className="blub-minor-message" dangerouslySetInnerHTML={{ __html: blurbMinorMessage }} />
                                    </>
                                  </div>

                                </Col>
                              </Row></> : ""}
                          </div>
                        </div>
                      </Card>
                    </section>
                  )
                })
                }


              </div>

            </section>


            <div className="cardFooter centerCardFooter">
              <Button className="btn btn-info" onClick={onSubmit} >Submit</Button>
            </div>
          </>
            :
            <>
              <center><p dangerouslySetInnerHTML={{ __html: formMsg }} /></center>
            </>
          }
        </div>
      </div>

      {agree && isHide &&
        <Modal centered show className="individualBlock-iframe">
          <div className="modal-content">
            <Modal.Body className="privacy-policy">
              <div>
                <iframe className="privacy-policy-iframe" src="https://urldefense.com/v3/__https:/hollywoodparkca.com/terms-of-use/__;!!P9g1NVG6!pO7V-L-_MFtJ1hwjU5l1tOaOO3W7tzfrZ72HS2sxtBbKWj7aB0UsKjq2fbk9QNJXH6YCvlSPQDxQZU5OlFKthTrSZQpk3A$">
                </iframe>
              </div>
              <div>
                <iframe className="privacy-policy-iframe" src="https://urldefense.com/v3/__https:/hollywoodparkca.com/policies/__;!!P9g1NVG6!pO7V-L-_MFtJ1hwjU5l1tOaOO3W7tzfrZ72HS2sxtBbKWj7aB0UsKjq2fbk9QNJXH6YCvlSPQDxQZU5OlFKthToSXoXHgw$">
                </iframe>
              </div>
              <div className="cardFooter centerCardFooter">
                <><Button className="btn-iagree btn-info" onClick={() => onAccept()} >I  ACCEPT</Button>
                  <Button className="btn-iagree  btn-info" onClick={() => onCancel()} >Cancel</Button>
                </>
              </div>
            </Modal.Body>
          </div>
        </Modal>}

      <Footer></Footer>
    </React.Fragment >
  )
}