import React, { useState, useEffect } from "react";
import logo from '../../assets/images/left-arrow.svg'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import Login from "../../AzureLogin/Login";
import ValidateGraphData from "../../AzureLogin/ValidateGraphAPI";
import GetAccessToken from "../../AzureLogin/GetAccessToken";

export default function Register(props) {
    const [mapData, setMapData] = useState([])
    const [data, setData] = useState()
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    const [paginationArr, setPaginationArr] = useState([]);
    const [pageNoStart, setPageNoStart] = useState(0)
    const [pageNoEnd, setPageNoEnd] = useState(3)
    const location = useLocation();
    const [totalGuest, setTotalGuest] = useState(0)
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [valgraphData, setvalGraphData] = useState(false)

    useEffect(() => {
        let queryparams = window.location.search.replace("?", "")
        const config = {
            method: 'GET',
            url: `${process.env.REACT_APP_WEBSITE_NAMES}/filtertoursticketdata?${queryparams}${'&pageNumber=' + selectedPageNo}`,
            headers: {
                'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
            }
        };

        axios(config)
            .then(function (response) {
                var itemNo = 1;
                var itemArr = [];
                itemNo = Math.ceil(response.data.Pagination.TotalRecords / response.data.Pagination.PageSize)
                for (let i = 1; i <= itemNo; i++) {
                    itemArr.push(i)
                }
                setPaginationArr(itemArr);
                setTotalGuest(response.data.Pagination.TotalRecords)
            })
    }, [])

    useEffect(() => {
        getGuestDetail();
    }, [selectedPageNo])
    const getGuestDetail = () => {
        let queryparams = window.location.search.replace("?", "")
        const config = {
            method: 'GET',
            url: `${process.env.REACT_APP_WEBSITE_NAMES}/filtertoursticketdata?${queryparams}${'&pageNumber=' + selectedPageNo}`,
            headers: {
                'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
            }
        };
        axios(config)
            .then(function (response) {
                if (response.data.Guests != null){
                    var newArr = [];
                response.data.Guests.map(data => {
                    newArr.push({ ...data, isChecked: false })
                })
                setMapData(newArr);
                }
            })
    }
    return (
        <>
            <AuthenticatedTemplate>
                <>
                    {graphData ? (
                        <>
                            {valgraphData ? (
                                <>
                                    <Header></Header>
                                    <div className="mainWrap mainTopMargin todayregWrapp">
                                        <div className="container">
                                            <div className="mainHeadeing">
                                                <h1> <Link to={"/Admin"} className="leftArrowLink"  > <img src={logo} alt="left arrow" /> </Link>{data}</h1>
                                            </div>


                                            <>
                                                <div className="table-responsive">
                                                    <table className="table  align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th>Confirmation ID </th>
                                                                <th>Group Name</th>
                                                                <th>Name</th>
                                                                <th>Email Address</th>
                                                                <th>No of Tickets  </th>
                                                                <th>Waiver Status </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mapData.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.ConfirmationNumber}</td>
                                                                        <td>{item.GroupName}</td>
                                                                        <td>{item.Name}</td>
                                                                        <td>{item.EmailAddress}</td>
                                                                        <td>{item.NoOfTickets}</td>

                                                                        <td>{(item.WaiverStatus == "2") ? "Paper" : ((item.WaiverStatus == "0") ? "Pending" : "Completed")}</td>

                                                                    </tr>
                                                                )

                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>

                                                <nav aria-label="Page navigation example" className="pull-right">
                                                    <ul className="pagination" onClick={(e) => {
                                                        setSelectedPageNo(Number(e.target.value==0?selectedPageNo:e.target.value));
                                                    }}>

                                                        <li className="page-item active page=" class="page-link">
                                                            <button
                                                                onClick={() => {
                                                                    if (pageNoStart !== 0) {

                                                                        setPageNoStart(pageNoStart - 1)
                                                                        setPageNoEnd(pageNoEnd - 1)
                                                                    }
                                                                }}
                                                                value={selectedPageNo - 1} disabled={selectedPageNo <= 1} style={{ border: "none" }}>Previous</button>
                                                        </li>
                                                        {paginationArr.slice(pageNoStart, pageNoEnd).map((item, index) => {
                                                            return (
                                                                <>
                                                                    <li value={item}
                                                                        className="page-item active" class={selectedPageNo==item?"page-link pagination-color":"page-link"}>
                                                                        {item}
                                                                    </li>
                                                                </>
                                                            )
                                                        })}
                                                        {paginationArr.length > pageNoEnd && <li className="page-item active" class="page-link">...</li>}

                                                        <li className="page-item active" class="page-link">
                                                            <button value={selectedPageNo + 1}
                                                                onClick={() => {
                                                                    if (paginationArr.length > pageNoEnd) {
                                                                        setPageNoStart(pageNoStart + 1)
                                                                        setPageNoEnd(pageNoEnd + 1)
                                                                    }
                                                                }}
                                                                disabled={selectedPageNo >= paginationArr.length} style={{ border: "none" }}>Next</button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </>
                                        </div>
                                    </div>
                                    <Footer></Footer>
                                </>
                            ) : (<ValidateGraphData setvalGraphData={setvalGraphData} graphData={graphData} />)}
                        </>
                    ) : (<GetAccessToken setGraphData={setGraphData} graphData={graphData} account={accounts} instance={instance} />)}
                </>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </>
    )
}