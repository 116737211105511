import React, { useState } from "react";
import { Card, Row, Col, Form } from 'react-bootstrap';


export default function GroupRegistrationForm(props) {
  const [disable, setDisable] = useState(false);

  const { setTicketInfo, items, index, ticketInfo, slots, formMsg, firstRef, middleRef, lastRef, emailRef, slotRef } = props
  const handleFirstName = (val) => {
    val = val
    let arr = [...ticketInfo]
    arr[index] = { ...arr[index], FirstName: val, errFirstName: '' }
    setTicketInfo(arr)

  }
  const handleLastName = (val) => {
    val = val
    let arr = [...ticketInfo]
    arr[index] = { ...arr[index], LastName: val, errLastName: '' }
    setTicketInfo(arr)

  }
  const handleMiddleName = (val) => {
    val = val
    let arr = [...ticketInfo]
    arr[index] = { ...arr[index], MiddleName: val, errMiddleName: '' }
    setTicketInfo(arr)

  }
  const handleEmail = (val) => {
    val = val.trim()
    let arr = [...ticketInfo]
    arr[index] = { ...arr[index], EmailAddress: val, Guardian: val, errEmailAddress: '', errMiddleName: '' }
    setTicketInfo(arr)
  }
  const handleIsMinor = (val) => {
    let arr = [...ticketInfo]

    arr[index] = { ...arr[index], IsMinor: val, errFirstName: '', errLastName: "", errMiddleName: '' }
    setTicketInfo(arr)
    setDisable(val)
  }
  const handleSlotChange = (val) => {
    debugger;
    let arr = [...ticketInfo]
    arr[index] = { ...arr[index], Slot: val }
    setTicketInfo(arr)

  }
  const handleisPaper = (val) => {
    let arr = [...ticketInfo]
    if (val == true) {
      arr[index] = { ...arr[index], PaperWaiver: 2 }
    }
    else {
      arr[index] = { ...arr[index], PaperWaiver: 0 }
    }
    setTicketInfo(arr)
    setDisable(val)

  }
  const handleTimeChange = (event) => {
    debugger;
    let arr=[...ticketInfo]
    const inputTime = event.target.value;
    const colonAddedTime = insertColonAfterHour(inputTime);
    arr[index] = { ...arr[index], Slot: colonAddedTime, errSlot: '' }
    setTicketInfo(arr)
  };

  const insertColonAfterHour = (timeStr) => {
    const cleanedTime = timeStr.replace(/[^\d]/g, '');
    if (cleanedTime.length > 2) {
      const hour = cleanedTime.slice(0, 2);
      const minute = cleanedTime.slice(2);
      return `${hour}:${minute}`;
    }

    return cleanedTime;
  };
  const handleKeyDown = e => {
		if (e.key === " ") {
			e.preventDefault();
		}
	};
  return (
    ticketInfo.length > 0 ?
      <>
        <div className="guestCount">{index + 1}</div>
        <div>
          <Card className="card">
            <div className="card-body">
              <Form className="formBlockDefault" >
                <Row className="row">
                  <Col className="col-lg-3 col-md-4 col-sm-6">
                    <Form.Group className="form-mb">
                      <div class="form-check form-check-inline noLabel">
                        <input class="form-check-input" type="checkbox" onChange={(e) => { handleIsMinor(e.target.checked) }} checked={items.IsMinor} disabled={index == 0 ? true : false} id={`flexcheckdefault-${index}`} />
                        <Form.Label class="form-check-label" htmlFor={`flexcheckdefault-${index}`} >Is a Minor (anyone under 18 years old)</Form.Label >
                      </div>
                    </Form.Group>

                  </Col>
                  <Col className=" col-lg-3 col-md-3 col-sm-6">
                    <Form.Group className="form-mb">
                      <Form.Text></Form.Text>
                      <Form.Label className="form-label">First Name *<span className="errMsg1">{items.errFirstName}</span></Form.Label>
                      <Form.Control
                        ref={(ref) => (firstRef.current[index] = ref)} onChange={(e) => { handleFirstName(e.target.value) }} type="text" className="form-control" value={items.FirstName} disabled={items.IsMinor} placeholder="Enter first name" maxLength="100"></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col className="col-lg-3 col-md-3 col-sm-2">
                    <Form.Group className="form-mb">
                      <Form.Label className="form-label">Middle Name <span className="errMsg1">{items.errMiddleName}</span></Form.Label>
                      <Form.Control ref={(ref) => (middleRef.current[index] = ref)} onChange={(e) => { handleMiddleName(e.target.value) }} type="text" className="form-control" value={items.MiddleName} disabled={items.IsMinor} placeholder="Enter middle name" maxLength="100"></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col className="col-lg-3 col-md-3 col-sm-6">
                    <Form.Group className="form-mb">
                      <Form.Label className="form-label">Last Name *<span className="errMsg1">{items.errLastName}</span></Form.Label>
                      <Form.Control ref={(ref) => (lastRef.current[index] = ref)} onChange={(e) => { handleLastName(e.target.value) }} type="text" className="form-control" value={items.LastName} disabled={items.IsMinor} placeholder="Enter last name" maxLength="100"></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>


                <br />

                <Row className="row">
                  <Col className="col-lg-3 col-md-3 col-sm-6">
                    <Form.Group className="form-mb">
                      <Form.Label className="form-label">{items.IsMinor ? "Legal Guardian Email Address" : "Email Address"}  * <span className="errMsg1">{items.errEmailAddress}</span></Form.Label>
                      <Form.Control ref={(ref) => (emailRef.current[index] = ref)} readOnly={index == 0} onChange={(e) => { handleEmail(e.target.value) }} type="text" className="form-control" value={items.EmailAddress} placeholder="Enter email address" maxLength="256" onKeyDown={handleKeyDown}></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-mb">
                      <Form.Label className="form-label">Slot * (24-hour format)<span className="errMsg1" >{items.errSlot}</span></Form.Label>
                      <input
													ref={(ref) => (slotRef.current[index] = ref)}
													className="form-control slot"
													type="text"
													id="timeInput"
													value={items.Slot}
													onChange={handleTimeChange}
													placeholder="HH:MM"
													maxLength="5" // Restrict input to 5 characters (e.g., "hh:mm")
													pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
												/>
                      {/* <Form.Control onChange={(e) => { handleSlotChange(e.target.value) }} pattern="[0-9]{2}:[0-9]{2}"
                        ref={(ref) => (slotRef.current[index] = ref)} type="time" className="form-control" value={items.Slot}>
                      </Form.Control> */}
                    </div>
                  </Col>
                  <Col className="col-lg-3 col-md-4 col-sm-6">
                    <Form.Group className="form-mb">
                      <Form.Label className="form-label">Waiver Type</Form.Label>
                      <div class="form-check form-check-inline">
                        <label class="form-check-label" htmlFor={`flexcheckpaperdefault-${index}`}>Paper</label>
                        <input class="form-check-input" type="checkbox" onChange={(e) => { handleisPaper(e.target.checked) }} checked={items.PaperWaiver} id={`flexcheckpaperdefault-${index}`} />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
        </div>

      </> :
      <>
        <center><p dangerouslySetInnerHTML={{ __html: formMsg }} /></center>
      </>
  )
}
