import React, { useEffect, useState, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router";
import { isValidDate, isValidEmail, isValidName } from "../utilities";
import { useNavigate } from "react-router";
import { First } from "react-bootstrap/esm/PageItem";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { webhooks } from "../WebHooksApi/webhooks";

export default function OnsiteRegistration() {
	const currentdate = new Date()
	let date = moment(currentdate).format("MM-DD-YYYY")
	const ShowTime = moment().format("h:mm A")
	const location = useLocation();
	const [response, setResponse] = useState({});
	const [ticketInfo, setTicketInfo] = useState([]);
	const [guestNumber, setGuestNumber] = useState(1)
	const navigate = useNavigate()
	const [isShow, setIsShow] = useState(false);
	const [formMsg, setFormMsg] = useState("<br/><br/><h1>Loading...</h1>");
	const firstRef = useRef([]);
	const lastRef = useRef([]);
	const middleRef = useRef([]);
	const emailRef = useRef([]);
	const dateRef = useRef([]);
	const isAgreeRef = useRef([]);
	const consentSignatureRef = useRef([])
	const [acceptAgree, setAcceptAgree] = useState(false);
	const [agree, setAgree] = useState(false);
	const [isHide, setisHide] = useState(false);


	useEffect(() => {
		window.history.pushState(null, null, location.href)
		window.onpopstate = function (event) {
			window.history.go(1);
		};
	}, [])
	const handleMinorYes = (val) => {

		let arr = [...ticketInfo]
		if ({ ...arr[guestNumber - 1], IsMinor: val }) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errFirstName: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errLastName: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errMiddleName: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errEmailAddress: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentPrintName: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignature: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignedDate: '' }

		}
		arr[guestNumber - 1] = { ...arr[guestNumber - 1], IsMinor: val, FirstName: '', MiddleName: '', LastName: '', EmailAddress: '', ConsentSignature: '' }
		for (let i = 0; i < arr.length; i++) {
			if (i >= guestNumber - 1) { break; }
			if (arr[i].EmailAddress == arr[guestNumber - 1].EmailAddress) {
				arr[guestNumber - 1] = { ...arr[guestNumber - 1] }

				break;
			}
		}
		setTicketInfo(arr)
		setIsShow(true)
	}
	const handleMinorNo = (val) => {
		let arr = [...ticketInfo]
		if ({ ...arr[guestNumber - 1], IsMinor: val }) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errFirstName: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errLastName: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errMiddleName: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errEmailAddress: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentPrintName: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignature: '' }
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignedDate: '' }
		}
		arr[guestNumber - 1] = {
			...arr[guestNumber - 1], IsMinor: !val, FirstName: '', MiddleName: '', LastName: '',
			EmailAddress: '', ConsentSignature: '', ConsentPrintName: arr[guestNumber - 1].FirstName + " " +
				(arr[guestNumber - 1].MiddleName ? (arr[guestNumber - 1].MiddleName + " ") : "") + arr[guestNumber - 1].LastName
		}

		setTicketInfo(arr)
		setIsShow(false)
	}
	const obj = {
		"TicketID": "",
		"GuestID": "",
		"ConfirmationNumber": "",
		"TicketType": "",
		"FirstName": "",
		"MiddleName": "",
		"LastName": "",
		"EmailAddress": "",
		"Guardian": "",
		"IsMinor": false,
		"Slot": "",
		"WaiverStatus": location?.state?.ticketDetails?.WaiverStatus ? location.state.ticketDetails.WaiverStatus : false,
		"Active": true,
		"EventDate": location?.state?.ticketDetails?.EventDate ? location.state.ticketDetails.EventDate : "",
		"ConsentPrintName": "",
		"ConsentSignature": "",
		"ConsentSignedDate": "",
		"errFirstName": "",
		"errLastName": "",
		"errMiddleName": "",
		"errConsentPrintName": "",
		"errConsentSignature": "",
		"errConsentSignedDate": "",
		"errAgree": "",
		"Slot": "",
		"CheckedConsentSignature": "",
		"isAgree": false,
		"isDisabled": false
	}

	useEffect(() => {
		let arr = [];
		var count = 0;
		let oGuest = obj;
		if (location.state && location.state.length && location.state.length > 0) {
			location.state.map((tkt) => {
				response.EventDate = tkt.EventDate
				response.Slot = tkt.Slot
				for (let i = 0; i < tkt.NoofTickets; i++) {
					let oGuest = obj;
					oGuest = {
						...oGuest, TicketID: tkt.TicketID, ConfirmationNumber: tkt.ConfirmationNumber,
						ConsentSignedDate: date, IsMinor: false,
					};
					if (i == 0)
						oGuest = {
							...oGuest, FirstName: tkt.FirstName, MiddleName: tkt.MiddleName,
							LastName: tkt.LastName, EmailAddress: tkt.EmailAddress, IsMinor: false, Slot: response.Slot,
							ConsentSignedDate: date, ConsentPrintName: tkt.FirstName + " " + (tkt.MiddleName != "" ? (tkt.MiddleName + " ") : "") + tkt.LastName
						};
					else
						oGuest = {
							...oGuest, Slot: response.Slot
						};
					arr.push(oGuest)
				}

			})
		}
		else if (location.state && location.state.StatusCode && location.state.StatusCode == "1004") {
			navigate('/SuccessfullMessage')
		}
		else if (location.state && location.state.StatusCode && eval(location.state.StatusCode) >= 1005) {
			setFormMsg(location.state.Message)
		}

		if (location.state) {
			debugger;
			if (location.state.TicketDetails && location.state.TicketDetails.length > 0) {
				location.state.TicketDetails.map((tkt) => {
					count = count + 1;
					oGuest = {
						...oGuest, TicketID: tkt.TicketID, ConfirmationNumber: tkt.ConfirmationNumber,
						ConsentSignedDate: tkt.ConsentSignedDate, IsMinor: tkt.IsMinor
					};
					oGuest = {
						...oGuest, GuestID: tkt.GuestID, FirstName: tkt.FirstName, MiddleName: tkt.MiddleName,
						LastName: tkt.LastName, EmailAddress: tkt.EmailAddress, Slot: response.Slot, ConsentSignature: tkt.ConsentSignature,
						ConsentSignedDate: tkt.ConsentSignedDate, ConsentPrintName: tkt.FirstName + " " + (tkt.MiddleName != "" ? (tkt.MiddleName + " ") : "") + tkt.LastName

					};
					arr.push(oGuest)
				});
			}
			if (location.state.NoofTickets && location.state.NoofTickets.length > 0) {
				for (let i = count + 1; i <= location.state.NoofTickets; i++) {
					oGuest = {
						...oGuest, GuestID: '', FirstName: '', MiddleName: '',
						LastName: '', EmailAddress: '', IsMinor: false, Slot: response.Slot,
						ConsentSignedDate: '', ConsentPrintName: ''
					}
					arr.push(oGuest)
				}
			}
			if (arr.length > 0) {
				arr.sort((a, b) => {
					if (a.IsMinor && !b.IsMinor) { return 1; }
					if (b.IsMinor && !a.IsMinor) { return -1; }
					return 0;
				});
			}
		}
		setTicketInfo(arr);
		response.NoOfTickets = arr.length;
	}, [])
	const handleFirstName = (val) => {
		let arr = [...ticketInfo]
		arr[guestNumber - 1
		] = {
			...arr[guestNumber - 1
			], FirstName: val, ConsentPrintName: arr[guestNumber - 1].FirstName +
				+" " + (arr[guestNumber - 1].MiddleName != "" ? (arr[guestNumber - 1].MiddleName + " ") : "")
				+ arr[guestNumber - 1].LastName, errFirstName: ''

		}

		setTicketInfo(arr)
	}
	const handleMiddleName = (val) => {
		let arr = [...ticketInfo]
		arr[guestNumber - 1] = {
			...arr[guestNumber - 1], MiddleName: val,
			ConsentPrintName: arr[guestNumber - 1].FirstName
				+ " " + (arr[guestNumber - 1].MiddleName != "" ? (arr[guestNumber - 1].MiddleName + " ") : "")
				+ arr[guestNumber - 1].LastName, errMiddleName: ''
		}
		setTicketInfo(arr)
	}
	const handleLastName = (val) => {
		let arr = [...ticketInfo]
		arr[guestNumber - 1] = {
			...arr[guestNumber - 1], LastName: val,
			ConsentPrintName: arr[guestNumber - 1].FirstName +
				+" " + (arr[guestNumber - 1].MiddleName != "" ? (arr[guestNumber - 1].MiddleName + " ") : " ")
				+ arr[guestNumber - 1].LastName, errMiddleName: '',
			errLastName: ''

		}

		setTicketInfo(arr)
	}
	const handleEmailAddress = (val) => {
		let arr = [...ticketInfo]
		arr[guestNumber - 1] = { ...arr[guestNumber - 1], EmailAddress: val, errEmailAddress: '' }
		for (let i = 0; i < arr.length; i++) {
			if (i >= guestNumber - 1) { break; }
			if (arr[i].EmailAddress == val) {
				arr[guestNumber - 1] = {
					...arr[guestNumber - 1],
					ConsentPrintName: arr[i].FirstName + " " + (arr[i].MiddleName != "" ? (arr[i].MiddleName + " ") : "") + arr[i].LastName
				}

				break;
			}
		}
		setTicketInfo(arr)
	}

	const handleConsentSignature = (val) => {
		let arr = [...ticketInfo]
		arr[guestNumber - 1] = {
			...arr[guestNumber - 1], ConsentSignature: val == true ? arr[guestNumber - 1].LastName : val,
			CheckedConsentSignature: val == true ? val : false,
			errConsentSignature: ''
		}
		setTicketInfo(arr)
	}
	const handleConsentPrintDate = (val) => {
		debugger;
		let arr = [...ticketInfo]
		arr[guestNumber - 1] = { ...arr[guestNumber - 1], ConsentSignedDate: val, errConsentSignedDate: '' }
		setTicketInfo(arr)
	}
	const formNotValid = () => {
		debugger;
		let doNotValidate = false
		let arr = [...ticketInfo]
		arr[guestNumber - 1] = {
			...arr[guestNumber - 1], errFirstName: "", errLastName: "",
			errConsentPrintName: "", errEmailAddress: "", errConsentPrintName: "", errConsentSignature: "", errConsentSignedDate: ""
		}
		if ((ticketInfo[guestNumber - 1].FirstName).trim() == "") {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errFirstName: 'Required' }

			doNotValidate = true
		}
		else if ((isValidName(ticketInfo[guestNumber - 1].FirstName))) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errFirstName: 'Invalid Name' }
			doNotValidate = true

		}
		if (ticketInfo[guestNumber - 1].MiddleName == "" || ticketInfo[guestNumber - 1].MiddleName == null) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errMiddleName: '' }

		}
		else if (isValidName(ticketInfo[guestNumber - 1].MiddleName)) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errMiddleName: 'Invalid Name' }
			doNotValidate = true
		}
		if ((ticketInfo[guestNumber - 1].LastName).trim() == "") {

			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errLastName: 'Required' }

			doNotValidate = true
		}
		else if ((isValidName(ticketInfo[guestNumber - 1].LastName))) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errLastName: 'Invalid Name' }
			doNotValidate = true
		}

		if (((ticketInfo[guestNumber - 1].EmailAddress).trim() == "")) {

			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errEmailAddress: 'Required' }

			doNotValidate = true
		} else if (!isValidEmail((ticketInfo[guestNumber - 1].EmailAddress).trim())) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errEmailAddress: 'Invalid Email' }
			doNotValidate = true
		}

		if (!(ticketInfo[guestNumber - 1].ConsentSignature) && !ticketInfo[guestNumber - 1].IsMinor) {

			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignature: 'Required' }

			doNotValidate = true
		}
		else if (!(ticketInfo[guestNumber - 1].CheckedConsentSignature) && ticketInfo[guestNumber - 1].IsMinor) {

			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignature: 'Read and check here to Agree' }

			doNotValidate = true
		}

		else if ((isValidName(ticketInfo[guestNumber - 1].ConsentSignature) && !ticketInfo[guestNumber - 1].IsMinor)) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignature: 'Invalid Signature' }
			doNotValidate = true


		}
		else if ((ticketInfo[guestNumber - 1].ConsentSignature)) {
			if ((ticketInfo[guestNumber - 1].ConsentSignature != ticketInfo[guestNumber - 1].LastName) &&
				!ticketInfo[guestNumber - 1].IsMinor) {
				arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignature: 'Signature/Last Name match with the signers (guests) last name' }

				doNotValidate = true
			}
		}


		if (ticketInfo[guestNumber - 1] && ticketInfo[guestNumber - 1].ConsentSignedDate == "") {
			debugger;
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignedDate: 'Required' }

			doNotValidate = true
		}
		else if (!isValidDate(ticketInfo[guestNumber - 1].ConsentSignedDate)) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errConsentSignedDate: 'Invalid Date' }

			doNotValidate = true
		}

		if (ticketInfo[guestNumber - 1].isAgree == false && ticketInfo[guestNumber - 1].IsMinor == false) {
			arr[guestNumber - 1] = { ...arr[guestNumber - 1], errAgree: 'Required' }
			doNotValidate = true
		}

		setTicketInfo(arr)

		let count = 1;

		arr.map((items, index) => {
			if (arr.length > 0 && count == 1) {

				if (items.errFirstName && [guestNumber - 1] == index) {
					firstRef.current[index].focus();
					count = 0;
				}
				else if (items.errMiddleName && [guestNumber - 1] == index) {
					middleRef.current[index].focus();
					count = 0;
				}
				else if (items.errLastName && [guestNumber - 1] == index) {
					lastRef.current[index].focus();
					count = 0;
				}
				else if (items.errEmailAddress && [guestNumber - 1] == index) {
					emailRef.current[index].focus();
					count = 0;
				}
				else if (items.errConsentSignature && [guestNumber - 1] == index) {
					consentSignatureRef.current[index].focus();
					count = 0;
				}
				else if (items.errConsentSignedDate && [guestNumber - 1] == index) {
					dateRef.current[index].focus();
					count = 0;
				}
				else if (items.errAgree && [guestNumber - 1] == index) {
					isAgreeRef.current[index].focus();
					count = 0
				}
			}
		})

		return doNotValidate
	}

	const handleKeyDown = e => {
		if (e.key === " ") {
			e.preventDefault();
		}
	};
 

	useEffect(() => {
		if (isShow) {
			if (ticketInfo.length > 0) {
				let arr = [...ticketInfo]
				arr[guestNumber - 1] = { ...arr[guestNumber - 1], LastName: "", FirstName: "" }
				setTicketInfo(arr)
			}

		}
		else {
			if (ticketInfo.length > 0 && guestNumber === 1) {
				let arr = [...ticketInfo]
				arr[guestNumber - 1] = {
					...arr[guestNumber - 1], LastName: location.state[0].LastName,
					FirstName: location.state[0].FirstName
				}
				setTicketInfo(arr)
			}
		}


	}, [isShow])


	/*****  Handle Submit *****/
	const handleSubmit = () => {
		if (formNotValid()) return
		let finalArray = []
		var tktInfoArry = [];
		let confNo = ''; let faCnt = -1;
		ticketInfo.map((guest) => {

			let tktInfo = {
				GuestID: guest.GuestID, FirstName: guest.FirstName, MiddleName: guest.MiddleName, LastName: guest.LastName,
				EmailAddress: guest.EmailAddress,
				IsMinor: guest.IsMinor, ConsentPrintName: guest.FirstName + " " + (guest.MiddleName ? (guest.LastName + " ") : "") + guest.LastName,
				ConsentSignature: guest.ConsentSignature, ConsentSignedDate: guest.ConsentSignedDate, Slot: guest.Slot, CheckedConsentSignature: guest.CheckedConsentSignature
			}

			if (confNo != guest.ConfirmationNumber) {

				if (faCnt >= 0) { finalArray[faCnt].TicketDetails = tktInfoArry; }
				faCnt++;
				tktInfoArry = [];
				confNo = guest.ConfirmationNumber;
				finalArray.push({
					"TicketID": guest.TicketID,
					"ConfirmationNumber": guest.ConfirmationNumber,
					"TicketType": guest.TicketType
				});
				tktInfoArry = [...tktInfoArry, tktInfo];

			} else {
				tktInfoArry = [...tktInfoArry, tktInfo];
			}

		})
		finalArray[faCnt].TicketDetails = tktInfoArry;
		const reqData = JSON.stringify(finalArray)

		// update details - Post Request
		var config = {
			method: 'POST',
			url: `${process.env.REACT_APP_WEBSITE_NAMES}/insertguestdetails`,
			headers: {
				'Content-Type': 'application/json',
				'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`

			},
			data: reqData
		};

		axios(config)
			.then(function (response) {
				if (response.data == 1) {
					/*Added webhooks api for production changes on 06-07-2023*/
					finalArray.map((confno) => {
						const config = {
							method: 'GET',
							url: `${process.env.REACT_APP_WEBSITE_NAMES}/gettoursticketdetailsbyid?queryparams=""&ConfirmationID=${confno.ConfirmationNumber ? confno.ConfirmationNumber : ''}`,
							headers: {
								'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
							}
						};

						axios(config)
							.then(function (response) {
								if (response.data && response.data.TicketDetails.length > 0) {
									response.data.TicketDetails.map((guest) => {
										if (guest.ConsentPrintName != "" || guest.ConsentSignature != "") {
											let value = `${process.env.REACT_APP_WEBHOOKS_ISACTIVE}`
											if (value.toLowerCase() == "true" && (guest.TicketType == "VIP" || guest.TicketType == "Group")) {
												webhooks(guest.GuestID, guest.TicketType, guest.FirstName, guest.LastName, guest.EmailAddress)
											}
										}
									})
								}
							})
							.catch(function (error) {
								console.log(error);
							});
					})

					navigate('/SuccessfullMessage', { state: ticketInfo[0].ConfirmationNumber })
				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}
	const handleNextClick = () => {
		if (formNotValid()) return
		setGuestNumber((old) => { return old + 1 })

	}
	/*Newly added for accepting terms and conditions on 05-02-2023*/
	const onAccept = () => {
		setisHide(false)
		setAcceptAgree(true);
	}
	const onCancel = () => {
		setisHide(false);
		setAcceptAgree(false);
		let arr = [...ticketInfo]
		arr[guestNumber - 1] = { ...arr[guestNumber - 1], isAgree: false, errAgree: '' }
		setTicketInfo(arr);
	}

	/**/
	const checkBoxHandler = (val) => {
		if (val == true) {
			if (ticketInfo.length > 0) {
				let arr = [...ticketInfo]
				arr[guestNumber - 1] = { ...arr[guestNumber - 1], isAgree: val, errAgree: '' }
				setTicketInfo(arr)
				setAgree(val)
				setisHide(val);
			}
		}
		else {
			if (ticketInfo.length > 0) {
				let arr = [...ticketInfo]
				arr[guestNumber - 1] = { ...arr[guestNumber - 1], isAgree: val, errAgree: '' }
				setTicketInfo(arr)
				setAgree(val)
				setisHide(val);
			}
		}


	}
	return (
		<>
			<Header></Header>
			<div className="mainWrap mainTopMargin">
				<div className="container">
					{ticketInfo.length > 0 ? <>
						<div className="mainHeadeing">
							<h1>My Tour Registration</h1>
						</div>
						<p className="asterick">Note:<span className="asterick-size">' * '</span>Fields that are required must be filled out.</p>

						<div className="subRow ticketInformationBlock">
							<div className="rightsubRow">
								<h2>Guests - <span>{guestNumber}/{response.NoOfTickets}</span></h2>
							</div>

							<div className="leftsubRow">
								<h2 className="ticketInformationLabel">Your Ticket Information:</h2>
								<h6><i className="fa fa-calendar-check-o" aria-hidden="true"></i> {response.EventDate}</h6>
								<h6><i className="fa fa-clock-o" aria-hidden="true"></i> {response.Slot}</h6>
								<h6><i className="fa fa-ticket" aria-hidden="true"></i> {response.NoOfTickets}</h6>
							</div>
						</div>
						<div className="individualBlock">
							<Card className="card">
								<div className="card-body">
									<div className="formBlockDefault">
										<Row className="row">
											<Col className="col-sm-12">
												<div className="mb-3">
													<label className="form-label">Minor *</label>
													<div className="form-check form-check-inline">
														<input className="form-check-input" disabled={guestNumber === 1} type="radio" name="inlineRadioOptions" id="inlineRadioYes"
															checked={ticketInfo.length > 0 ? (ticketInfo[guestNumber - 1].IsMinor == true ? true : false) : false} onChange={(e) => handleMinorYes(e.target.checked)} />
														<label className="form-check-label" for="inlineRadioYes">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadioNo"
															checked={ticketInfo.length > 0 ? (ticketInfo[guestNumber - 1].IsMinor == true ? false : true) : true}
															onChange={(e) => handleMinorNo(e.target.checked)} />
														<label className="form-check-label" for="inlineRadioNo">No</label>
													</div>
												</div>
											</Col>

										</Row>

										<Row className="row">
											<div className="individual_mediaLicense minorsInformation">
												{(ticketInfo.length > 0 && ticketInfo[guestNumber - 1].IsMinor) ? (<><h3>Required To Be Completed If Guest Is A Minor</h3>
													<h3 style={{ color: '#00A9CE' }}>Minor's Information</h3></>) : ""}
											</div>
											<Col className="col-lg-3 col-md-4 col-sm-6">
												<div className="form-mb">
													<label className="form-label">First Name *<span className="errMsg1"> {ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].errFirstName : ""}</span></label>
													<input type="text" ref={(ref) => (firstRef.current[guestNumber - 1] = ref)} value={ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].FirstName : ""} onChange={(e) => { handleFirstName(e.target.value) }} className="form-control" placeholder="Enter first name" maxLength="100" />
												</div>
											</Col>

											<Col className="col-lg-3 col-md-4 col-sm-6">
												<div className="form-mb">
													<label className="form-label">Middle Name <span className="errMsg1"> {ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].errMiddleName : ""}</span></label>
													<input type="text" ref={(ref) => (middleRef.current[guestNumber - 1] = ref)} value={ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].MiddleName : ""} onChange={(e) => { handleMiddleName(e.target.value) }}
														className="form-control" placeholder="Enter middle name" maxLength="100" />
												</div>
											</Col>

											<Col className="col-lg-3 col-md-4 col-sm-6">
												<div className="form-mb">
													<label className="form-label">Last Name *<span className="errMsg1"> {ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].errLastName : ""}</span></label>
													<input type="text" ref={(ref) => (lastRef.current[guestNumber - 1] = ref)} value={ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].LastName : ""} onChange={(e) => { handleLastName(e.target.value) }} className="form-control" placeholder="Enter last name" maxLength="100" />
												</div>
											</Col>

											<Col className="col-lg-3 col-md-4 col-sm-6">
												<div className="form-mb">
													<label className="form-label">{isShow ? 'Parent or Legal Guardian Email Address *' : " Email Address *"}<span className="errMsg1"> {ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].errEmailAddress : ""}</span></label>
													<input readOnly={guestNumber === 1} ref={(ref) => (emailRef.current[guestNumber - 1] = ref)} type="text" value={ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].EmailAddress : ""} onChange={(e) => { handleEmailAddress(e.target.value) }} className="form-control" placeholder="Enter email Address" maxLength="256" onKeyDown={handleKeyDown} />
												</div>
											</Col>
										</Row>

										<div className="individual_mediaLicense" >
											<Row className="row">
												<Col className="col-md-12">
													<div className="mb-3">
														<h3>SoFi Stadium Tour Media License and Release, Waiver of Liability/Indemnity, and Marketing Consent
														</h3>
														{(ticketInfo.length > 0 && ticketInfo[guestNumber - 1].IsMinor) ? <>
															<span className="errMsg1"> {ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].errConsentSignature : ""}</span>
															<p><input ref={(ref) => (consentSignatureRef.current[guestNumber - 1] = ref)} className="form-check-input thisIsMe" type="checkbox" onChange={(e) => {
																handleConsentSignature(e.target.checked)
															}} checked={ticketInfo.length > 0 ? (ticketInfo[guestNumber - 1].CheckedConsentSignature == true ? true : false) : false} id="flexCheckDefault" />
																I am the parent or legal guardian of the minor named above.
																I have the legal right to consent to and, by signing below, I hereby do consent
																in all respects to the terms and conditions of the
																{""} <a href="https://www.sofistadium.com/tourswaiver" target="_blank">SoFi Stadium Media License and Release and Waiver of Liability/Indemnity Agreement</a> and agree that both the minor
																{""} and I shall be bound by all of its terms and conditions</p></> :

															<p>By signing my last name below, I  <span className="signingIndividual"> {ticketInfo.length > 0 ? (ticketInfo[guestNumber - 1].FirstName + " " +
																(ticketInfo[guestNumber - 1].MiddleName ? (ticketInfo[guestNumber - 1].MiddleName + " ") : "") + ticketInfo[guestNumber - 1].LastName) : ""}  </span>
																acknowledge that I have had the opportunity to review the  {""}
																<a href="https://www.sofistadium.com/tourswaiver" target="_blank"> SoFi Stadium Media License and Release and Waiver of Liability/Indemnity Agreement</a>
																{""} and that I agree to be bound by all of its terms and conditions.
															</p>}
													</div>
												</Col>

												{(ticketInfo.length > 0 && !ticketInfo[guestNumber - 1].IsMinor) && <>
													<Col className="col-lg-4 col-md-6 col-sm-6">
														<div className="mb-3">
															<label className="form-label">Last Name * <span className="errMsg1"> {ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].errConsentSignature : ""}</span></label>
															<input ref={(ref) => (consentSignatureRef.current[guestNumber - 1] = ref)} className="signatureControl" value={ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].ConsentSignature ? ticketInfo[guestNumber - 1].ConsentSignature : "" : ""} onChange={(e) => { handleConsentSignature(e.target.value) }} maxLength="100" />
														</div>
													</Col>

													<Col className="col-lg-4 col-md-6 col-sm-6">
														<div className="mb-3">
															<label className="form-label">Date *<span className="errMsg1"> {ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].errConsentSignedDate : ""}</span></label>
															<div className="date datepickerCss" data-date-format="mm-dd-yyyy">
																<input className="form-control" ref={(ref) => (dateRef.current[guestNumber - 1] = ref)} type="text" placeholder="mm-dd-yyyy" value={ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].ConsentSignedDate ? ticketInfo[guestNumber - 1].ConsentSignedDate : "" : date} onChange={(e) => { handleConsentPrintDate(e.target.value) }} />
															</div>
														</div>
													</Col></>}

											</Row>
											{ticketInfo[guestNumber - 1].IsMinor == false ? <Row>
												<Col className="col-lg-12 col-md-6 col-sm-6">

													<div className="onSitetermsandconditions_checkbox">
														<div className="onsiteErrmsg">
															<span className="errMsg1">{ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].errAgree : ""} </span>
														</div>
														<div className="form-check form-check-inline">
															<input className="form-check-input" type="checkbox" ref={(ref) => (isAgreeRef.current[guestNumber - 1] = ref)} checked={ticketInfo.length > 0 ? ticketInfo[guestNumber - 1].isAgree : false} id="flexCheckDefault" onChange={(e) => { checkBoxHandler(e.target.checked) }} />

															<label className="form-check-label" for="flexCheckDefault">I agree to the Terms and Privacy Policy</label>
														</div>
													</div>
												</Col>
											</Row> : ""}
										</div>


									</div>
								</div>
							</Card>
						</div>


						<div className="footerButtonBlock">
							<div className="pull-right">
								{guestNumber > 1 && <Button className="btn btn-info grayBt" onClick={() => { setGuestNumber((old) => { return old - 1 }) }}>BACK</Button>}
								{guestNumber < response.NoOfTickets && <Button className="btn btn-info" onClick={() => { handleNextClick() }} >NEXT</Button>}
								{guestNumber == response.NoOfTickets && <Button className="btn btn-info" onClick={() => { handleSubmit() }}>SUBMIT</Button>}
							</div>
						</div>
					</> :
						<>
							<center><p dangerouslySetInnerHTML={{ __html: formMsg }} /></center>
						</>}
				</div>

			</div>
			{agree && isHide &&
				<Modal centered show className="individualBlock-iframe">
					<div className="modal-content">
						<Modal.Body className="privacy-policy">
							<div >
								<iframe className="privacy-policy-iframe" src="https://urldefense.com/v3/__https:/hollywoodparkca.com/terms-of-use/__;!!P9g1NVG6!pO7V-L-_MFtJ1hwjU5l1tOaOO3W7tzfrZ72HS2sxtBbKWj7aB0UsKjq2fbk9QNJXH6YCvlSPQDxQZU5OlFKthTrSZQpk3A$">
								</iframe>
							</div>
							<div>
								<iframe className="privacy-policy-iframe" src="https://urldefense.com/v3/__https:/hollywoodparkca.com/policies/__;!!P9g1NVG6!pO7V-L-_MFtJ1hwjU5l1tOaOO3W7tzfrZ72HS2sxtBbKWj7aB0UsKjq2fbk9QNJXH6YCvlSPQDxQZU5OlFKthToSXoXHgw$">
								</iframe>
							</div>
							<div className="cardFooter centerCardFooter">
								<><Button className="btn-iagree btn-info" onClick={() => onAccept()} >I  ACCEPT</Button>
									<Button className="btn-iagree btn-info" onClick={() => onCancel()} >Cancel</Button>
								</>
							</div>
						</Modal.Body>
					</div>
				</Modal>}
			<Footer></Footer>
		</>
	)
}
