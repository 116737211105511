import React from "react";
import logo from '../../assets/images/logo.svg'
export default function Header() {
    return (
       <>
	<div className="stickyWrap">
	<div className="headerFixer fixedPosition">
		<header className="pageHeader" >
			<div className="container clearfix">
				<div className="logo">	
					<a href="index.html">
						<img src={logo} className="img-fluid" alt="Sofi Tours Logo"/>
					</a>
				</div>					
			</div>
		</header>
	</div>
</div></>
    )
}

