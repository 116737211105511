import moment from "moment";
export const isValidEmail = (email) => {
  var validRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (email && validRegex.test(email) === false) {
    return false;
  }
  return true;
}

export const isValidName = (name) => {
  var regex =/\b([A-ZÀ-ÿ][-,a-z. '][^a-zA-Z0-9\s]+[ ]*)+/ 
  if (name && regex.test(name) === false) {
    return false;
  }
  return true;
}

export const isValidDate = (dateFormat) => {

  var dateRegex = /^((0?[1-9]|1[012])[\-.](0?[1-9]|[12][0-9]|3[01])[\-.](19|20)?[0-9]{2})*$/;
  let dateNew = new Date();
  let date = moment(dateNew).format("MM-DD-YYYY");
  let dateOld = moment(date).isAfter(dateFormat);
  
  if (dateFormat && dateRegex.test(dateFormat) === false) {
    return false;
  }
  return true;
}

export const isValidDigit = (digit) => {
  var digitRegex =/^[0-9]*[1-9]+$|^[1-9]+[0-9]*$/
  if (digit && digitRegex.test(digit) === false) {
    return false;
  }
  return true;
}

export const isValidTime = (time) => {
  var timeRegex =/^([0-2][0-3]|[01]?[0-9]):([0-5][0-9])/
  if (time && timeRegex.test(time) === false) {
    return false;
  }
  return true;
}
export const containsOnlyNumbers=(str) =>{
  debugger;
  return /^(\d+,)*(\d+)$/.test(str);
}


